import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { AuthenticationService } from '@app/authentication/authentication.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let current_token = localStorage.getItem('token');
    if (current_token && this.handleExpiredToken(current_token)) {
      this.auth.getUserdata().subscribe((u) => {
        return !!(u && u.email)
      })
      // logged in so return true
      return true
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'])
    return false
  }

  public handleExpiredToken(token: string): boolean {
    if (token != null && token != undefined && token != 'undefined') {
      const base64Url = token.split('.')[1];
      if (base64Url != undefined && base64Url != 'undefined') {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')));
        let timestampStr = new Date().getTime().toString();
        let nowTs = parseInt(timestampStr.substr(0, (timestampStr.length - 3)));
        return jsonPayload.exp > nowTs;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
