<div class="pre-modification-item">
    <div class="content">
        <div class="ckbox">
            <mat-checkbox class="ckb" [disabled]="disabled()" [checked]="isChecked" (click)="sendStatusToParent()"></mat-checkbox>
        </div>
        <div class="box">
            <div class="flux-next">
                <div class="flux">
                    <mat-grid-list [cols]="7" rowHeight="30px">
                        <mat-grid-tile [colspan]="2" [rowspan]="1">
                            <span class="cle">
                                {{ next_line['cle'] }}
                            </span>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <span class="date">
                                {{ next_line['date'] }}
                            </span>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <span class="region">
                                {{ next_line['region'] }}
                            </span>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <span class="societe">
                                {{ next_line['societe'] }}
                            </span>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <span class="mp">
                                {{ next_line['mp'] }}
                            </span>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="1">
                            <span class="total">
                                {{ next_line['total'] }}
                            </span>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
                <div class="description">
                    {{ getAlert() }}
                </div>
            </div>
            <div class="actions">
                <table mat-table [dataSource]="dataSource" class="actions-table" [hidden]="!actions.length">
                    <!-- ID_PAIEMENT_NEXT -->
                    <ng-container matColumnDef="ID_PAIEMENT_NEXT">
                        <th mat-header-cell *matHeaderCellDef> ID_PAIEMENT_NEXT </th>
                        <td mat-cell *matCellDef="let element"> {{element.ID_PAIEMENT_NEXT}} </td>
                    </ng-container>

                    <!-- DATE Column -->
                    <ng-container matColumnDef="DATE">
                        <th mat-header-cell *matHeaderCellDef> DATE </th>
                        <td mat-cell *matCellDef="let element"> {{element.DATE}} </td>
                    </ng-container>

                    <!-- INSTANCE_GN Column -->
                    <ng-container matColumnDef="INSTANCE_GN">
                        <th mat-header-cell *matHeaderCellDef> INSTANCE_GN </th>
                        <td mat-cell *matCellDef="let element"> {{element.INSTANCE_GN}} </td>
                    </ng-container>

                    <!-- SOCIETE Column -->
                    <ng-container matColumnDef="SOCIETE">
                        <th mat-header-cell *matHeaderCellDef> SOCIETE </th>
                        <td mat-cell *matCellDef="let element"> {{element.SOCIETE}} </td>
                    </ng-container>

                    <!-- MONTANT Column -->
                    <ng-container matColumnDef="MONTANT">
                        <th mat-header-cell *matHeaderCellDef> MONTANT </th>
                        <td mat-cell *matCellDef="let element"> {{element.MONTANT}} </td>
                    </ng-container>

                    <!-- STATUT Column -->
                    <ng-container matColumnDef="STATUT">
                        <th mat-header-cell *matHeaderCellDef class="col-statut-title"> STATUT </th>
                        <td mat-cell *matCellDef="let element" class="col-statut-item"> {{element.STATUT}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
            </div>

        </div>
    </div>
</div>
