<h2 mat-dialog-title>Règlement Général sur la Protection des Données (RGPD)</h2>
<mat-dialog-content class="mat-typography text-justify">
  <p>
    Lors de l'utilisation de Cashwater, des informations techniques sont
    recueillies concernant votre navigation et font l’objet d’un traitement par
    les équipes informatiques de Veolia afin d'améliorer votre expérience
    utilisateur et assurer la sécurité du système d'information. Vous pouvez
    exercer vos droits concernant vos données personnelles auprès de votre DRH
    (si vous êtes employés par Veolia) ou de notre DPO (Délégué à la Protection
    des Données
    <a href="mailto:veolia-eau-france.dpo@veolia.com"
      >veolia-eau-france.dpo@veolia.com</a
    >) sinon.
  </p>
  <p>
    Par ailleurs, en accédant à Cashwater, vous pouvez accéder à des données à
    caractère personnel, c'est-à-dire à des informations relatives à des
    personnes physiques susceptibles d'être identifiées, directement ou
    indirectement. La diffusion de ces informations présente un risque pour la
    vie privée des personnes concernées, et pour vos organismes.
  </p>
  <p>
    Par conséquent, vous vous engagez à prendre toutes précautions conformes aux
    usages et à l’état de l’art dans le cadre de vos attributions afin de
    protéger la confidentialité des informations auxquelles vous avez accès, et
    en particulier d’empêcher qu’elles ne soient communiquées à des personnes
    non expressément autorisées à les recevoir.
  </p>
  <p>
    De plus, dans Cashwater, vous êtes amenés à saisir des informations dans des
    champs de saisie libre; ayez à l'esprit que la personne concernée peut à
    tout moment exercer son droit d'accès et lire ces commentaires. Par
    conséquent, vous vous engagez à prendre toutes les précautions afin que vos
    commentaires soient toujours objectifs, adéquats, pertinents et non
    excessifs au regard de la finalité du traitement.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="buttonRGPD"
  >
    J'AI COMPRIS
  </button>
</mat-dialog-actions>
