import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationFluxService {

  constructor(
    private http: HttpClient
  ) { }

  infoFlux(id_paiement_nexts: any[], action_type: string = 'EXCLUSION'): Observable<any[]> {
    const params = {ids: id_paiement_nexts, action_type: action_type}
    return this.http.post<any[]>(`${environment.API_URL}/verification_flux_a_exclure`, params)
  }

  verifierSiLesFluxSontIncluables(id_paiement_nexts: any[]): Observable<any[]> {
    const params = {ids: id_paiement_nexts}
    return this.http.post<any[]>('', params)
  }

  roundSum(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
}
