import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NavigationExtras, Router } from '@angular/router'
import { RgpdModalComponent } from '@app/components/modals/rgpd-modal/rgpd-modal.component'
import { FilterpayementsComponent } from '@app/dashboard/filterpayements/filterpayements.component'
import { AddPaymentNextComponent } from '@app/dashboard/home/add-payment-next/add-payment-next.component'
import { UpdatepayementsComponent } from '@app/dashboard/updatepayements/updatepayements.component'
import { ExportDataComponent } from '@app/dashboard/export-data/export-data.component'
import { ReloadFluxService } from '@app/dashboard/home/add-payment-next/reload-flux.service'

@Component({
  selector: 'app-liste-buttons-home',
  templateUrl: './liste-buttons-home.component.html',
  styleUrls: ['./liste-buttons-home.component.scss'],
})
export class ListeButtonsHomeComponent implements OnInit, OnChanges {
  @Input() private childMessage: {
    SOCIETE: string[]
    REGION: string[]
    MP: string[]
    DATE: string[]
    LIST_REGION: string[]
    LIST_SOCIETES: string[]
    MONTANT: string
    LIST_MP: string[]
  }
  desactivation = true
  user = JSON.parse(localStorage.getItem('user'))
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private reloadFluxService: ReloadFluxService
  ) {}

  ngOnChanges() {
    if (this.childMessage.LIST_SOCIETES) {
      this.desactivation = false
    }
  }

  ngOnInit() {}

  openDialog() {
    const navigationExtras: NavigationExtras = {
      state: this.childMessage,
    }
    const dialogAp = this.dialog.open(AddPaymentNextComponent, {
      width: '260px',
      height: '94%',
      data: {
        dataKey: navigationExtras,
      },
    })
    dialogAp.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined' && result === 'ok') {
        this.reloadFluxService.performAction(result)
      }
    })
  }

  openExportDataDialog() {
    const navigationExtras: NavigationExtras = {
      state: this.childMessage,
    }
    this.dialog.open(ExportDataComponent, {
      width: '100%',
      height: '100%',
      data: {
        dataKey: navigationExtras,
      },
    })
  }

  openRGPD() {
    const navigationExtras: NavigationExtras = {
      state: {
        LIST_SOCIETES: this.childMessage.LIST_SOCIETES,
        LIST_REGION: this.childMessage.LIST_REGION,
        LIST_MP: this.childMessage.LIST_MP,
      },
    }
    this.dialog.open(RgpdModalComponent, {
      width: '600px',
      height: '80%',
      data: {
        dataKey: navigationExtras,
      },
    })
  }
  goToFilterPage() {
    const navigationExtras: NavigationExtras = {
      state: {
        SOCIETE: this.childMessage.SOCIETE,
        REGION: this.childMessage.REGION,
        MP: this.childMessage.MP,
        DATE: this.childMessage.DATE,
      },
    }
    let diag = this.dialog.open(FilterpayementsComponent, {
      width: '260px',
      height: '94%',
      data: {
        navigationExtras,
      },
    })
    diag.afterClosed().subscribe((res) => {
      console.log(res)
    })
  }

  goToUpdatePage() {
    const navigationExtras: NavigationExtras = {
      state: {
        SOCIETE: this.childMessage.SOCIETE,
        REGION: this.childMessage.REGION,
        MP: this.childMessage.MP,
        DATE: this.childMessage.DATE,
        KEY: null,
        CUT: true,
      },
    }
    this.dialog.open(UpdatepayementsComponent, {
      width: '260px',
      height: '94%',
      data: {
        navigationExtras,
        fromContext: false
      },
    })
  }

  goToTableTraitements() {
    const navigationExtras: NavigationExtras = {
      state: {
        SOCIETE: this.childMessage.SOCIETE,
        REGION: this.childMessage.REGION,
        MP: this.childMessage.MP,
        DATE: this.childMessage.DATE,
      },
    }
    this.router.navigate(['/traitements'], navigationExtras)
  }
  goToSolderIntersocietes() {
    const navigationExtras: NavigationExtras = {
      state: {
        SOCIETE: this.childMessage.SOCIETE,
        REGION: this.childMessage.REGION,
        MP: this.childMessage.MP,
        DATE: this.childMessage.DATE,
      },
    }
    this.router.navigate(['/solder_intersocietes'], navigationExtras)
  }

  goToDateCloture() {
    const navigationExtras: NavigationExtras = {
      state: {
        SOCIETE: this.childMessage.SOCIETE,
        REGION: this.childMessage.REGION,
        DATE: this.childMessage.DATE,
      },
    }
    this.router.navigate(['/date_cloture'], navigationExtras)
  }

  goToClotures() {
    this.router.navigate(['/clotures'], {})
  }
}
