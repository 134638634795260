<div [@.disabled]="true" #div class="{{ maximize ? 'maximize' : 'minimize' }}">
<mat-toolbar color="primary">
<!--<div class="tab-toolbar">-->
  <span class="title" style="font-size: 16px;">{{ toggleShowExcludedData ? 'Exclusions ' + title[0].toLowerCase() + title.slice(1) : title }} {{ data && '(' + this.dataSource.data.length + ')' }}</span>
  <div class="filters-tags">
  <div class="el-select__tags">
    <span *ngFor="let filter of col_contain">
        <span class="el-tag el-tag--info el-tag--small el-tag--light">
          <span class="el-select__tags-text">{{filter}} = {{contain[filter]}}</span>
          <button class="el-tag__close" (click)="removeFilter(filter, col_contain, contain)" mat-icon-button><mat-icon>highlight_off</mat-icon></button>
        </span>
    </span>
    <span *ngFor="let filter of col_sup">
        <span class="el-tag el-tag--info el-tag--small el-tag--light">
          <span class="el-select__tags-text">{{filter}} > {{sup[filter]}}</span>
          <button class="el-tag__close" (click)="removeFilter(filter, col_sup, sup)" mat-icon-button><mat-icon>highlight_off</mat-icon></button>
        </span>
    </span>
    <span *ngFor="let filter of col_inf">
        <span class="el-tag el-tag--info el-tag--small el-tag--light">
          <span class="el-select__tags-text">{{filter}} < {{inf[filter]}}</span>
          <button class="el-tag__close" (click)="removeFilter(filter, col_inf, inf)" mat-icon-button><mat-icon>highlight_off</mat-icon></button>
        </span>
    </span>
  </div>
  </div>
  <span style="flex: 1 1 auto;"></span>
  <!--<div class="button-group">-->
    <button
      mat-icon-button
      (click)="
        elements.first.nativeElement.className = '';
        maximize = !maximize;
        elements.first.nativeElement.style = ''
      "
      [(value)]="maximize"
      title="{{ maximize ? 'Rétrécir' : 'Agrandir' }}"
    >
    <mat-icon style="font-size: 16px;" *ngIf="maximize">close_fullscreen</mat-icon>
    <mat-icon style="font-size: 16px;" *ngIf="!maximize">zoom_out_map</mat-icon>
  </button>
  <button
    mat-icon-button
    *ngIf="excludedData"
    title="{{ toggleShowExcludedData ? 'Masquer les lignes exclues' : 'Afficher les lignes exclues' }}"
    alt="{{ toggleShowExcludedData ? 'Masquer les lignes exclues' : 'Afficher les lignes exclues' }}"
    [(value)]="toggleShowExcludedData"
    (click)="showExcludedData()"
  >
  <mat-icon style="font-size: 16px;" *ngIf="toggleShowExcludedData">layers</mat-icon>
  <mat-icon style="font-size: 16px;" *ngIf="!toggleShowExcludedData">layers_clear</mat-icon>
</button>

  <button  *ngIf="otherTabs && data && data.length"   title="Afficher avec un deuxième tableau"
    alt="Afficher avec un deuxième tableau"  style="font-size: 16px;" mat-icon-button [matMenuTriggerFor]="menuDuplicateTable" aria-label="Outils">
    <mat-icon style="font-size: 16px;">splitscreen</mat-icon>
  </button>
<mat-menu #menuDuplicateTable="matMenu">
<div *ngFor="let line of otherTabs">
<button
  mat-menu-item
  *ngIf="line.data && line.data.length" (click)="split_tabs(line)"
>
{{ line.title }}
</button>
</div>
</mat-menu>

  <button  style="font-size: 16px;" mat-icon-button [matMenuTriggerFor]="menuTools" aria-label="Outils">
  <mat-icon style="font-size: 16px;">more_vert</mat-icon>
</button>
<mat-menu #menuTools="matMenu">
<button
  mat-menu-item
    class="padd_"
    *ngIf="data && data.length"
    (click)="exportAsExcel()"
    title="Exporter"
  >
    <mat-icon>file_download</mat-icon>
    <span>Exporter</span>
  </button>
  <button
  mat-menu-item
    (click)="
      elements.first.nativeElement.className = '';
      maximize = !maximize;
      elements.first.nativeElement.style = ''
    "
    [(value)]="maximize"
    title="{{ maximize ? 'Rétrécir' : 'Agrandir' }}"
  >
   <mat-icon *ngIf="maximize">close_fullscreen</mat-icon>
    <mat-icon *ngIf="!maximize">zoom_out_map</mat-icon>
    <span *ngIf="maximize">Rétrécir</span>
    <span *ngIf="!maximize">Agrandir</span>
  </button>

</mat-menu>

  <!--</div>-->
  <!--</div>-->
  </mat-toolbar>
  <div class="progress-bar" *ngIf="!data">
    <mat-progress-bar
      class="my-color"
      color="red"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <div
    *ngIf="contextmenu"
    class="contextmenu"
    [ngStyle]="{ 'left.px': contextmenuX, 'top.px': 0 }"
    (mouseleave)="contextmenu = false"
  >
    <ul>
      <ng-container *ngFor="let data of mapped">
        <li (click)="data[1] == 'click' && data[2]('click')">
          <small>{{ data[0] }}</small>
          <input
            *ngIf="data[1] !== 'click'"
            [value]="data[1]"
            (input)="data[2]($event.target.value)"
          />
        </li>
      </ng-container>
    </ul>
  </div>
  <mat-table
    [@.disabled]="true"
    *ngIf="data"
    [dataSource]="dataSource"
    (mouseleave)="hasKey && (startSel = false)"
    [ngClass]="{'hide-cle': hideTableKey}"
  >
    <ng-container
      *ngFor="let cell of displayedColumns"
      matColumnDef="{{ cell }}"
    >
      <mat-header-cell
        [matTooltip]="cell"
        *matHeaderCellDef
        class="{{ (cell !== '_' && cell) || 'under' }}"
        (contextmenu)="filter_str(cell, $event); (false)"
        [ngSwitch]="cell === '_'"
      >
        <mat-checkbox
          *ngSwitchCase="true"
          [checked]="selectedColumns.length === dataSource.data.length"
          (change)="toggleSel()"
        ></mat-checkbox>
        <div *ngSwitchCase="false">
          <a href="javascript:;" (click)="order(cell, $event)">{{ cell == "comment" ? "Commentaire" : cell.charAt(0).toUpperCase() + cell.substr(1).toLowerCase() }}
          <mat-icon *ngIf="ordered === cell">south</mat-icon>
          <mat-icon *ngIf="ordered != cell">north</mat-icon></a>
          <a href="javascript:;" (click)="filter_str(cell, $event); (false)"  ><mat-icon>filter_list</mat-icon></a>
        </div>
        <div
          *ngIf="cell === 'total' && dataSource.data.length"
          class="totalSum"
          [matTooltip]="somme"
        >
          ∑
        </div>
      </mat-header-cell>
      <mat-cell
        class="{{ (cell !== '_' && cell) || 'under' }}"
        *matCellDef="let row"
        [matTooltip]="row[cell]"
        [ngSwitch]="cell === '_'"
      >
        <div
          class="under under-cell"
          *ngSwitchCase="true"
          (mousedown)="hasKey && (startSel = true) && doubleclick(row)"
          (mouseenter)="startSel && doubleclick(row)"
          (mouseup)="hasKey && (startSel = false)"
        >
          &nbsp;
        </div>
        <div *ngSwitchCase="false">
          {{ (row[cell] !== null && row[cell] !== undefined ) ? displayValue(row[cell]) :  'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      (dblclick)="hasKey && doubleclick(row)"
      [ngClass]="selectedColumns.indexOf(row['cle']) >= 0 && 'selected-row'"
      (contextmenu)="( (excludedData && toggleShowExcludedData) ? ( excludedDataLineClick(row, selectedColumns, $event) ) : ( lineClick(row, selectedColumns, $event) ) ); false"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
    <!-- https://stackoverflow.com/questions/35274028/stop-mouse-event-propagation -->
  </mat-table>
  <small
    *ngIf="(dataSource && !dataSource.data.length) "
    style="position: absolute; top: 50%; left: calc(50% - 73px)"
    >
     Aucune donnée à afficher
    </small
  >

</div>
