import { Injectable } from '@angular/core'
import { HttpResponse } from '@angular/common/http'
import { each } from 'lodash'
import { Logger } from '../helpers/logger.service'

const log = new Logger('HttpCacheService')
const cachePersistenceKey = 'Teleo-lece'
const lifeCycle = 1 // Minutes
export interface IHttpCacheEntry {
  lastUpdated: Date
  data: HttpResponse<any>
}

/**
 * Provides a cache facility for HTTP requests with configurable persistence policy.
 */
@Injectable()
export class HttpCacheService {
  public forceUpdate = false
  private cachedData: { [key: string]: IHttpCacheEntry } = {}
  private storage = sessionStorage

  constructor() {
    this.loadCacheData()
  }

  /**
   * Sets the cache data for the specified request.
   * @param url The request URL.
   * @param data The received data.
   * @param lastUpdated The cache last update, current date is used if not specified.
   */
  setCacheData(url: string, data: HttpResponse<any>, lastUpdated?: Date) {
    this.cachedData[url] = {
      lastUpdated: lastUpdated || new Date(),
      data,
    }
    log.debug(`Cache set for key: "${url}"`)
    this.saveCacheData()
  }

  /**
   * Gets the cached data for the specified request.
   * @param url The request URL.
   * @return The cached data or null if no cached data exists for this request.
   */
  getCacheData(url: string): HttpResponse<any> | null {
    this.expireCache()
    const cacheEntry = this.cachedData[url]

    if (cacheEntry) {
      log.debug(`Cache hit for key: "${url}"`)
      return cacheEntry.data
    }

    return null
  }

  /**
   * Gets the cached entry for the specified request.
   * @param url The request URL.
   * @return The cache entry or null if no cache entry exists for this request.
   */
  getHttpCacheEntry(url: string): IHttpCacheEntry | null {
    return this.cachedData[url] || null
  }

  /**
   * Clears the cached entry (if exists) for the specified request.
   * @param url The request URL.
   */
  clearCache(url: string): void {
    delete this.cachedData[url]
    log.debug(`Cache cleared for key: "${url}"`)
    this.saveCacheData()
  }

  flushCache() {
    this.cachedData = {}
  }

  expireCache() {
    each(this.cachedData, (value: IHttpCacheEntry, key: string) => {
      const current_date = new Date(value.lastUpdated)
      const expirationDate = new Date(value.lastUpdated)
      expirationDate.setMinutes(current_date.getMinutes() + lifeCycle)
      if (new Date() >= expirationDate) {
        delete this.cachedData[key]
      }
    })
    this.saveCacheData()
  }

  private saveCacheData() {
    if (this.storage) {
      this.storage[cachePersistenceKey] = JSON.stringify(this.cachedData)
    }
  }

  private loadCacheData() {
    const data = this.storage ? this.storage[cachePersistenceKey] : null
    this.cachedData = data ? JSON.parse(data) : {}
    this.expireCache()
  }
}
