import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@app/authentication/authentication.service'
import { Observable, throwError, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          sessionStorage.setItem(
            'returnUrl',
            window.location.pathname + window.location.search
          )
          this.authService.logout()
        } else if (err.status === 403) {
          localStorage.removeItem('token')
          sessionStorage.removeItem('user')
          this.router.navigate(['login'], {
            queryParams: { email: err.error.email },
          })
        } else if (![400, 500, 502].includes(err.status)) {
          // GAE may return a 502 in case of OOM or timeout, we don't want to logout our users in such case
          localStorage.removeItem('token')
          sessionStorage.removeItem('user')
          const error = (err.error && err.error.message) || err.statusText
          console.error(error)
          this.router.navigate(['login'], {
            queryParams: { email: err.error.email },
          })
        } else {
          const error =
            (err.error && (err.error.message || err.error.error)) ||
            err.statusText
          if (error) {
            return throwError(error)
          } else if (err.message) {
            console.error(err)
            return of(err.message)
          } else {
            console.error(err)
            return throwError(err)
          }
        }
      })
    )
  }
}
