import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ConfirmationInputModalComponent } from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component'
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component'
import { RgpdModalComponent } from '@app/components/modals/rgpd-modal/rgpd-modal.component'
import { HeaderComponent } from '@app/dashboard/header/header.component'
import { MaterialModule } from 'src/app/material/material.module'
import { FiltersComponent } from './filters/filters.component'
import { ListeButtonsHomeComponent } from './liste-buttons-home/liste-buttons-home.component'
import { MultiAutocompleteComponent } from './multi-autocomplete/multi-autocomplete.component'
import { SearchCardComponent } from './search-card/search-card.component'
import { TabReportComponent } from './tabreport/tabreport.component'

@NgModule({
    imports: [CommonModule, MaterialModule],
    declarations: [
        MultiAutocompleteComponent,
        FiltersComponent,
        ConfirmationInputModalComponent,
        ConfirmationModalComponent,
        RgpdModalComponent,
        HeaderComponent,
        TabReportComponent,
        SearchCardComponent,
        ListeButtonsHomeComponent,
    ],
    exports: [
        MultiAutocompleteComponent,
        FiltersComponent,
        ConfirmationInputModalComponent,
        ConfirmationModalComponent,
        RgpdModalComponent,
        HeaderComponent,
        TabReportComponent,
        SearchCardComponent,
        ListeButtonsHomeComponent,
    ]
})
export class ComponentsModule {}
