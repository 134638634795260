<mat-expansion-panel style="top: 70px" [expanded]="true">
  <mat-expansion-panel-header class="card-header">
    <mat-panel-title class="title"
      >Déclarer les dates de clotures
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-grid-list cols="4" rowHeight="65px">
    <ng-container *ngFor="let data of dataToSend">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field color="primary">
          <input
            matInput
            [matDatepicker]="date_comptable"
            [(max)]="data.date_cloture"
            placeholder="1er jour du Mois Comptable"
            [(ngModel)]="data.date_comptable"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="date_comptable"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #date_comptable
            startView="multi-year"
            (monthSelected)="
              data.date_comptable = $event; date_comptable.close()
            "
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field color="primary">
          <input
            matInput
            [min]="now"
            [matDatepicker]="date_cloture_cac"
            placeholder="Date de cloture CAC"
            [(ngModel)]="data.date_cloture_cac"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="date_cloture_cac"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #date_cloture_cac
            startView="multi-year"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field color="primary">
          <input
            matInput
            [min]="now"
            [matDatepicker]="date_cloture"
            placeholder="Date de cloture FI-GL"
            [(ngModel)]="data.date_cloture"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="date_cloture"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #date_cloture
            startView="multi-year"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>

      

      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <button
          mat-button
          class="button_cancel"
          (click)="dataToSend.splice(dataToSend.indexOf(data), 1)"
        >
          Retirer la date de cloture
        </button>
      </mat-grid-tile>
    </ng-container>


    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <button mat-button class="button_confirm" (click)="file.click()">
        Importer les date de cloture
      </button>
      <input
        type="file"
        style="display: none"
        #file
        accept=".csv,.xls,.xlsx"
        (change)="import($event)"
      />
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <button
        mat-button
        class="button_confirm"
        (click)="dataToSend.push({ date_cloture_cac: '', date_cloture: '', date_comptable: '' })"
      >
        Ajouter une date de cloture
      </button>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <button mat-button class="button_confirm" (click)="save_cloture()">
        Valider les dates de clotures
      </button>
    </mat-grid-tile>
  </mat-grid-list>

</mat-expansion-panel>
<br />
<mat-expansion-panel style="top: 70px" [expanded]="true">
  <mat-expansion-panel-header class="card-header">
    <mat-panel-title class="title">
      Visualiser les dates de clotures à venir
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-tabreport [data]="data" [columns]="displayedColumns1"></app-tabreport>
</mat-expansion-panel>

<mat-expansion-panel style="top: 70px" [expanded]="true">
  <mat-expansion-panel-header class="card-header">
    <mat-panel-title class="title">
      Visualiser les dates de clotures passées
    </mat-panel-title>
  </mat-expansion-panel-header>
  <app-tabreport
    [data]="old_dates"
    [columns]="displayedColumns1"
  ></app-tabreport>
</mat-expansion-panel>
