import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { AuthenticationService } from '@app/authentication/authentication.service'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  auth: AuthenticationService

  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(`${environment.API_URL}`)) {
      this.auth = this.injector.get(AuthenticationService)

      return this.auth.getUserIdToken().pipe(
        switchMap((token) => {
          this.auth.setToken(token)
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
          return next.handle(request)
        })
      )
    } else {
      return next.handle(request)
    }
  }
}
