import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core'
import { NgForm, FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModuleService } from '@app/shared/services/module/module.service'

import { Subscription } from 'rxjs'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import * as _moment from 'moment'
import { Moment } from 'moment'

const moment = _moment
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}
@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportDataComponent implements OnInit {
  export_progress_percent = 0;
  export_progress_status = '';
  export_location_link = '';
  export_task_id = '';
  export_type = 'csv_v2';
  exportExclus = false;
  filter_data = '';
  export_succeded = false;
  spinner = false;
  export_data_types: FormGroup;
  state;
  reloadExportStatusIntervalId: number;
  private subscription: Subscription = new Subscription();

  date_start: Moment;
  date_end: Moment;
  date_op_start: Moment;
  date_op_end: Moment;
  public montant: string = '';
  selectedMpValue: string[] = [];
  selectedMpGNValue: string[] = [];
  selectedOIBValue: string[] = [];
  selectedSocieteValue: string[] = [];
  selectedRegionValue: string[] = [];
  @ViewChild('fe') fe: NgForm;;

  constructor(
    private matDialogRef: MatDialogRef<ExportDataComponent>,
    private cdRef: ChangeDetectorRef,
    private moduleService: ModuleService,
    fb: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.export_data_types = fb.group({
      gn: false,
      next: false,
      aggs: false,
      action_courante: false,
      aggs_details: false,
    })
  }

  ngOnInit(): void {
    this.export_data_types.get('aggs_details').valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        this.export_data_types.get('action_courante').setValue(false);
        this.export_data_types.get('aggs').setValue(false);
        this.export_data_types.get('gn').setValue(false);
        this.export_data_types.get('next').setValue(false);
      }
    });
  }

  close() {
    this.matDialogRef.close('close')
  }

  onSubmit(fe: NgForm) {
    if (!this.export_type || this.export_type === '') {
      this._snackBar.open(
        "Veuillez selectionner au moins un type d'export",
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      return
    }
    if (!Object.values(this.export_data_types.value).some((e) => e === true)) {
      this._snackBar.open(
        'Veuillez selectionner au moins un type de données à exporter',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      return
    }

    const data_types = Object.entries(this.export_data_types.value).filter(
      (e) => e[1] === true
    )
    if (data_types.length === 0) {
      return
    }
    const data_types_fmt = data_types.map((e) => e[0]).join(',')

    // GET FILTER DATA
    this.filter_data = this.getFilterData()

    if (this.filter_data === '') {
      return
    }
    this.spinner = true;
    this.moduleService
      .createExport(
        `${this.filter_data}&type=${this.export_type}&data_types=${data_types_fmt}`
      )
      .subscribe(
        (result) => {
          this.spinner = false;
          let detail = "";
          this.cdRef.detectChanges();
          if (result && result['ERROR']) {
            this._snackBar.open(result['ERROR'], '', {duration: 10000, panelClass: ['red-snackbar'],})
          }
          if (result && result['RESULT'] === 'Export task created') {
            if (result['CHECK'] && result['CHECK']['count_ac'] > -1) detail += " - " + result['CHECK']['count_ac']  + " Actions courantes"
            if (result['CHECK'] && result['CHECK']['count_gn'] > -1) detail += " - " + result['CHECK']['count_gn'] + " Flux GN"
            if (result['CHECK'] && result['CHECK']['count_ecart'] > -1) detail += " - " + result['CHECK']['count_ecart'] + " Ecart calculés"
            if (result['CHECK'] && result['CHECK']['count_next'] > -1) detail += " - " + result['CHECK']['count_next'] + " Flux Next"
            this._snackBar.open(`L'extraction des données est en cours de traitement ${detail}. Vous serez informé par mail, une fois le traitement effectué.`, '', {
              duration: 10000, panelClass: ['blue-snackbar'],
            })
          }
        },
        (error) => {
          this.spinner = false;
          this.cdRef.detectChanges();
          this.export_progress_status = error.RESULT
          this._snackBar.open(`${error.RESULT}`, '', {duration: 5000,panelClass: ['red-snackbar'],})
        }
      )
  }

  reloadExportStatus(task_id) {
    // this.subscription.add(
    this.moduleService.getExportStatus(task_id).subscribe(
      (result) => {
        this.export_progress_status = result.completion_status
        this.export_progress_percent = result.completion_percent
        this.export_location_link = result.export_location
        this.export_succeded = result.success
        if (this.export_progress_percent === 100) {
          this.subscription.unsubscribe()
          if (this.reloadExportStatusIntervalId) {
            clearInterval(this.reloadExportStatusIntervalId)
          }
          this.reloadExportStatusIntervalId = null
        }
      },
      (error) => {
        this.export_progress_status = error.RESULT
        this._snackBar.open(`${error.RESULT}`, '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
      }
    )
    // )
  }

  getFilterData() {
    let local_filter_data = ''
    if (!this.date_start &&
      !this.date_end ) {
        this._snackBar.open('Veuillez préciser un intervalle de date comptable', '', {
          duration: 10000,
          panelClass: ['green-snackbar'],
        })
        return local_filter_data
    }

    if (this.date_end.diff(this.date_start, 'years', true) > 1 ) {
      this._snackBar.open("L'intervalle de date doit être inférieur à 1 an", '', {
        duration: 10000,
        panelClass: ['green-snackbar'],
      })
      return local_filter_data
    }

    local_filter_data = '?'

    local_filter_data += 'DATE__gte=' + this.date_start.format('YYYY-MM-DD');
    local_filter_data += '&DATE__lte=' + this.date_end.format('YYYY-MM-DD');

    if (this.date_op_start && this.date_op_end){
      local_filter_data += '&CREATED_AT__gte=' + this.date_op_start.format('YYYY-MM-DD');
      local_filter_data += '&CREATED_AT__lte=' + this.date_op_end.format('YYYY-MM-DD');
    }

    local_filter_data += '&exclus=' + this.exportExclus;

    if (this.montant !== '') {
      local_filter_data += '&MONTANT__in=' + this.montant
    }

    if (this.selectedMpValue.length > 0) {
      local_filter_data += '&CODE_MP__in=' + this.selectedMpValue.join('&CODE_MP__in=')

      let list_mp_matching = JSON.parse(sessionStorage.mp_matching);
      let list_mp_gn = []
      this.selectedMpValue.forEach((mp)=>{
        list_mp_gn.push(Object.keys(list_mp_matching).find(key => list_mp_matching[key] === mp));
      })
      local_filter_data += '&CODE_MP_GN__in=' + list_mp_gn.join('&CODE_MP_GN__in=')
    }

    if (this.selectedMpGNValue.length > 0) {
      local_filter_data += '&CODE_MP_GN_NUM__in=' + this.selectedMpGNValue.join('&CODE_MP_GN_NUM__in=')
    }

    if (this.selectedOIBValue.length > 0) {
      local_filter_data += '&CODE_OIB__in=' + this.selectedOIBValue.join('&CODE_OIB__in=')
    }

    if (this.selectedSocieteValue.length > 0) {
      local_filter_data += '&SOCIETE__in=' + this.selectedSocieteValue.join('&SOCIETE__in=')
    }

    if (this.selectedRegionValue.length > 0) {
      local_filter_data += '&INSTANCE_GN__in=' + this.selectedRegionValue.join('&INSTANCE_GN__in=')
    }
    return local_filter_data
  }

  get getListRegion() {
    let list_regions = []

    list_regions = JSON.parse(sessionStorage.list_regions).sort(
      (item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1)
    )

    return list_regions
  }

  get getListSociete() {
    let list_societes = []

    list_societes = JSON.parse(sessionStorage.list_societes).sort(
      (item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1)
    )

    return list_societes
  }

  get getListMP() {
    let list_mp = []
    list_mp = JSON.parse(sessionStorage.list_mp).sort(
      (item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1)
    )
    return list_mp
  }

  get getListMPGN() {
    let list_mp_gn = [];
    let list_mp_gn_in_cache = sessionStorage.list_mp_gn;
    if(list_mp_gn_in_cache){
      list_mp_gn = JSON.parse(list_mp_gn_in_cache).sort(
        (item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1)
      )
    }
    return list_mp_gn
  }

  get getListOIB() {
    let list_oib = []
    let list_oib_in_cache = sessionStorage.list_oib;
    if (list_oib_in_cache){
      list_oib = JSON.parse(list_oib_in_cache).sort(
        (item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1)
      )
    }
    return list_oib
  }

  get getMPTitle() {
    return this.selectedMpValue.join(' , ')
  }

  get getSocieteTitle() {
    return this.selectedSocieteValue.join(' , ')
  }

  get getRegionTitle() {
    return this.selectedRegionValue.join(' , ')
  }


  onDestroy() {
    this.subscription.unsubscribe()
    if (this.reloadExportStatusIntervalId) {
      clearInterval(this.reloadExportStatusIntervalId)
    }
    this.reloadExportStatusIntervalId = null
  }

  reset_export_data_types(){
    this.export_data_types.get('aggs_details').setValue(false);
  }
}
