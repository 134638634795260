import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@app/models/user'
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import * as _ from 'lodash'
import { AuthenticationService } from '../authentication.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          if (authResult.credential) {
            this.authService.setToken(authResult.credential.idToken)
          } else {
            this.authService.setToken(authResult.user.ra)
          }
          this.authService.getUserdata().subscribe((user) => {
            this.authService.setUserData(user)
            this.router.navigate(['/'])
          })
          setTimeout(() => this.router.navigate(['/']), 1000)
          return false
        },
        uiShown() {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none'
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
        },
      ],
    }

    const ui = new firebaseui.auth.AuthUI(firebase.auth())
    ui.start('#firebaseui-auth-container', uiConfig)

    this.authService.loadGapi().subscribe()
  }

  ngOnInit() {
    this.authService.getUser().then((user: User) => {
      this.authService.logout(false)
    })
    if (window.location.hash !== '') {
      const urlParam = window.location.hash.slice(1)
      const urlParamParts = urlParam.split('&')
      let idToken = null

      if (urlParamParts.length >= 3) {
        urlParamParts.forEach((part) => {
          if (_.startsWith(part, 'id_token=')) {
            idToken = part.substr(9)
          }
        })

        if (_.isNull(idToken)) {
          idToken = urlParamParts[0]
        }

        if (!_.isNull(idToken)) {
          this.authService.setToken(idToken)
        }
      }
    }
  }
}
