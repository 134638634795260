<mat-label
  *ngIf="valInput.value || (retval && retval.length)"
  (click)="valInput.focus(); (true)"
  >{{ label }}</mat-label
>
<input
  [placeholder]="(retval && retval.length && retval.join(', ')) || label"
  #valInput
  matInput
  [formControl]="control"
  [matAutocomplete]="auto"
/>
<mat-icon
  *ngIf="retval && retval.length"
  (click)="retval.splice(0, retval.length); control.setValue('')"
  >cancel</mat-icon
>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let val of filteredVal | async" [value]="val.name">
    <div (click)="update(val.name, $event)">
      <mat-checkbox class="option-checkbox" [checked]="val.selected">
        {{ val.name }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>
