import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-rgpd-modal',
  templateUrl: './rgpd-modal.component.html',
  styleUrls: ['./rgpd-modal.component.scss'],
})
export class RgpdModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
