export class User {
  id: number
  first_name: string
  last_name: string
  is_actor: boolean
  is_observator: boolean
  is_parametreur: boolean
  email: string
  profile_picture: string
  phone: string
  show_rgpd: boolean
  profile_type: string
  roles: any[]
  modules: any[]
}
