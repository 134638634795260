import { Component, OnInit } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { ModuleService } from '@app/shared/services/module/module.service'
import { environment } from '@env/environment'

@Component({
  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.scss'],
})
export class TableauClotureComponent implements OnInit  {
  public extractFiles: any[] = [];
  public extractFilesDate: any[] = [];
  public isLoading = false;

  displayedColumns: string[] = [
    'SOCIETE',
    'INSTANCE_GN',
    'MP',
    'DATE',
    'MONTANT',
  ]
  data: string[]
  public spinner = true
  private date: string[]
  private filter_data = '?'
  private state: any

  constructor(
    public moduleService: ModuleService,
    private _snackBar: MatSnackBar
  ) {
    this.refreshFileList();
    this.refresh_filters();
  }

  public downloadFile(filename : string){
    this.moduleService.downloadFile(filename).subscribe((data)=>{
      window.open(data.signedUrl);
    });
  }

  public refreshFileList(){
    this.isLoading = true;
    this.moduleService.getAllExtract().subscribe((data)=>{
      this.extractFiles = data;
      this.extractFilesDate = [...new Set(this.extractFiles.map(chaine => chaine.filename.split('-EXTRACT-')[1].replace('.csv.gz','')))].sort().reverse();
      console.log(this.extractFilesDate);
      this.isLoading = false;
    });
  }

  private refresh_filters(state = {} as any) {
    const societe = state.SOCIETE as string[]
    if (societe && societe.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_societe = societe[societe.length - 1]
      let listString = 'SOCIETE__in='
      for (const el of societe) {
        if (el !== revers_societe) {
          listString = listString + el + '&SOCIETE__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    const region = state.REGION as string[]
    if (region && region.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_region = region[region.length - 1]
      let listString = 'INSTANCE_GN__in='
      for (const el of region) {
        if (el !== revers_region) {
          listString = listString + el + '&INSTANCE_GN__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    const mp = state.MP as string[]
    if (mp && mp.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_mp = mp[mp.length - 1].replace(/'/g, '').trim()
      let listString = 'MP__in='
      for (let el of mp) {
        el = el.replace(/'/g, '').trim()
        if (el !== revers_mp) {
          listString = listString + el + '&MP__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.date = state.DATE as string[]
    if (this.date) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      this.filter_data =
        this.filter_data +
        'DATE=' +
        this.date[0].split('/').reverse().join('-') +
        '-01'
    }
  }
  private refresh_clotures() {
    if (!this.date) {
      return
    }
    this.moduleService.getAllClotures(this.filter_data).subscribe(
      (result) => {
        this.spinner = false
        this.data = result.RESULT
      },
      (error) => {
        this.spinner = false
        this.data = []
        console.error(error)
        this._snackBar.open(error, '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
      }
    )
  }

  receiveMessage($event) {
    this.spinner = true
    this.state = $event

    this.filter_data = '?'
    this.refresh_filters(this.state)
    this.refresh_clotures()
  }

  ngOnInit() {}

  export_mois = () => {
    this.filter_data = '?'
    this.refresh_filters(this.state)
    window.open(`${environment.API_URL}/export_cloture/${this.filter_data}`)
  }
}
