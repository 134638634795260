import { AfterViewInit, Component, OnInit } from '@angular/core'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AuthenticationService } from '@app/authentication/authentication.service'
import { User } from '@app/models/user'
import { ModuleService } from '@app/shared/services/module/module.service'
import * as moment from 'moment'
import * as XLSX from 'xlsx'

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

export interface PeriodicElement {
  position: number
  SOC_INS_SOURCE: string
  SOC_INS_CIBLE: string
  MP: string
  DATE: string
  MONTANT: number
  LIBELLE_NORMALISE: string
  ID_NEXT_SOLDE: string
  MONTANT_ID_NEXT: number
  selected: Boolean
}

@Component({
  selector: 'app-date-cloture',
  templateUrl: './date-cloture.component.html',
  styleUrls: ['./date-cloture.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateClotureComponent implements OnInit {

  data: string[]
  old_dates: string[]
  user: User
  displayedColumns1: string[] = [
    'date comptable',
    'date CAC',
    'date FI-GL',
    'user',
    'date de création',
  ]
  now = new Date()
  dataToSend: { date_cloture_cac: Date; date_cloture: Date; date_comptable: Date }[] = this.initializeDataToSend()

  constructor(
    private authService: AuthenticationService,
    private moduleService: ModuleService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.authService.getUser().then((_user: User) => {
      this.user = _user
    })
    const navigation = this.router.getCurrentNavigation()
  }

  ngOnInit() {
    setTimeout(() => {
      this.refresh_dates()
      this.refresh_old_dates()
    }, 500)
  }

  save_cloture() {
    try {
      const formattedDate = this.dataToSend.map((x) => ({
        date_cloture:
          x.date_cloture.toISOString() &&
          moment(x.date_cloture).format('YYYY-MM-DD'),
        date_cloture_cac:
          x.date_cloture_cac.toISOString() &&
          moment(x.date_cloture_cac).format('YYYY-MM-DD'),
        date_comptable:
          x.date_comptable.toISOString() &&
          moment(x.date_comptable).format('YYYY-MM-DD'),
      }))
      const data = {
        listDates: formattedDate,
        user: this.user.email,
        timestamp: this.now,
      }
      this.moduleService.newCloture(data).subscribe(
        (res) => {
          this.refresh_dates()
          if (res === 'ok') {
            this.dataToSend = this.initializeDataToSend()
            this._snackBar.open('Dates de clotures enregistrées', '', {
              duration: 5000,
              panelClass: ['green-snackbar'],
            })
          } else {
            this._snackBar.open(res, '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          }
        },
        (error) => {
          this._snackBar.open('Une erreur est survenue', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    } catch (e) {
      let err: string
      if (e.name === 'TypeError') {
        err = 'Veuillez remplir tous les champs'
      } else {
        err = e.message
      }
      this._snackBar.open(err, '', {
        duration: 5000,
        panelClass: ['red-snackbar'],
      })
    }
  }

  import(event) {
    event.target.files[0].arrayBuffer().then((x: ArrayBuffer) => {
      const workbook = XLSX.read(new Uint8Array(x), {
        type: 'array',
        raw: true,
      })
      workbook.SheetNames.forEach((name) => {
        const sheet = workbook.Sheets[name]
        const indexes =
          (sheet.A1.v.toLowerCase().indexOf('cloture'.toLowerCase()) >= 0 &&
            sheet.B1.v.toLowerCase().indexOf('comptable'.toLowerCase()) >=
              0 && { cloture: 'A', comptable: 'B' }) ||
          (sheet.B1.v.toLowerCase().indexOf('cloture'.toLowerCase()) >= 0 &&
            sheet.A1.v.toLowerCase().indexOf('comptable'.toLowerCase()) >=
              0 && { cloture: 'B', comptable: 'A' }) ||
          undefined

        if (!indexes) {
          this._snackBar.open(
            "Fichier Invalide: Doit contenir une colone 'Date cloture' et une colonne 'Date comptable'",
            '',
            {
              duration: 5000,
              panelClass: ['red-snackbar'],
            }
          )
        }
        let index = 2
        let d_cloture: string
        let d_comptable: string
        const dataToSend = []
        while (
          sheet[indexes.cloture + index] &&
          sheet[indexes.comptable + index] &&
          (d_cloture = sheet[indexes.cloture + index].v) &&
          (d_comptable = sheet[indexes.comptable + index].v)
        ) {
          let formatted_cloture: Date
          let formatted_comptable: Date

          // Vérifier le format de la date de cloture FI-GL
          if (d_cloture.match(/\d{4}-[01][0-9]-[0-3][0-9]/)) {
            formatted_cloture = new Date(d_cloture)
          } else if (d_cloture.match(/[0-3][0-9]\/[01][0-9]\/\d{4}/)) {
            formatted_cloture = new Date(
              d_cloture.split('/').reverse().join('-')
            )
          } else {
            this._snackBar.open(
              'Fichier Invalide ligne ' +
                index +
                " : La date de cloture n'est ni au format 'jj/mm/aaaa' ni au format 'aaaa-mm-jj'",
              '',
              {
                duration: 5000,
                panelClass: ['red-snackbar'],
              }
            )
            break
          }

          // Vérifier le format de la date comptable. Il s'agit en effet du premier jour de la période comptable
          if (d_comptable.match(/\d{4}-[01][0-9](-[0-3][0-9]){0,1}/)) {
            formatted_comptable = new Date(d_comptable)
          } else if (d_comptable.match(/([0-3][0-9]\/){0,1}[01][0-9]\/\d{4}/)) {
            formatted_comptable = new Date(
              d_comptable.split('/').reverse().join('-')
            )
          } else {
            this._snackBar.open(
              'Fichier Invalide ligne ' +
                index +
                " : La date de comptable n'est ni au format 'jj/mm/aaaa' ni au format 'aaaa-mm-jj'",
              '',
              {
                duration: 5000,
                panelClass: ['red-snackbar'],
              }
            )
            break
          }

          dataToSend.unshift({
            date_cloture: formatted_cloture,
            date_comptable: formatted_comptable,
          })
          index++
        }
        if (
          !sheet[indexes.cloture + index] ||
          !sheet[indexes.comptable + index]
        ) {
          this.dataToSend.unshift(...dataToSend)
        }
      })
    })
  }

  initializeDataToSend() {
    return [
      { date_cloture_cac: undefined, date_cloture: undefined, date_comptable: undefined },
    ]
  }


  private refresh_dates() {
    this.moduleService
      .getDateCloture('?date_cloture__gte=' + moment().format('YYYY-MM-DD'))
      .subscribe(
        (result) => {
          this.data = result.map((x) => {
            x['date comptable'] = x['date_comptable']
              .split('-')
              .reverse()
              .join('/')
            x['date CAC'] = x['date_cloture_cac']
              .split('-')
              .reverse()
              .join('/')
            x['date FI-GL'] = x['date_cloture']
              .split('-')
              .reverse()
              .join('/')
            x['date de création'] = moment(x['creation']).format(
              'DD/MM/YYYY HH:mm'
            )
            delete x['creation']
            return x
          })
          this.data.sort(
            (a, b) =>
              a['date_comptable'].localeCompare(b['date_comptable']) as any
          )
        },
        (error) => {
          this.data = []
        }
      )
  }
  private refresh_old_dates() {
    this.moduleService
      .getDateCloture('?date_cloture__lte=' + moment().format('YYYY-MM-DD'))
      .subscribe(
        (result) => {
          this.old_dates = result.map((x) => {
            x['date comptable'] = x['date_comptable']
              .split('-')
              .reverse()
              .join('/')
            x['date CAC'] = x['date_cloture_cac']
              .split('-')
              .reverse()
              .join('/')
            x['date FI-GL'] = x['date_cloture']
              .split('-')
              .reverse()
              .join('/')
            x['date de création'] = moment(x['creation']).format(
              'DD/MM/YYYY HH:mm'
            )
            delete x['creation']
            return x
          })
          this.old_dates.sort(
            (a, b) =>
              a['date_comptable'].localeCompare(b['date_comptable']) as any
          )
        },
        (error) => {
          this.old_dates = []
        }
      )
  }

  receiveMessage($event) {
    this.refresh_dates()
    this.refresh_old_dates()
  }
}
