import { NgGapiClientConfig } from 'ng-gapi'
import { environment } from 'src/environments/environment'

export const BASE_URL = `${environment.BASE_URL}`

export const APIS: any = {
  EXAMPLE: `${BASE_URL}/example`,
}

export const gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.client_id,
  ux_mode: 'redirect',
  redirect_uri: environment.redirect_uri,
  discoveryDocs: [''],
}
