<div class="background-custom">
<div mat-dialog-title class="title-modal">
  {{ message }}

  <!--<mat-icon class="close-icon" (click)="close()">close</mat-icon>-->
</div>
<br />
<div
  style="height: 67%"
  class="uploader-status"
  *ngIf="spinner"
  mat-dialog-content
>
  <mat-progress-spinner
    style="
      position: fixed;
      z-index: 999;
      overflow: show;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 50px;
    "
    mode="indeterminate"
    value="10"
  >
  </mat-progress-spinner>
</div>

<div *ngIf="!spinner" mat-dialog-content>
  <br />

  <form class="center">
    <button
      style="background-color: grey"
      class="half-width"
      mat-button
      (click)="annuler()"
    >
      ANNULER
    </button>

    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button
      style="background-color: #00b1c7ff"
      class="half-width"
      mat-raised-button
      color="primary"
      (click)="validateUpdate()"
    >
      CONFIRMER
    </button>
  </form>
</div>
</div>
