import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleService } from '@app/shared/services/module/module.service';
import { PreModificationItemComponent } from '../pre-modification-item/pre-modification-item.component';
import { Subject } from 'rxjs';
import { VerificationFluxService } from '@app/dashboard/home/exclusion-inclusion/services/verification-flux.service';

@Component({
  selector: 'app-pre-modification',
  templateUrl: './pre-modification.component.html',
  styleUrls: ['./pre-modification.component.scss']
})
export class PreModificationComponent implements OnInit {
  // PreModificationItemComponent représente un bloc qui contient un flux Next ainsi que
  // les actions courantes qui ont été créées à partir de ce flux Next.
  // La variable preMods avec l'annotation ViewChildren permet d'indexer tous les blocs
  // PreModificationItemComponent se trouvant dans l'interface HTML de ce component
  @ViewChildren(PreModificationItemComponent) preMods: ElementRef<PreModificationItemComponent>[]
  public actions: any
  public flux_next: any
  public update_to_apply: any
  public headerWidth: any
  public desactivation: boolean = false
  public spinner: boolean = false
  selectallEventsSubject: Subject<boolean> = new Subject<boolean>();
  numberCheckedFlux: number;
  sumCheckedFlux: number = 0;

  constructor(
    public matDialogRef: MatDialogRef<PreModificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private moduleService: ModuleService,
    private _snackBar: MatSnackBar,
    private verifService: VerificationFluxService
  ) {
    this.actions = this.data.actions
    this.flux_next = this.data.flux_next
    this.update_to_apply = this.data.update_to_apply
  }

  ngOnInit(): void {
    this.headerWidth = Math.ceil(( window.innerWidth / 5 ) * 4)
    setTimeout(() => {
      this.numberCheckedFlux = this.preMods['_results'].filter(x => x.isChecked).length
      this.sumCheckedFlux = 0
      this.preMods['_results'].forEach((x) => {
        if (x.isChecked) {
          this.sumCheckedFlux += parseFloat(x.next_line.total);
        }
      });
      this.sumCheckedFlux = this.verifService.roundSum(this.sumCheckedFlux)
    }, 500)
  }

  get getActionsKey() {
    const actionsKey =  Object.keys(this.actions)
    return actionsKey
  }

  getNext(cleNext) {
    const f_next = this.flux_next.filter(item => item['cle'] === cleNext)[0]
    return f_next
  }

  close() {
    this.matDialogRef.close('confirm')
  }

  valider() {
    this.desactivation = true
    this.spinner = true
    const ids = []
    const dict = []

    for (const predMod of this.preMods['_results']) {
      if (predMod.isChecked) {
        ids.push(predMod.next_line.cle)
      }
    }

    // On construit les paramètres à passer à la requête pour demander d'appliquer les modification des flux
    // La requête est constituée de { action: 'cuttingData' }, qui indique qu'on veut faire du découpage de flux,
    // de {id_next: ids} qui contient la liste des clé Next retenues par l'utilisateur quand la page avec les blocs
    // PreModificationItemComponent lui a été présentée.
    // et de this.update_to_apply qui contient les modification qu'on veut apporter.
    dict.push({ action: 'cuttingData' })
    dict.push({
      id_next: ids,
    })
    dict.push(this.update_to_apply)
    dict.push({selection: true})

    this.moduleService.recordCuttingData(dict).subscribe(
      (result) => {
        this.spinner = false
        if (result.RESULTAT === 'OK') {
          this._snackBar.open('La modification de la sélection a été effectuée avec succès', '', {
            duration: 10000,
            panelClass: ['blue-snackbar'],
          })
        } else if (result.RESULTAT === 'OK avec réinitialisation') {
          this._snackBar.open("La modification de la sélection a été effectuée avec succès. Cependant un ou plusieurs flux n'ont pas été modifiés car les critères de modification correspondent à leur état actuel.", '', {
            duration: 15000,
            panelClass: ['blue-snackbar'],
          })
        } else if (result.RESULTAT === 'Un flux Next exclu ne peut être découpé.') {
          this._snackBar.open("La modification de la sélection n'a pas été effectuée avec succès. Car un flux Next exclu ne peut être découpé.", '', {
            duration: 15000,
            panelClass: ['red-snackbar'],
          })
        }
        this.close()
      },
      (error) => {
        this.desactivation = false
        this.spinner = false
        this._snackBar.open("Une erreur s'est produite lors du traitement de votre opération.", '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
      }
    )
  }

  annuler() {
    this.matDialogRef.close('cancel')
  }

  receiveStatus($event) {
    if ($event.checked) {
      this.numberCheckedFlux--
      this.sumCheckedFlux = this.sumCheckedFlux - parseFloat($event.montant)
    } else {
      this.numberCheckedFlux++
      this.sumCheckedFlux = this.sumCheckedFlux + parseFloat($event.montant)
    }
    this.sumCheckedFlux = this.verifService.roundSum(this.sumCheckedFlux)
  }

  selectAll(ev) {
    this.selectallEventsSubject.next(ev.checked)
    if (ev.checked) {
      this.numberCheckedFlux = this.preMods['_results'].filter(x => x.isChecked).length
      this.sumCheckedFlux = 0
      this.preMods['_results'].forEach((x) => {
        if (x.isChecked) {
          this.sumCheckedFlux += parseFloat(x.next_line.total);
        }
      });
      this.sumCheckedFlux = this.verifService.roundSum(this.sumCheckedFlux)
    } else {
      this.numberCheckedFlux = 0
      this.sumCheckedFlux = 0
    }
  }
}
