import { HttpClient } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { environment } from '@env/environment'
import { Observable, Subscription } from 'rxjs'
@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private running_observable = new Subscription()
  private canceled = false
  constructor(private httpClient: HttpClient, private ngZone: NgZone) {}

  public clear_running() {
    if (!this.canceled) {
      this.canceled = true
      this.running_observable.unsubscribe()
      this.running_observable = new Subscription()
      this.canceled = false
    } else {
      this.running_observable.unsubscribe()
      this.running_observable = new Subscription()
    }
  }

  private post(str: string, data: any): Observable<any> {
    return this.httpClient.post(str, data)
  }

  private get(str: string): Observable<any> {
    let ret: any
    let err: any
    const already_canceled = this.canceled
    this.ngZone.runOutsideAngular(() => {
      const fetch = (db: IDBDatabase = undefined, now: Date = undefined) => {
        const req = this.httpClient.get(str)
        this.running_observable.add(
          req.subscribe(
            (x) => {
              if (this.canceled && !already_canceled) {
                return
              }
              ret = x
              if (db) {
                const tx = db.transaction(['requests'], 'readwrite')
                const store = tx.objectStore('requests')
                tx.oncomplete = function () {
                  db.close()
                }
                store.put({ id: str, data: x, date: now })
              }
            },
            (error) => {
              err = error
              console.error(err)
            }
          )
        )
      }
      const indexedDB = window.indexedDB
      if (str && indexedDB) {
        const open = indexedDB.open('CashWater', 1)
        open.onblocked = (x) => {
          console.error('blocked')
          console.error(x)
          fetch()
        }
        open.onupgradeneeded = () => {
          const db = open.result
          if (!db.objectStoreNames.contains('requests')) {
            db.createObjectStore('requests', { keyPath: 'id' })
          }
        }
        open.onerror = (error) => {
          fetch()
          console.error('Error creating database')
          console.error(error)
        }
        open.onsuccess = () => {
          const db = open.result
          const tx = db.transaction(['requests'], 'readwrite')
          const store = tx.objectStore('requests')

          const res = store.get(str)
          res.onsuccess = () => {
            const now = new Date()
            const cont =
              res.result &&
              (now as unknown as number) - res.result.date <= 30 * 1000

            if (this.canceled && !already_canceled) {
              return
            }
            if (!cont) {
              try {
                fetch(db, now)
              } catch (error) {
                err = error
              }
              if (res.result) {
                store.delete(str)
              }
            } else {
              console.warn('db')
              ret = res.result.data
              db.close()
            }
          }
        }
      } else {
        try {
          fetch()
        } catch (error) {
          err = error
          console.error(err)
        }
      }
    })

    const ans = new Observable((subscriber) => {
      const inter = setInterval(() => {
        if (ret) {
          subscriber.next(ret)
          subscriber.complete()
          clearInterval(inter)
        } else if (this.canceled && !already_canceled) {
          subscriber.next(ret)
          subscriber.complete()
          clearInterval(inter)
        } else if (err) {
          subscriber.error(err)
          subscriber.complete()
          clearInterval(inter)
        }
      }, 250)
    })
    return ans
  }

  created(element): Observable<any> {
    const params = {
      id_payment_next: element[0].id,
      comment: element[0].comment,
      ACTION: element[0].action,
    }

    console.log('INCLUDE/EXCLUDE PARAMS ', params)
    return this.httpClient.post(
      `${environment.API_URL}/exclusions/`,
      JSON.stringify(params)
    )
  }

  newCloture(params: { [key: string]: any }): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/clotures/date`,
      JSON.stringify(params)
    )
  }

  getDateCloture(getData = ''): Observable<any> {
    // return this.httpClient.get(`${environment.API_URL}/clotures/date${getData}`)
    return this.get(`${environment.API_URL}/clotures/date${getData}`)
  }

  getAggs(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_aggs/${getData}`)
  }

  getAllNext(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_nexts/${getData}`)
  }

  getExcludedNext(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_exclusions/${getData}`)
  }

  getAllRegle(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_regles/${getData}`)
  }

  getAllGN(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_gn/${getData}`)
  }

  getAllTraitements(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_exclusions/${getData}`)
  }

  integrityCheck(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/integrity`)
  }

  getAllClotures(getData = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_all_clotures/${getData}`)
  }

  extractData(ids: any[]): Observable<any>  {
    return this.httpClient.get(`${environment.API_URL}/extract-data/?ids=${ids}`)
  }

  getFilterElements(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/getfiltervalues/`)
  }

  createExport(getParamsFmt = ''): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/export_data/${getParamsFmt}`)
  }

  getExportStatus(taskId: string): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/get_export_status/?task_id=${taskId}&ts=${Date.now()}`)
  }

  getAllExtract(): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/extract-data-full/`)
  }

  downloadFile(filename): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/extract-data-full/`,JSON.stringify({filename:filename}))
  }


  check_cut(element): Observable<any> {
    const params = {
      id_payment_next: element[0].id,
    }

    return this.httpClient.get(`${environment.API_URL}/get_id_cut/`, {
      params,
    })
  }

  check(element): Observable<any> {
    const params = {
      id_payment_next: element[0].id,
    }

    return this.httpClient.get(`${environment.API_URL}/get_id/`, {
      params,
    })
  }

  updatepayements(element): Observable<any> {
    const params = {
      CONFIRMATION: element[1].CONFIRMATION,
      id_payment_next: element[1].ID,
      SOCIETE: element[1].SOCIETE,
      GN: element[1].GN,
      CODE_MP: element[1].CODE_MP,
      DATE: element[1].DATE,
      MONTANT: element[1].MONTANT,
      COMMENTAIRE: element[1].COMMENTAIRE,
      IND_MODIF_SOCIETE: element[1].IND_MODIF_SOCIETE,
      IND_MODIF_GN: element[1].IND_MODIF_GN,
      IND_MODIF_MP: element[1].IND_MODIF_MP,
      IND_MODIF_DATE: element[1].IND_MODIF_DATE,
    }
    return this.httpClient.post(
      `${environment.API_URL}/update_payment/`,
      JSON.stringify(params)
    )
  }

  confirm_updatepayements(element): Observable<any> {
    const params = {
      CONFIRMATION: element[0].CONFIRMATION,
      id_payment_next: element[0].ID,
      SOCIETE: element[0].SOCIETE,
      GN: element[0].GN,
      CODE_MP: element[0].CODE_MP,
      DATE: element[0].DATE,
      MONTANT: element[0].MONTANT,
      COMMENTAIRE: element[0].COMMENTAIRE,
      IND_MODIF_SOCIETE: element[0].IND_MODIF_SOCIETE,
      IND_MODIF_GN: element[0].IND_MODIF_GN,
      IND_MODIF_MP: element[0].IND_MODIF_MP,
      IND_MODIF_DATE: element[0].IND_MODIF_DATE,
    }
    return this.httpClient.post(
      `${environment.API_URL}/confirm_update_payment/`,
      JSON.stringify(params)
    )
  }

  recordCuttingData(element): Observable<any> {
    const params = {
      DATA: element,
    }
    return this.httpClient.post(
      `${environment.API_URL}/cutting_payment/`,
      JSON.stringify(params)
    )
  }

  checkingNextDataForCutting(element): Observable<any> {
    return this.httpClient.put(
      `${environment.API_URL}/cutting_payment/`, {ID_NEXTS: element}
    )
  }

  createClearAGap(element): Observable<any> {
    const params = {
      DATA: element,
    }
    return this.httpClient.post(
      `${environment.API_URL}/create_clear_gap/`,
      JSON.stringify(params)
    )
  }

  deleteCuttingData(element): Observable<any> {
    const params = {
      DATA: element,
    }
    return this.httpClient.post(
      `${environment.API_URL}/cutting_payment/`,
      JSON.stringify(params)
    )
  }

  exclureFlux(data: any) {
    return this.httpClient.post(
      `${environment.API_URL}/exclure_flux`,
      JSON.stringify(data)
    )
  }

  exclureInclureFlux(element): Observable<any> {
    const params = {
      id_payment_next: element['id_payment_next'],
      comment: element['comment'],
      ACTION: element['action'],
    }

    console.log('INCLUDE/EXCLUDE PARAMS ', params)
    return this.httpClient.post(
      `${environment.API_URL}/exclusions/`,
      JSON.stringify(params)
    )
  }

  addComment(data): Observable<any> {
    return this.httpClient.post(
      `${environment.API_URL}/add_comment/`,
      data
    )
  }
}
