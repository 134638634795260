<div class="hyper-vision-header">
  <!--Mobile Menu-->
  <button mat-icon-button class="mobile-menu-icon">
    <mat-icon>menu</mat-icon>
  </button>

  <!--Desktop/Tablet Brand-->
  <div class="brand">
    <div class="main-brand-name">CASHWATER</div>
    <img src="assets/img/header-brand.svg" alt="{{ app_name }} par Veolia" />
  </div>
  <!--Mobile Brand-->
  <div class="mobile-brand">
    <img
      src="assets/img/mobile-header-brand.svg"
      alt="{{ app_name }} par Veolia"
    />
  </div>
  <div class="current-app">{{ app_name }}</div>
  <div class="right-side" *ngIf="user?.email">
    <button mat-icon-button (click)="gotoAccueil('/')">
      <mat-icon
        style="color: white"
        aria-hidden="false"
        aria-label="Example home icon"
        >home</mat-icon
      >
    </button>
    <button
      mat-icon-button
      class="apps-icon"
      (click)="refresh_filters(); nav = true"
      click-bypass
    >
      <mat-icon>apps</mat-icon>
    </button>
    <a
      mat-icon-button
      class="apps-icon"
      target="_blank"
      href="https://docs.google.com/document/d/1FHQy6dI0uDnMEiOw_r4-dVQmAZsxepL7nwq6_e2wNqc/view"
    >
      <mat-icon>info</mat-icon>
    </a>
    <button
      mat-icon-button
      class="user-avatar"
      (click)="popupToggle('avatar')"
      click-bypass
    >
      <img
        onerror="this.src='/assets/img/blank-profile-picture.png'"
        class="user-img"
        [src]="user.profile_picture"
        alt="{{ user.first_name }} {{ user.last_name }}"
      />
    </button>
    <!--
      Avatar POPUP
    -->

    <div
      class="avatar-popup short"
      [hidden]="!isPopup('avatar')"
      style="--triangle-right: 53px"
      click-bypass
    >
      <div class="left-side">
        <img
          onerror="this.src='/assets/img/blank-profile-picture.png'"
          class="user-img"
          [src]="user.profile_picture"
          alt="{{ user.first_name }} {{ user.last_name }}"
        />
      </div>
      <div class="right-side">
        <div class="full-name">{{ user.first_name }} {{ user.last_name }}</div>
        <div class="email">{{ user.email }}</div>
        <!-- <div class="type">Profiil : {{ user.profile_type }}</div> -->
      </div>
    </div>
    <button
      mat-icon-button
      class="menu-icon"
      (click)="popupToggle('menu')"
      click-bypass
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <!--
      Menu POPUP
    -->
    <div
      class="menu-popup"
      [hidden]="!isPopup('menu')"
      style="--triangle-right: 4px"
      click-bypass
    >
      <mat-list>
        <mat-nav-list>
          <a (click)="logout()" mat-list-item> Déconnexion </a>
        </mat-nav-list>
      </mat-list>
    </div>
  </div>
  <div style="position: absolute; top: 64px; right: 0">
    <app-liste-buttons-home
      *ngIf="nav"
      (click)="nav = false"
      (mouseleave)="nav = false"
      [childMessage]="state"
    ></app-liste-buttons-home>
  </div>
</div>
