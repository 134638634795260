<div cdkDrag class="popin-box">
  <div cdkDrag class="popin-content">
    <mat-card
      *ngIf="!state || !state.INC"
      class="card mat-elevation-z2 exclurePaymentNext"
    >
      
      <mat-card-header class="card-header">
        <mat-card-title>Exporter les données</mat-card-title>
        <div class="close-block">
          <div class="close-content" (click)="close()">
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content class="card-content">
        <form (ngSubmit)="onSubmit(fe)" #fe="ngForm">
          <div class="form-data-export">
            <h4>Format</h4>
            <mat-radio-group name="export_type" [(ngModel)]="export_type" (change)="reset_export_data_types()" class="export-type" aria-label="Type d'export">
              <!-- <mat-radio-button style="width:200px" [checked]="true" value="gsheet">Google sheets V1</mat-radio-button><br> -->
              <mat-radio-button style="width:200px" value="gsheet_v2">Google sheets</mat-radio-button><br>
              <mat-radio-button style="width:200px" value="csv_v2">Fichiers CSV</mat-radio-button><br>
          </mat-radio-group> 

            <h4>Types de données à exporter</h4>
            <section class="export-date-types" [formGroup]="export_data_types">
                <mat-checkbox [disabled]="export_data_types.get('aggs_details')?.value ? true : null" formControlName="action_courante" class="export-type-action">Actions courantes</mat-checkbox>
                <mat-checkbox [disabled]="export_data_types.get('aggs_details')?.value ? true : null" formControlName="aggs" class="export-type-aggs">Ecarts calculés</mat-checkbox>
                <mat-checkbox [disabled]="export_data_types.get('aggs_details')?.value ? true : null" formControlName="gn" class="export-type-gn">Flux GN</mat-checkbox>
                <mat-checkbox [disabled]="export_data_types.get('aggs_details')?.value ? true : null" formControlName="next" class="export-type-next">Flux NEXT</mat-checkbox>
                <mat-checkbox *ngIf="export_type=='csv_v3' || export_type=='gsheet'" formControlName="aggs_details" class="export-type-aggs-details">Details des ecarts calculés</mat-checkbox>
            </section>

            <h4>Filtres</h4>
            <div class="search-criteria">
              <mat-grid-list [cols]="3" rowHeight="60px" class="filter-list-grid">
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="1">
                  <mat-form-field color="primary">
                    <mat-label> DATES COMPTABLES </mat-label>
                    <mat-date-range-input [rangePicker]="drp">
                      <input matStartDate #dstart [(ngModel)]="date_start" (mousedown)="$event.stopPropagation()"/>
                      <input matEndDate #dend [(ngModel)]="date_end" (mousedown)="$event.stopPropagation()"/>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="drp"></mat-datepicker-toggle>
                    <mat-date-range-picker #drp panelClass="example-month-picker"></mat-date-range-picker>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="export_type!='gsheet' && export_data_types.get('action_courante').value"
                  [colspan]="1"
                  [rowspan]="1">
                  <mat-form-field color="primary">
                    <mat-label> DATES D'OPERATION (Actions courantes) </mat-label>
                    <mat-date-range-input [rangePicker]="drp">
                      <input matStartDate #dstart [(ngModel)]="date_op_start" (mousedown)="$event.stopPropagation()"/>
                      <input matEndDate #dend [(ngModel)]="date_op_end" (mousedown)="$event.stopPropagation()"/>
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="drp"></mat-datepicker-toggle>
                    <mat-date-range-picker #drp panelClass="example-month-picker"></mat-date-range-picker>
                  </mat-form-field>
                  <!--<mat-icon matTooltip="Uniquement pour les Actions courantes">info</mat-icon>-->
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" [title]="selectedRegionValue">
                  <multi-autocomplete
                    class="full-width"
                    [label]="'REGION'"
                    [listVal]="getListRegion"
                    [(retval)]="selectedRegionValue"
                    #regionFilterComponent
                  ></multi-autocomplete>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" [title]="selectedSocieteValue">
                  <multi-autocomplete
                    class="full-width"
                    [label]="'SOCIETE'"
                    [listVal]="getListSociete"
                    [(retval)]="selectedSocieteValue"
                    #societeFilterComponent
                  ></multi-autocomplete>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" [title]="getMPTitle">
                  <multi-autocomplete
                    class="full-width"
                    [label]="'MP'"
                    [listVal]="getListMP"
                    [(retval)]="selectedMpValue"
                    #mpFilterComponent
                  ></multi-autocomplete>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="export_type!='gsheet' && export_data_types.get('gn').value">
                  <multi-autocomplete
                    class="full-width"
                    [label]="'MP GN (Flux GN)'"
                    [listVal]="getListMPGN"
                    [(retval)]="selectedMpGNValue"
                    #mpFilterComponent
                  ></multi-autocomplete>
                  <!--<mat-icon matTooltip="Uniquement pour les Flux GN">info</mat-icon>-->
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="export_type!='gsheet' && export_data_types.get('next').value">
                  <multi-autocomplete
                    class="full-width"
                    [label]="'CODE OIB (Flux NEXT)'"
                    [listVal]="getListOIB"
                    [(retval)]="selectedOIBValue"
                    #mpFilterComponent
                  ></multi-autocomplete>
                  <!--<mat-icon matTooltip="Uniquement pour les Flux NEXT">info</mat-icon>-->
                </mat-grid-tile>
                <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="1">
                  <mat-form-field class="full-width">
                    <mat-label> MONTANT </mat-label>
                    <input
                      matInput
                      type="number"
                      #montant_value
                      [(ngModel)]="montant"
                      autocomplete="off" (mousedown)="$event.stopPropagation()"
                    />
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" [rowspan]="1" *ngIf="export_type!='gsheet' && (export_data_types.get('next').value)">
                  <mat-slide-toggle class="exportExclus-slide-toggle"
                      [(ngModel)]="exportExclus">
                    Exporter les exclus
                  </mat-slide-toggle>
                </mat-grid-tile>
              </mat-grid-list>
            </div>

          </div>

          <div *ngIf="spinner">
            <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
          </div>
          <div class="_btn">
            <button *ngIf="!spinner"
                mat-button
                class="buttonConfirmerExport"
                mat-raised-button
                type="submit">
                  Exporter les données
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <br />
  </div>
</div>

