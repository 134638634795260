import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SolderIntersocietesService {
  constructor(private httpClient: HttpClient) {}

  setMCA(element): Observable<any> {
    const params: any = {
      dateFrom: element.dateFrom,
      dateTo: element.dateTo,
      ids: element.ids,
      action: element.action,
    }
    return this.httpClient.post(
      `${environment.API_URL}/set_mca/`,
      JSON.stringify(params)
    )
  }

  annulerMiseEnCompteAttente(element): Observable<any> {
    const params: any = {
      action: element.action,
      liste: element.liste,
    }
    return this.httpClient.post(
      `${environment.API_URL}/set_mca/`,
      JSON.stringify(params)
    )
  }

  setSOLDER(element): Observable<any> {
    const params: any = {
      action: element.action,
      liste: element.liste,
    }
    return this.httpClient.post(
      `${environment.API_URL}/set_solder/`,
      JSON.stringify(params)
    )
  }

  annulerSolde(dict: any[]): Observable<any> {
    return this.httpClient.post(`${environment.API_URL}/annuler_solde`, JSON.stringify(dict))
  }

  getAllTraitementsMCA(filters: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/get_all_traitements_mca/${filters}`
    )
  }

  getRemboursementMCA(filters: string): Observable<any> {
    return this.httpClient.get(
      `${environment.API_URL}/get_remboursement_mca/${filters}`
    )
  }
}
