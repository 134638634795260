import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ComponentsModule } from '@app/components/components.module'
import { GoogleApiModule, NG_GAPI_CONFIG } from 'ng-gapi'
import { gapiClientConfig } from '../config'
import { AuthenticationRoutingModule } from './authentication-routing.module'
import { AuthenticationComponent } from './authentication.component'
import { LoginComponent } from './login/login.component'

const materialModule = [MatProgressBarModule, MatButtonModule, MatIconModule]

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    AuthenticationRoutingModule,
    ...materialModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
  ],
  declarations: [AuthenticationComponent, LoginComponent],
})
export class AuthenticationModule {}
