import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from '@app/authentication/authentication.service'
import { User } from '@app/models/user'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  constructor(public authService: AuthenticationService) {
    this.authService.getUser().then((_user: User) => {
      if (_user.show_rgpd) {
        this.authService.showRGPD()
      }
    })
  }

  ngOnInit() {}
}
