import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModuleService } from '@app/shared/services/module/module.service'

@Component({
  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.scss'],
})
export class TableauComponent implements OnInit {
  displayedColumns: string[] = [
    'date',
    'societe',
    'region',
    'mp',
    'montant',
    'action',
    'statut',
    'libelle',
    'commentaire',
  ]
  data: string[]
  spinner = true
  private societe: string[]
  private region: string[]
  private mp: string[]
  private action: string[]
  private date: string[]
  private date_start: string
  private date_end: string
  private filter_data = '?'
  private state: any

  constructor(public moduleService: ModuleService, private router: Router) {
    const navigation = this.router.getCurrentNavigation()
    this.state =
      navigation && navigation.extras.state
        ? navigation.extras.state
        : {
            SOCIETE: [''],
            REGION: [''],
            MP: [''],
            DATE: '',
          }
    this.refresh_filters(this.state)
  }
  private refresh_filters(state) {
    this.societe = state.SOCIETE as string[]
    if (this.societe && this.societe.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_societe = this.societe[this.societe.length - 1]
      let listString = 'SOCIETE__in='
      for (const el of this.societe) {
        if (el !== revers_societe) {
          listString = listString + el + '&SOCIETE__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.region = state.REGION as string[]
    if (this.region && this.region.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_region = this.region[this.region.length - 1]
      let listString = 'INSTANCE_GN__in='
      for (const el of this.region) {
        if (el !== revers_region) {
          listString = listString + el + '&INSTANCE_GN__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.action = state.ACTION as string[]
    if (this.action && this.action.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_action = this.action[this.action.length - 1]
      let listString = 'TYPE_ACTION__in='
      for (const el of this.action) {
        if (el !== revers_action) {
          listString = listString + el + '&TYPE_ACTION__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.mp = state.MP as string[]
    if (this.mp && this.mp.filter((x) => x).length) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_mp = this.mp[this.mp.length - 1].replace(/'/g, '').trim()
      let listString = 'CODE_MP__in='
      for (let el of this.mp) {
        el = el.replace(/'/g, '').trim()
        if (el !== revers_mp) {
          listString = listString + el + '&CODE_MP__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.date = state.DATE as string[]
    if (this.date) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      const revers_date = this.date[this.date.length - 1]
      let listString = 'DATE_MM_AAAA__in='
      for (const el of this.date) {
        if (el !== revers_date) {
          listString = listString + el + '&DATE_MM_AAAA__in='
        } else {
          listString = listString + el
        }
      }
      this.filter_data = this.filter_data + listString
    }
    this.date_start = state.DATE_START as string
    if (this.date_start) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      this.filter_data = this.filter_data + 'DATE__gte=' + this.date_start
    }
    this.date_end = state.DATE_END as string
    if (this.date_end) {
      if (this.filter_data !== '?') {
        this.filter_data = this.filter_data + '&'
      }
      this.filter_data = this.filter_data + 'DATE__lte=' + this.date_end
    }
  }
  private refresh_rules() {
    this.moduleService.getAllTraitements(this.filter_data).subscribe(
      (result) => {
        this.spinner = false
        this.data = result.RESULT
      },
      (error) => {
        this.spinner = false
        console.error(error)
      }
    )
  }

  receiveMessage($event) {
    this.spinner = true
    this.state = $event

    this.filter_data = '?'
    this.refresh_filters(this.state)
    this.refresh_rules()
  }

  ngOnInit() {}
}
