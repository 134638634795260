import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DashboardModule } from '@app/dashboard/dashboard.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthenticationModule } from './authentication/authentication.module'
import { AuthenticationService } from './authentication/authentication.service'
import { TokenInterceptor } from './authentication/interceptors/authentication.interceptor'
import { CacheInterceptor } from './cache/cache.interceptor'
import { HttpCacheService } from './cache/http-cache-service'
import { ComponentsModule } from './components/components.module'
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop'


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    AuthenticationModule,
    DashboardModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DragDropModule
  ],
  providers: [
    HttpCacheService,
    AuthenticationService,
    CacheInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
  }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
