<mat-card
  *ngIf="!state || !state.INC"
  class="card mat-elevation-z2 exclurePaymentNext"
>
  <mat-card-header class="card-header">
    <mat-card-title style="text-align: center; position: relative; top: 10px"
      >Exclure paiement Next
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-content">
    <div class="progress-bar" *ngIf="spinner">
      <mat-progress-bar
        class="my-color"
        color="red"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
    <form (ngSubmit)="onSubmit(fe)" #fe="ngForm">
      <div class="example-container1">
        <mat-form-field>
          <input
            matInput
            placeholder="Id paiement Next"
            name="ID_EXCLUSION"
            ngModel="{{ state && state.EXC }}"
            autocomplete="off"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Commentaire"
            name="COMMENT"
            ngModel
            autocomplete="off"
            required
          />
        </mat-form-field>
      </div>
      <button
        class="buttonConfirmerExclurePayementNext"
        mat-raised-button
        type="submit"
        [disabled]="spinner"
      >
        Exclure le paiement
      </button>
    </form>
  </mat-card-content>
</mat-card>
<mat-card
  *ngIf="!state || !state.EXC"
  class="card mat-elevation-z2 inclurePaymentNext"
>
  <mat-card-header class="card-header">
    <mat-card-title style="text-align: center; position: relative; top: 10px"
      >Re inclure paiement Next exclu
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="card-content">
    <div class="progress-bar" *ngIf="spinner2">
      <mat-progress-bar
        class="my-color"
        color="red"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
    <div class="example-container">
      <mat-form-field>
        <input
          #inclusion
          matInput
          placeholder="Id paiement Next"
          name="ID_INCLUSION"
          ngModel="{{ state && state.INC }}"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          #comment
          matInput
          placeholder="Commentaire"
          name="COMMENT_INCLUSION"
          ngModel
          autocomplete="off"
          required
        />
      </mat-form-field>
    </div>
    <div>
      <button
        class="buttonInclurePayementNext"
        mat-raised-button
        [disabled]="spinner2"
        (click)="submit(inclusion.value, comment.value)"
      >
        Inclure le paiement
      </button>
    </div>
  </mat-card-content>
</mat-card>
<div class="button_confirm" style="top: 75px; position: relative">
  <button
    style="width: 200px; background-color: grey; display: block; margin: auto"
    mat-button
    (click)="close(1)"
  >
    Annuler
  </button>
</div>
