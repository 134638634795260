import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@app/authentication/authentication.service'
import { User } from '@app/models/user'
import { environment } from '@env/environment'
import * as moment from 'moment'
import { Moment } from 'moment'
import Tether from 'tether'

/**
 * Example of usage:
 * <example-url>https://fr-ist-vef-seed-app.appspot.com/examples/header</example-url>
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: User = null
  private popup: string
  private date_start: Moment
  private date_end: Moment
  app_name: string
  nav = false
  state = {
    SOCIETE: [],
    REGION: [],
    MP: [],
    DATE: [],
    DATE_START: '',
    DATE_END: '',
    MONTANT: '',
    LIST_MP: [],
    LIST_REGION: [],
    LIST_SOCIETES: [],
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.authService.getUser().then((_user: User) => {
      this.user = _user
    })
    this.app_name = environment.APP_NAME
  }

  ngOnInit() {
    this.refresh_filters()
  }

  refresh_filters() {
    if (localStorage.societe) {
      const arrayS = localStorage.societe.split(',')
      this.state.SOCIETE = arrayS
    }
    if (localStorage.region) {
      const arrayR = localStorage.region.split(',')
      this.state.REGION = arrayR
    }
    if (localStorage.mp) {
      const arrayM = localStorage.mp.split("','")
      this.state.MP = arrayM
    }
    if (localStorage.date_start) {
      this.date_start = moment(localStorage.date_start)
      const date_start = this.date_start.format('YYYY-MM-DD')
      this.state.DATE_START = date_start
      localStorage.setItem('date_start', date_start)
    }
    if (localStorage.date_end) {
      this.date_end = moment(localStorage.date_end)
      const date_end = this.date_end.format('YYYY-MM-DD')
      this.state.DATE_END = date_end
    }
    if (this.date_start && this.date_end) {
      const dates = []
      const tmp = this.date_start
      while (tmp < this.date_end) {
        dates.push(tmp.format('MM/YYYY'))
        tmp.add(1, 'month')
      }
      this.state.DATE = dates
    } else {
      const now = moment()
      const dt = now.month() + 1 + '/' + now.year()
      this.state.DATE = [dt]
    }
    if (localStorage.montant) {
      this.state.MONTANT = localStorage.montant
    }
    if (sessionStorage.list_mp) {
      this.state.LIST_MP = JSON.parse(sessionStorage.list_mp)
      this.state.LIST_REGION = JSON.parse(sessionStorage.list_regions)
      this.state.LIST_SOCIETES = JSON.parse(sessionStorage.list_societes)
    }
  }

  newTether(element: string) {
    const tether = new Tether({
      element: `.${element}-popup`,
      target: '.hyper-vision-header',
      attachment: 'top right',
      targetAttachment: 'bottom right',
      offset: '2px 15px',
    })
    setTimeout(() => {
      tether.position()
    })
    this.popup = element
  }

  logout() {
    this.authService.logout()
  }

  popupToggle(element: string) {
    this.nav = false
    if (!this.popup || this.popup !== element) {
      this.newTether(element)
    } else {
      this.popup = ''
    }
  }

  isPopup(element: string) {
    return this.popup === element
  }

  gotoAccueil(url: string) {
    const myurl = `${url}`
    this.router.navigateByUrl(myurl)
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.popup) {
      const targetElement = event.target as HTMLElement
      if (!targetElement.closest('[click-bypass]')) {
        this.popup = ''
      }
    }
  }
}
