<mat-accordion class="card mat-elevation-z2" style="top: 70px; position: relative; display: block">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header class="card-header">
      <mat-panel-title class="title">
        1. &nbsp; Lister et Exporter les Intersociétés
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-content">
      <app-search-card (messageEvent)="receiveMessage($event)"
        [optional]="['statut_intersociete', 'dates', 'date_operation']" [selectedStatutValue]="['A SOLDER']"
        class="home-card"></app-search-card>
      <div class="btn-action">
        <div class="button_confirm">
        </div>
      </div>
      <div class="table-is-brute">
        <app-tabreport [data]="data" [columns]="displayedColumns2" [lineClick]="menu_is"
          [title]="'Tableau des intersociétés'" #tab1></app-tabreport>

        <div class="text-center table-is-vide" *ngIf="! data.length">

          <span> Aucune données à afficher </span> <br>
          <span> <mat-icon aria-hidden="false">search</mat-icon> </span> <br>
          <span> Choisissez de nouveaux critères de recherche si vous le voulez. Puis cliquez sur le bouton Valider
          </span>

        </div>

        <div class="button_confirm" *ngIf="data.length">
          <button title="Mettre en compte d'attente toutes les lignes du tableau." [disabled]="inMca" mat-button
            (click)="getSelectedItemToSetMCA()">
            Mettre en compte d'attente
            <div *ngIf="inMca" class="spinner">
              <mat-spinner [diameter]="250"></mat-spinner>
            </div>
          </button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header class="card-header">
      <mat-panel-title class="title">
        2. &nbsp; Solder les intersociétés <small> (Les valeurs se rafraichissent chaque matin)</small>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expansion-panel-content">
      <app-search-card (messageEvent)="receiveMessageForMCA($event)" [optional]="['montant', 'date_operation']"
        class="home-card"></app-search-card>

      <div class="table-is-mca">
        <app-tabreport #tab2 [title]="'Intersociétés mises en compte d\'attente'" [data]="dataMCA"
          [columns]="displayedColumnsMCA" [hideTableKey]="true" [lineClick]="menu_is_mca"></app-tabreport>

        <div class="text-center table-is-vide" *ngIf="! dataMCA.length">

          <span> Aucune données à afficher </span> <br>
          <span> <mat-icon aria-hidden="false">search</mat-icon> </span> <br>
          <span> Choisissez de nouveaux critères de recherche si vous le voulez. Puis cliquez sur le bouton Valider
          </span>

        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="overlay" *ngIf="inMca || inSearch1 || spinner2">
  <mat-spinner class="overlay-spinner" [diameter]="200"></mat-spinner>
  <span class="overlay-text">Traitement en cours ...</span>
</div>

<div *ngIf="contextmenu && !inMca" class="contextmenu" [ngStyle]="{ 'left.px': contextmenuX, 'top.px': contextmenuY }"
  (mouseleave)="contextmenu = false">
  <ul>
    <ng-container *ngFor="let data of mapped">
      <li (click)="contextmenu = false; data[1]()">
        {{ data[0] }}
      </li>
    </ng-container>
  </ul>
</div>