import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadFluxService {
  reloadFlux$: Subject<string> = new Subject<string>();
  constructor() { }

  performAction(text: string) {
    this.reloadFlux$.next(text);
  }
}
