import { Component, Inject, OnInit } from '@angular/core'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModuleService } from '@app/shared/services/module/module.service'

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
}

@Component({
  selector: 'app-add-payment-next',
  templateUrl: './add-payment-next.component.html',
  styleUrls: ['./add-payment-next.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddPaymentNextComponent implements OnInit {
  def_date: string
  def_montant: string
  spinner: boolean
  list_societes: string[]
  list_regions: string[]
  list_mp: string[]
  public list_mps: string[]

  user = JSON.parse(localStorage.getItem('user'))

  constructor(
    private matDialogRef: MatDialogRef<AddPaymentNextComponent>,
    private moduleService: ModuleService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _snackBar: MatSnackBar
  ) {
    this.init()
  }

  private init() {
    if (!this.user.is_parametreur) {
      setTimeout(() => {
        this.close()
        this._snackBar.open(
          'Vous manquez de permissions pour apurer un écart',
          '',
          {
            duration: 5000,
            panelClass: ['red-snackbar'],
          }
        )
      }, 100)
      return
    }
    this.list_societes =
      this.data.dataKey.state.SOCIETE &&
      this.data.dataKey.state.SOCIETE.filter((x) => x).length
        ? this.data.dataKey.state.SOCIETE
        : this.data.dataKey.state.LIST_SOCIETES
    this.list_regions =
      this.data.dataKey.state.REGION &&
      this.data.dataKey.state.REGION.filter((x) => x).length
        ? this.data.dataKey.state.REGION
        : this.data.dataKey.state.LIST_REGION
    this.list_mp =
      this.data.dataKey.state.MP &&
      this.data.dataKey.state.MP.filter((x) => x).length
        ? this.data.dataKey.state.MP
        : this.data.dataKey.state.LIST_MP
    this.list_mps = JSON.parse(sessionStorage.list_mp)
    this.def_montant = parseFloat(this.data.dataKey.state.MONTANT).toFixed(2)
    this.def_date = this.data.dataKey.state.RDATE
      ? this.data.dataKey.state.RDATE.split('/').reverse().join('-')
      : this.data.dataKey.state.DATE[0].split('/').reverse().join('-') + '-01'
  }

  filtered(lst: string[], str: string) {
    return lst.filter((x) => x.indexOf(str) >= 0)
  }

  ngOnInit() {
    this.init()
    this.matDialogRef.updatePosition({ top: `50px`, right: `0px` })
  }

  close() {
    this.matDialogRef.close('close')
  }


  updateListSociete($event) {
    const transco_region_societe = JSON.parse(sessionStorage.transco_region_societe)
    this.list_societes = transco_region_societe[$event.value].sort()
  }

  confirm(
    societe: MatInput,
    gn: MatInput,
    mp: MatInput,
    date: MatInput,
    montant: MatInput,
    commentaire: MatInput
  ) {
    this.spinner = true
    const dict = []
    const new_payment: { [key: string]: string } = {}
    new_payment.date = date.value
    new_payment.region = gn.value
    new_payment.societe = societe.value
    new_payment.mp = mp.value
    new_payment.montant = montant.value
    new_payment.commentaire = commentaire.value
    if (
      !new_payment.date ||
      !new_payment.region ||
      !new_payment.mp ||
      !new_payment.societe ||
      !new_payment.commentaire ||
      !new_payment.montant ||
      !parseFloat(new_payment.montant)
    ) {
      this.spinner = false
      this._snackBar.open('Veuillez remplir correctement tout les champs', '', {
        duration: 5000,
        panelClass: ['red-snackbar'],
      })
    } else {
      dict.push(new_payment)
      this.moduleService.createClearAGap(dict).subscribe(
        (result) => {
          this.spinner = false

          if (result.RESULTAT === 'OK') {
            this._snackBar.open('Apuration enregistrée', '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
          }
          this.matDialogRef.close('ok')
        },
        (error) => {
          this.spinner = false
          console.error(error)
          this._snackBar.open('Création impossible', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }
}
