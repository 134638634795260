import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { IConfirmationModaleWording } from './confirmation-modal-wording.interface'

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public title: string
  public description: string
  public labelConfirm: string
  public labelRefuse: string

  constructor(
    private matDialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IConfirmationModaleWording
  ) {
    this.title = this.data.title
    this.description = this.data.description
    this.labelConfirm = this.data.labelConfirm
    this.labelRefuse = this.data.labelRefuse
  }

  ngOnInit() {}

  confirm() {
    this.matDialogRef.close(true)
  }

  refuse() {
    this.matDialogRef.close(false)
  }
}
