<div cdkDrag class="popin-box">
    <div cdkDrag class="popin-content">
        <div class="pre-modification">
            <mat-accordion class="card">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="card-header">
                        <mat-panel-title>
                            Liste des actions effectuées
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content" #actionsCourante (mousedown)="$event.stopPropagation()">
                        <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
                        <app-pre-modification-item
                            *ngFor="let actionKey of getActionsKey"
                            [actions]="actions[actionKey]"
                            [next_line]="getNext(actionKey)"
                            [selectAll]="selectallEventsSubject.asObservable()"
                            (checkEvents)="receiveStatus($event)">

                        </app-pre-modification-item>
                        <p>Nombre d'action sélectionné : {{numberCheckedFlux}}</p>
                        <p>Montant des actions sélectionnées : {{sumCheckedFlux}}</p>
                    </div>
                    <div class="footer">
                        <div class="foot">
                            <div class="bton">
                                <button
                                    style="background-color: grey"
                                    class="half-width"
                                    mat-button
                                    (click)="annuler()"
                                    >
                                ANNULER
                                </button>

                                <button
                                    style="background-color: #00b1c7ff"
                                    class="half-width"
                                    mat-raised-button
                                    color="primary"
                                    (click)="valider()"
                                    [disabled]="desactivation"
                                    >
                                CONFIRMER
                                </button>
                            </div>
                        </div>
                        <div class="progress-bar" *ngIf="spinner">
                            <mat-progress-bar class="my-color" color="red" mode="indeterminate"></mat-progress-bar>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
