import { Component, OnInit } from '@angular/core'
import firebase from 'firebase/app'
import { AuthenticationService } from '@app/authentication/authentication.service'
@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit {
  constructor(public authService: AuthenticationService) {}
  ngOnInit() {
    if (firebase.apps.length === 0) {
      AuthenticationService.initFirebaseApp()
    }
  }
}
