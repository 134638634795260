import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { ModuleService } from '@app/shared/services/module/module.service'
import { SolderIntersocietesService } from '../solder-intersocietes/solder-intersocietes.service'
import * as XLSX from 'xlsx'
import { Clipboard } from '@angular/cdk/clipboard'
import { TabReportComponent } from '@app/components/tabreport/tabreport.component'
import * as moment from 'moment'

export interface PeriodicElement {
  position: number
  SOC_INS_SOURCE: string
  SOC_INS_CIBLE: string
  MP: string
  DATE: string
  MONTANT: number
  LIBELLE_NORMALISE: string
  ID_NEXT_SOLDE: string
  MONTANT_ID_NEXT: number
  selected: Boolean
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
}

@Component({
  selector: 'app-solder-intersocietes',
  templateUrl: './solder-intersocietes.component.html',
  styleUrls: ['./solder-intersocietes.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SolderIntersocietesComponent implements OnInit {
  spinner2 = false

  private date: string
  private filter_data: string
  private detailed_filters = ''
  private dateFrom = ''
  private dateTo = ''

  inSearch1: boolean = false;

  @ViewChild('tab1') tabIntersocieteASolder: TabReportComponent

  // Coordonnées du point où l'on a cliqué
  contextmenu = false
  contextmenuX = 0
  contextmenuY = 0
  mapped: [string, () => void][]

  data: any[] = []
  dataMCA: any[] = []
  dataRemboursement: any[] = []
  displayedColumns2: string[] = [
    'cle',
    'date',
    'societe (source)',
    'region (source)',
    'societe',
    'region',
    'opération',
    'mp',
    'montant',
    'action courante',
    'statut',
    'modification',
    'commentaire',
    'id next',
  ]

  displayedColumns: string[] = [
    'select',
    'SOC_INS_SOURCE',
    'SOC_INS_CIBLE',
    'MP',
    'DATE',
    'MONTANT',
    'LIBELLE_NORMALISE',
    'ID_NEXT_SOLDE',
    'MONTANT_ID_NEXT',
  ]

  displayedColumnsMCA: string[] = [
    'cle',
    'date',
    'societe_source',
    'region_source',
    'societe',
    'region',
    'opération',
    'mp',
    'montant',
    'action_courante',
    'statut',
    'cle_paiement',
    'date_paiement',
    'societe_paiement',
    'region_paiement',
    'mp_paiement',
    'montant_paiement',
    'action_courante_paiement',
  ]
  dataSource: MatTableDataSource<PeriodicElement>

  fromDate: any
  toDate: any
  ids: number[] = []
  idsMCA: number[] = []
  private state

  inMca: boolean = false;

  constructor(
    private moduleService: ModuleService,
    private solderIntersocietesService: SolderIntersocietesService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private clipboard: Clipboard,
    private cdRef: ChangeDetectorRef,
  ) {
    this.dataSource = new MatTableDataSource<PeriodicElement>()

    const navigation = this.router.getCurrentNavigation()
    this.state = navigation.extras.state as any
  }

  // activates the menu with the coordinates
  private async context(event: MouseEvent, mapped: [string, () => void][]) {
    this.contextmenuX = event.clientX
    this.contextmenuY = event.clientY
    this.contextmenu = true
    this.mapped = mapped
  }


  private save_to_cpb = (key: string) => () => {
    this.clipboard.copy(key)
  }

  ngOnInit() {
    this.filter_data = '?'
  }

  private get_menu_name(state: any) {

    switch (state.statut) {
      case 'A SOLDER': {
        return "Mettre en compte d'attente"
      }
      // case 'SOLDE': {
      //   return "Annuler le solde"
      // }
      default: {
        return ''
      }
    }
  }


  menu_is = (
    state: { [key: string]: string },
    selection,
    event: MouseEvent
  ) => {
    this.context(event, [
      ['Copier la clé', this.save_to_cpb(state.cle)],
      [
        this.get_menu_name(state),
        () => {
          if (selection.length === 0) {
            this._snackBar.open("Veuillez cocher au moins une case pour mettre l'élément en compte d'attente", '', {
              duration: 10000,
              panelClass: ['green-snackbar'],
            })
            return
          }

          const selectedItems = this.data.filter((item) => selection.indexOf(item['cle']) > -1)
          // On vérifie que les statuts des lignes sélectionnées sont toutes identiques
          const statuts = [...new Set(selectedItems.map(a => a.statut))]
          if (statuts.length > 1) {
            this._snackBar.open(
              'Les lignes sélectionnées ont différent statuts. Veuillez sélectionner des lignes avec un même statut',
              '',
              {
                duration: 10000,
                panelClass: ['green-snackbar'],
              }
            )
            return
          }

          this.ids = selection as number[]
          if (statuts[0] === 'A SOLDER' && window.confirm("Êtes-vous sûr(e) de vouloir mettre ces lignes en compte d'attente ?")) {
            this.setMCA();
          }
          // else if (statuts[0] === 'SOLDE') {
          //   this.annulerSolde(this.ids)
          // }
        }
      ]
    ])
  }

  menu_is_mca = (
    state: { [key: string]: string },
    selection,
    event: MouseEvent
  ) => {
    this.context(event, [
      ['Copier la clé', this.save_to_cpb(state.cle)],
      [
        'Solder',
        () => {
          if (selection.length === 0) {
            this._snackBar.open("Veuillez cocher au moins une case pour solder l'intersociété", '', {
              duration: 10000,
              panelClass: ['green-snackbar'],
            })
            return
          }
          const selectedItems = this.data.filter((item) => selection.indexOf(item['cle']) > -1)

          this.idsMCA = selection as number[]
          if (window.confirm('Êtes-vous sûr(e) de vouloir solder ces intersociétés ?')) {
            this.setSOLDER()
          }
        }
      ],
      [
        "Annuler la mise en compte d'attente",
        () => {
          if (selection.length === 0) {
            this._snackBar.open("Veuillez cocher au moins une case pour solder l'intersociété ?", '', {
              duration: 10000,
              panelClass: ['green-snackbar'],
            })
            return
          }
          const selectedItems = this.data.filter((item) => selection.indexOf(item['cle']) > -1)

          this.idsMCA = selection as number[]
          if (window.confirm("Êtes-vous sûr(e) de vouloir annuler cette mise en compte d'attente")) {
            this.annulerMiseCA()
          }
        }
      ]
    ])
  }

  private refresh_filters(state: { [key: string]: string | string[] }) {
    this.refresh_detailed_filters(state)
  }

  private refresh_detailed_filters(state: {
    [key: string]: string | string[]
  }) {
    this.detailed_filters = ''
    const societe = state && (state.SOCIETE as string[])
    if (societe && societe.filter((x) => x).length) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      const revers_societe = societe[societe.length - 1]
      let listString = 'SOCIETE__in='
      for (const el of societe) {
        if (el !== revers_societe) {
          listString = listString + el + '&SOCIETE__in='
        } else {
          listString = listString + el
        }
      }
      this.detailed_filters = this.detailed_filters + listString
    }
    const region = state && (state.REGION as string[])
    if (region && region.filter((x) => x).length) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      const revers_region = region[region.length - 1]
      let listString = 'INSTANCE_GN__in='
      for (const el of region) {
        if (el !== revers_region) {
          listString = listString + el + '&INSTANCE_GN__in='
        } else {
          listString = listString + el
        }
      }
      this.detailed_filters = this.detailed_filters + listString
    }
    const statut = state && (state.STATUT as string[])
    if (statut && statut.filter((x) => x).length) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      const revers_statut = statut[statut.length - 1]
      let listString = 'STATUT__in='
      for (const el of statut) {
        if (el !== revers_statut) {
          listString = listString + el + '&STATUT__in='
        } else {
          listString = listString + el
        }
      }
      this.detailed_filters = this.detailed_filters + listString
    }
    const mp = state && (state.MP as string[])
    if (mp && mp.filter((x) => x).length) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      const revers_mp = mp[mp.length - 1].replace(/'/g, '').trim()
      let listString = 'CODE_MP_GN__in=';
      let short_el = '';
      for (let el of mp) {
        el = el.replace(/'/g, '').trim()
        short_el = this.getMpShortText(el);
        if (el !== revers_mp) {
          listString = listString + short_el + '&CODE_MP__in='
        } else {
          listString = listString + short_el
        }
      }
      this.detailed_filters = this.detailed_filters + listString
    }

    const date_start = state.DATE_START as string
    if (date_start) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      this.detailed_filters += 'DATE__gte=' + date_start
      this.dateFrom = date_start
    }

    const date_end = state.DATE_END as string
    if (date_end) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      this.detailed_filters += 'DATE__lte=' + date_end
      this.dateTo = date_end
    }

    const date_Opstart = state.DATE_OPSTART as string
    if (date_Opstart) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      this.detailed_filters += 'created_at__date__gte=' + date_Opstart
      this.dateFrom = date_Opstart
    }

    const date_Opend = state.DATE_OPEND as string
    if (date_Opend) {
      if (this.detailed_filters) {
        this.detailed_filters = this.detailed_filters + '&'
      }
      this.detailed_filters += 'created_at__date__lte=' + date_Opend
      this.dateTo = date_Opend
    }

    if (this.detailed_filters) {
      this.detailed_filters += '&'
    }
  }

  private refresh_rules(forMca: boolean) {
    if (forMca) this.inMca = false
    const filters =
      this.filter_data + this.detailed_filters + '&INTER=true'
    this.moduleService.getAllTraitements(filters).subscribe(
      (result) => {
        this.data = result.RESULT.map(item => {
          item['montant'] = (+ item['montant']).toFixed(2)
          return item
        })
        this.cdRef.detectChanges()
        if (this.data.length === 0) {
          this._snackBar.open(
            'Aucun flux disponible',
            '',
            {
              duration: 1000,
              panelClass: ['blue-snackbar'],
            }
          )
        }
        this.inSearch1 = false
      },
      (error) => {this.inSearch1 = false}
    )
  }

  private async getAllMCA() {
    const filters = this.filter_data + this.detailed_filters

    this.solderIntersocietesService.getAllTraitementsMCA(this.adaptFilter(filters)).subscribe(
      (result) => {
        this.spinner2 = false
        if (result === 'KO') {
          this._snackBar.open('Incoherence entre GN NEXT / TRANSACTIONS', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        } else if (result === 'empty') {
          this._snackBar.open('Aucun remboursement trouvé pour ces critères de sélection.', '', {
            duration: 10000,
            panelClass: ['green-snackbar'],
          })
          this.dataMCA = []
          this.cdRef.detectChanges()
        } else if (result !== 'empty') {
          for (const element of result) {
            element.selected = false
          }
          this.dataMCA = result.map(item => {
            item['montant'] = (+ item['montant']).toFixed(2)
            item['montant_paiement'] = (+ item['montant_paiement']).toFixed(2)
            item['opération'] = moment(item['opération']).format('DD/MM/YYYY')
            return item
          })
          this.cdRef.detectChanges()
        }
      },
      (error) => {
        this.spinner2 = false
        this._snackBar.open(
          "Impossible de charger le tableau des transactions en compte d'attente",
          '',
          {
            duration: 5000,
            panelClass: ['red-snackbar'],
          }
        )
      }
    )
  }

  private adaptFilter(filter: string) {
    let flter = filter.split('DATE__').join('date__')
    flter = flter.split('INSTANCE_GN__').join('region__')
    flter = flter.split('SOCIETE__').join('societe__')
    flter = flter.split('CODE_MP__').join('mp__')
    flter = flter.split('created_at__date__').join('opération__')
    flter = flter.split('STATUT__').join('statut__')
    return flter
  }

  receiveMessage($event) {
    this.inSearch1 = true;
    this.state = $event

    this.filter_data = '?'
    this.refresh_filters(this.state)
    // To remove this.refresh_aggs()
    this.refresh_rules(false)
  }

  receiveMessageForMCA($event) {
    this.state = $event
    this.filter_data = '?'
    this.refresh_filters(this.state)

    // Ajouter le statut mise en compte d'attente pour filtrer les
    // opérations d'intersociétés mise en compte d'attente
    this.detailed_filters += "STATUT__in=MISE EN COMPTE D'ATTENTE"
    this.spinner2 = true
    // Rechercher les intersociétés mises en compte d'attente
    this.getAllMCA()
  }

  getSelectedItemToSetMCA() {
    this.ids = this.tabIntersocieteASolder.selectedColumns.map((item) => Number(item))

    if (this.ids.length === 0) {
      this._snackBar.open('Veuillez sélectionner au moins une ligne.', '', {
        duration: 5000,
        panelClass: ['green-snackbar'],
      })
      return
    }

    const selectedItems = this.data.filter((item) => this.ids.indexOf(item['cle']) > -1)
    const statuts = [...new Set(selectedItems.map(a => a.statut))]
      if (statuts.length > 1) {
        this._snackBar.open('Les lignes sélectionnées ont des statuts différents. Veuillez sélectionner des lignes avec un statut `A SOLDER`', '', {
          duration: 10000,
          panelClass: ['green-snackbar'],
        })
        return
      }
    this.setMCA()
  }

  setMCA() {
    if (!this.dateFrom || !this.dateTo) {
      this._snackBar.open('Veuillez remplir une periode ou effectuez une première recherche', '', {
        duration: 10000,
        panelClass: ['red-snackbar'],
      })
    } else {
      this.inMca = true;
      const dict: any = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        ids: this.ids,
        action: 'MCA',
      }
      this.solderIntersocietesService.setMCA(dict).subscribe(
        (result) => {
          if (result === 'empty') {
            this._snackBar.open(
              'Aucune intersociété avec un statut À SOLDER n\'est indiquée.',
              '',
              {
                duration: 5000,
              }
            )
            this.inMca = false;
          } else {
            this._snackBar.open("Mise en compte d'attente enregistrée", '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })

            const name = 'MCA - ' + new Date().toString().substring(0, 10)
            this.convertStringFieldToNumber(result)
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result)
            this.formatCells(ws)
            const wb: XLSX.WorkBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, name)
            XLSX.writeFile(wb, name + '.xlsx')

            this.refresh_rules(true)
          }

        },
        (error) => {
          this._snackBar.open(
            "Erreur lors de la mise en compte d'attente",
            '',
            {
              duration: 5000,
              panelClass: ['red-snackbar'],
            }
          )
          this.inMca = false;
        }
      )
      // Réinitialisation du tableau des identifiants
      this.ids = []
    }
  }

  formatCells(obj: any) {
    for (const item in obj) {
      if (obj[item].t === 'n') {
        obj[item].z = '0.00'
      }
    }
  }

  convertStringFieldToNumber(arr: any[]) {
    const dataToExport = arr.map((item) => {
      if (item.montant_paiement) {
        item.montant_paiement = +item.montant_paiement
      }
      if (item.montant) {
        item.montant = +item.montant
      }
      return item
    })
    return dataToExport
  }

  annulerMiseCA() {
    this.spinner2 = true
    const dict: any = {
      liste: this.idsMCA,
      action: 'ANNULER_MCA',
    }
    if (dict.liste.length === 0) {
      this.spinner2 = false
      this._snackBar.open(
        'Veuillez selectionner une ou plusieurs transaction(s)',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else {
      this.solderIntersocietesService.annulerMiseEnCompteAttente(dict).subscribe(
        (result) => {
          this.spinner2 = false
          if (result === 'KO' && dict.action === 'ANNULER_MCA') {
            this._snackBar.open(
              "Annulation de la mise en compte d'attente impossible. Veuillez verifier les données",
              '',
              {
                duration: 5000,
                panelClass: ['red-snackbar'],
              }
            )
            return
          }

          if (result === 'OK') {
            this._snackBar.open("Annulation de la mise en compte d'attente effectuée", '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
          }
          this.spinner2 = true
          this.refresh_filters(this.state)
          this.detailed_filters += "STATUT__in=MISE EN COMPTE D'ATTENTE"
          this.getAllMCA()
          this.cdRef.detectChanges()
        },
        (error) => {
          this.spinner2 = false
          this._snackBar.open('Solde impossible', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }

  setSOLDER() {
    this.spinner2 = true
    const dict: any = {
      liste: this.idsMCA,
      action: 'SOLDE',
    }

    if (dict.liste.length === 0) {
      this.spinner2 = false
      this._snackBar.open(
        'Veuillez selectionner une ou plusieurs transaction(s)',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else {
      this.solderIntersocietesService.setSOLDER(dict).subscribe(
        (result) => {
          console.log('SOLDE', result)
          this.spinner2 = false
          if (result === 'KO' && dict.action === 'SOLDE') {
            this._snackBar.open(
              'Solde impossible pour certains données saisies, Veuillez verifier les données',
              '',
              {
                duration: 5000,
                panelClass: ['red-snackbar'],
              }
            )
            return
          }

          if (result === 'OK') {
            this._snackBar.open('Solde enregistré', '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
          }
          this.spinner2 = true
          this.refresh_filters(this.state)
          this.detailed_filters += "STATUT__in=MISE EN COMPTE D'ATTENTE"
          this.getAllMCA()
          this.cdRef.detectChanges()
        },
        (error) => {
          this.spinner2 = false
          this._snackBar.open('Solde impossible', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }

  changeTradesByCategory(event, row) {
    const checked = event.target.checked
    row.selected = checked
  }

  allNonTrades(event) {
    const checked = event.target.checked
    this.dataSource.data.forEach((item) => (item.selected = checked))
  }
  cancelPeriod() {
    this.fromDate = undefined
    this.toDate = undefined
    this.dateFrom = ''
    this.dateTo = ''
    // To remove this.refresh_aggs()
    this.refresh_rules(false)
  }

  getMpShortText(modep) {

    return (Object.keys(JSON.parse(sessionStorage.getItem('mp_matching')))).find((key) => {
      if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('VIREMENTS (')) {
        return modep.includes('VIREMENTS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('ANNULATIONS')) {
        return modep.includes('ANNULATIONS');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CARTES BANCAIRES (')) {
        return modep.includes('CARTES BANCAIRES (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES DOCAPOST (')) {
        return modep.includes('DOCAPOST (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES ETRANGERS (')) {
        return modep.includes('ETRANGERS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES IMPAYES (')) {
        return modep.includes('IMPAYES (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES MANUELS (')) {
        return modep.includes('MANUELS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 4) == 'ETIP') {
        return modep.substr(0, 4) == 'ETIP';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 12) == 'PRELEVEMENTS') {
        return modep.substr(0, 12) == 'PRELEVEMENTS';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('REPRISE SOLDE')) {
        return modep.includes('REPRISE SOLDE');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 3) == 'TIP') {
        return modep.substr(0, 3) == 'TIP';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('VIREMENTS CCP (')) {
        return modep.includes('CCP (');
      } else {
        return JSON.parse(sessionStorage.getItem('mp_matching'))[key] === modep;
      }
    })
  }
}
