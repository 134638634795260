import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DashboardComponent } from './dashboard.component'
import { DateClotureComponent } from './date-cloture/date-cloture.component'
import { FilterpayementsComponent } from './filterpayements/filterpayements.component'
import { SolderIntersocietesComponent } from './solder-intersocietes/solder-intersocietes.component'
import { TableauClotureComponent } from './tableau-clotures/tableau.component'
import { TableauComponent } from './tableau/tableau.component'
import { UpdatepayementsComponent } from './updatepayements/updatepayements.component'

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [],
      },
      {
        path: 'filter-payement',
        component: FilterpayementsComponent,
        canActivate: [],
      },
      {
        path: 'update-payement',
        component: UpdatepayementsComponent,
        canActivate: [],
      },
      {
        path: 'traitements',
        component: TableauComponent,
        canActivate: [],
      },
      {
        path: 'solder_intersocietes',
        component: SolderIntersocietesComponent,
        canActivate: [],
      },
      {
        path: 'date_cloture',
        component: DateClotureComponent,
        canActivate: [],
      },
      {
        path: 'clotures',
        component: TableauClotureComponent,
        canActivate: [],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
