<mat-card class="card mat-elevation-z2">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <mat-list>
    <div class="filelist-header">
      <div mat-subheader>Fichiers disponibles</div>
      <button mat-button class="button-confirm-search-all" (click)="refreshFileList()">
        Tout recharger
      </button>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor="let currentdate of extractFilesDate">
      <div mat-subheader>{{currentdate}}</div>
        <ng-container *ngFor="let currentFiles of extractFiles">
          <mat-list-item *ngIf="currentFiles.filename.includes(currentdate)">
              <div style="display: flex;">
                <div class="filelist-icon"><mat-icon (click)="downloadFile(currentFiles.filename)">download</mat-icon></div>
                <div class="filelist-item" (click)="downloadFile(currentFiles.filename)">
                  <div class="filelist-title">{{currentFiles.filename}}</div>
                  <div class="filelist-subtitle">{{currentFiles.size}} Mo</div>
                </div>
              </div>
          </mat-list-item>          
        </ng-container>
      <mat-divider></mat-divider>
    </div>

  </mat-list>

  <!--<app-search-card
    (messageEvent)="receiveMessage($event)"
    class="home-card"
    [optional]="['date_cloture']"
    [export]="export_mois"
  ></app-search-card>
  <mat-card-header class="card-header">
    <mat-card-title
      style="text-align: center; position: relative; top: 12px; height: 30px"
      >Données de clotures</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="card-content">
    <mat-progress-bar
      *ngIf="spinner"
      class="my-color"
      color="red"
      mode="indeterminate"
    ></mat-progress-bar>
    <app-tabreport [data]="data" [columns]="displayedColumns"> </app-tabreport>
  </mat-card-content>-->
</mat-card>
