<div cdkDrag class="popin-box">
  <div cdkDrag class="popin-content">
    <mat-accordion  class="card mat-elevation-z2" >
      <mat-expansion-panel class="card-panel" [expanded]="!state.CUT && true" *ngIf="!state.CUT" >
        <mat-expansion-panel-header class="card-header">
          <mat-panel-title>
            Modifier un paiement
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="progress-bar" *ngIf="spinner">
          <mat-progress-bar class="my-color" color="red" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="example-container" (mousedown)="$event.stopPropagation()">
          <br />
          <mat-form-field style="width: 400px">
            <input [disabled]="true" #id name="id_next" matInput [value]="(state.MODIF && state.KEY) || ''"
              placeholder="ID Paiement Next" pattern="NEXT_.+" required [(ngModel)]="id_next" (mousedown)="$event.stopPropagation()"/>
            <small style="position: absolute; left: -50px; color: grey" *ngIf="montant_next">({{ montant_next }})</small>

            <button style="
                width: 40px;
                background-color: green;
                right: 0;
                top: 0;
                position: absolute;
                z-index: 5;
              " mat-mini-fab (click)="confirmeID(id.value)">
              <mat-icon style="color: white" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
            </button>
            <span>ID : {{state.KEY}}</span>
          </mat-form-field>

          <mat-form-field>
            <input #date name="new_date" matInput [matDatepicker]="picker" placeholder="Date" required
              [formControl]="new_date" (mousedown)="$event.stopPropagation()"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <input placeholder="Région" matInput #region [matAutocomplete]="reg" pattern="[0-9]+" [(value)]="gn_next"
              (change)="regionSelected($event.target.value)" (mousedown)="$event.stopPropagation()"/>
            <mat-autocomplete #reg placeholder="Région" name="reg" (optionSelected)="regionSelected(region.value)"
              (closed)="regionSelected(region.value)">
              <mat-option *ngFor="
                  let reg of liste_region
                " [value]="reg">{{ reg }}</mat-option>
            </mat-autocomplete>
            <span *ngIf="region_error != ''">{{region_error}}</span>
          </mat-form-field>


          <mat-form-field>
            <input type="text" placeholder="Société" matInput #societe
              [matAutocomplete]="soc" pattern="[a-zA-Z0-9]+" [(value)]="new_societe"
              (change)="societeSelected($event.target.value)" (mousedown)="$event.stopPropagation()"/>
            <mat-autocomplete #soc placeholder="Société du payment next" name="soc"
              (optionSelected)="societeSelected(societe.value)" (closed)="societeSelected(societe.value)">
              <mat-option *ngFor="let soc of mapRegionSociete" [value]="soc">{{ soc }}
              </mat-option>
            </mat-autocomplete>
            <br>
            <span *ngIf="societe_error != ''">{{societe_error}}</span>
          </mat-form-field>

          <mat-form-field>
            <mat-select [(value)]="new_mp" placeholder="MP" required #mp>
              <mat-option *ngIf="new_mp === 'Selection multiple'" [value]="'Selection multiple'">
                Selection multiple
              </mat-option>
              <mat-option *ngFor="let mp of liste_mp" [value]="mp">{{ mp }}</mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field>
            <input #commentaire name="comment" matInput placeholder="Commentaire" required pattern="...+"
              [(ngModel)]="comment" required (mousedown)="$event.stopPropagation()" />
          </mat-form-field>
          <br />
          <div id="outer">
            <div class="button_confirm">
              <button style="width: 200px; background-color: grey" mat-button (click)="cancelForm(); close(1)">
                Annuler
              </button>
            </div>

            <div class="button_confirm">
              <button *ngIf="validate2" style="width: 200px" mat-button [disabled]="desactivation" (click)="confirmForm()">
                Valider
              </button>
            </div>
          </div>
          <br />
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="card-panel" [expanded]="state.CUT" *ngIf="state.CUT">
        <mat-expansion-panel-header class="card-header">
          <mat-panel-title>
            Découper un paiement
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="progress-bar" *ngIf="spinner">
          <mat-progress-bar class="my-color" color="red" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="example-container1" (mousedown)="$event.stopPropagation()">
          <br />
          <button *ngIf="validate" style="width: 40px; background-color: grey" mat-mini-fab (click)="addNew()">
            <mat-icon style="color: white" aria-hidden="false" aria-label="Example home icon">playlist_add</mat-icon>
          </button>

          <mat-form-field style="width: 160px">
            <input #id2 [disabled]="isFromContext" matInput [value]="(state.CUT && state.KEY) || ''"
              placeholder="ID Paiement Next" required (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <mat-form-field *ngIf="validate" style="width: 50px" (mousedown)="$event.stopPropagation()">
            <input [disabled]="true" matInput placeholder="Societe" required [(ngModel)]="societe_next" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <mat-form-field *ngIf="validate" style="width: 30px">
            <input [disabled]="true" matInput placeholder="GN" required [(ngModel)]="gn_next" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <mat-form-field *ngIf="validate" style="width: 100px">
            <input [disabled]="true" matInput placeholder="Date" required [(ngModel)]="date_next" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <mat-form-field *ngIf="validate" style="width: 100px">
            <input [disabled]="true" matInput placeholder="MP" [(ngModel)]="mp_short_next" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>

          <mat-form-field *ngIf="validate" style="width: 110px">
            <input *ngIf="validate" [disabled]="true" matInput placeholder="Montant Initial" [(ngModel)]="montant_next" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
        </div>
        <div class="example-container1" *ngFor="let el of liste_db_cutting; let i = index" (mousedown)="$event.stopPropagation()">

          <mat-form-field style="width: 50px">
            <mat-select [disabled]="true" placeholder="GN" #mp [(ngModel)]="el.gn" name="region">
              <mat-option *ngFor="let region of liste_region" [value]="region">{{
                region
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <mat-select [disabled]="true" #cuttingSociete placeholder="Société" [(ngModel)]="el.societe" name="societe">
              <mat-option [value]="undefined">Vide</mat-option>
              <mat-option *ngFor="let societe of liste_societe" [value]="societe">{{
                societe
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <input [disabled]="true" matInput [matDatepicker]="picker" placeholder="Date" [(ngModel)]="el.date" (mousedown)="$event.stopPropagation()"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <mat-select [disabled]="true" placeholder="MP" #mp [(ngModel)]="el.mp" name="mp">
              <mat-option *ngFor="let mp of liste_mp" [value]="mp">
                {{ getMpShortText(mp) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 110px">
            <input [disabled]="true" matInput placeholder="Montant" [(ngModel)]="el.montant" name="montant" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>

          <mat-form-field style="width: 110px">
            <input [disabled]="true" matInput placeholder="Statut" [(ngModel)]="el.statut" name="statut" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>

          <mat-form-field style="width: 110px">
            <input [disabled]="true" matInput placeholder="Type d'action" [(ngModel)]="el.type" name="type" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>

          <mat-form-field style="width: 110px">
            <input [disabled]="true" matInput placeholder="Commentaire" [(ngModel)]="el.commentaire" name="commentaire" (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>

          <!-- <div *ngIf="compensationAvoir(el)" class="info">
            <span class="action-info compensation-avoir" >Compensation des avoirs</span>
          </div> -->

          <div *ngIf="montantOppose(el)" class="info">
            <span class="action-info oppose" >Montant de l'action opposé à celui du flux Next</span>
          </div>

          <div *ngIf="hideDeleteBtn(el.statut)" class="info">
            <span class="action-info suppression" >Impossible de supprimer</span>
          </div>

          <button style="width: 40px; background-color: grey" mat-mini-fab (click)="deleteCuttingRecord(el, i)" *ngIf="! hideDeleteBtn(el.statut)" >
            <mat-icon style="color: white" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
          </button>
        </div>
        <hr width="100%" color="grey" />

        <div class="global-error-container">
          <span *ngIf="societe_error != ''">{{societe_error}}</span>
        </div>
        <br>
        <div class="example-container1" *ngFor="let el of cuttingData; let i = index">

          <mat-form-field style="width: 50px">
            <mat-select placeholder="GN" #region [(ngModel)]="el.region" name="region" (selectionChange)="setRegions($event, '' + i)">
              <mat-option *ngFor="let region of liste_region" [value]="region">{{
                region
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <input placeholder="Société" matInput [matAutocomplete]="cuttingSociete" [(ngModel)]="el.societe" (change)="societeSelectedForCutting($event.target.value,  '' + i)" (blur)="societeSelectedForCutting($event.target.value,  '' + i)" />
            <mat-autocomplete #cuttingSociete placeholder="Société" name="societe" [autoSelectActiveOption]="true" >
              <mat-option *ngFor="
                  let societe of filtered(liste_societe_par_region['' + i], el.societe.value || '')
                " [value]="societe" [active]="el.societe===societe ? true : false">{{ societe }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <input matInput [matDatepicker]="picker" placeholder="Date" [(ngModel)]="el.date" (mousedown)="$event.stopPropagation()"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field style="width: 100px">
            <mat-select placeholder="MP" [(ngModel)]="el.mp" name="mp">
              <mat-option *ngFor="let mp of liste_mp" [value]="mp">
                {{ getMpShortText(mp) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 110px">
            <input matInput placeholder="Montant" [(ngModel)]="el.montant" name="montant" required (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <mat-form-field style="width: 110px">
            <input matInput placeholder="Commentaire" [(ngModel)]="el.commentaire" name="commentaire" required (mousedown)="$event.stopPropagation()"/>
          </mat-form-field>
          <button style="width: 40px; background-color: red" mat-mini-fab (click)="delete(el, i)">
            <mat-icon style="color: white" aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
          </button>

        </div>
        <br />
        <div class="button">
          <div class="button_confirm">
            <button style="width: 200px; background-color: grey" mat-button (click)="cancelForm(); close(1)">
              Annuler
            </button>
          </div>

          <div class="button_confirm">
            <button *ngIf="validate" style="width: 200px" mat-button [disabled]="desactivation" (click)="submit(id2.value)">
              Valider
            </button>
          </div>
          <div class="button_confirm">
            <button *ngIf="!validate" style="width: 200px" mat-button (click)="confirmeID2(id2.value)">
              Rechercher
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div> 