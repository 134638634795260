export const environment = {
  production: true,
  dev: true,
  APP_NAME: '',
  API_URL: '/api/v1',
  BASE_URL: '',
  client_id:
    '591621361936-3fee00lc918qt6774g8im4acv1dm7rfa.apps.googleusercontent.com',
  redirect_uri: 'https://fr-ist-isteau-cashwater-dev.appspot.com/login',
  apiKey: 'AIzaSyBeOUIyhl9EC9hU5DYqAniTgk4Mv77_2-c',
  authDomain: 'fr-ist-isteau-cashwater-dev.firebaseapp.com',
  URL_ACCUEIL:
    'https://app.powerbi.com/reportEmbed?reportId=703015be-301e-420e-91b3-cf20e68e44ae&autoAuth=true&ctid=c4ad28ac-0163-4979-96e0-cfdb5a29b702&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false&navContentPaneEnabled=false',
  URL_EXCLUSION_LEFT:
    'https://app.powerbi.com/reportEmbed?reportId=f58f0c21-ef47-4761-8b97-4a813f68316f&autoAuth=true&ctid=c4ad28ac-0163-4979-96e0-cfdb5a29b702&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false&navContentPaneEnabled=false',
  URL_EXCLUSION_RIGHT:
    'https://app.powerbi.com/reportEmbed?reportId=74860a46-b7da-441b-ab79-aa2f3c348bed&autoAuth=true&ctid=c4ad28ac-0163-4979-96e0-cfdb5a29b702&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filterPaneEnabled=false&navContentPaneEnabled=false',
  URL_UPDATE_TOP:
    'https://app.powerbi.com/reportEmbed?reportId=95c94f46-a9cf-4d9f-9941-5ae1a103b07f&autoAuth=true&ctid=c4ad28ac-0163-4979-96e0-cfdb5a29b702&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D &filterPaneEnabled=false&navContentPaneEnabled=false',
  URL_TRANSACTIONS:
    'https://app.powerbi.com/reportEmbed?reportId=61626630-9f33-4650-8dec-54af043d69e9&autoAuth=true&ctid=c4ad28ac-0163-4979-96e0-cfdb5a29b702&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1mLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D',
  URL_SOLDER_INTERSOCIETES: '',
}
