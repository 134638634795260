<mat-expansion-panel class="card-panel" [(expanded)]="isExpanded" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Recherche
    </mat-panel-title>
    <mat-panel-description>
      <div mat-card-avatar class="header-image">
        <mat-icon aria-hidden="false" style="margin: 4px">search</mat-icon>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div id="content">
    <mat-grid-list cols="4" rowHeight="60px" style="width: 100%;">

      <mat-grid-tile>
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'REGION'" [listVal]="mapSocieteRegion"
          [(retval)]="selectedRegionValue" #regionFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'SOCIETE'" [listVal]="mapRegionSociete"
            [(retval)]="selectedSocieteValue" #societeFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'MP'" [listVal]="list_mp" [(retval)]="selectedMpValue"
            #mpFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field class="row-margin">
          <mat-label> CLE NEXT </mat-label>
          <input matInput type="text" #mcle_value [(ngModel)]="cle_next" autocomplete="off" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile *ngIf="optional.indexOf('statut') !== -1">
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'STATUT'" [listVal]="list_statut"
            [(retval)]="selectedStatutValue" #statutFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile *ngIf="optional.indexOf('statut_intersociete') !== -1">
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'STATUT'" [listVal]="list_statut_inter"
            [(retval)]="selectedStatutValue" #statutInterFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile *ngIf="optional.indexOf('action') !== -1">
        <div class="row-margin">
          <multi-autocomplete class="full-width" [label]="'ACTION'" [listVal]="list_action"
            [(retval)]="selectedActionValue" #actionFilterComponent></multi-autocomplete>
        </div>
      </mat-grid-tile>

      <mat-grid-tile  *ngIf="
      (optional.indexOf('date_cloture') === -1 &&
      optional.indexOf('statut') === -1) || optional.indexOf('dates') !== -1">
        <div class="row-margin" style="margin-right: 45px;">
          <mat-form-field color="primary">
            <mat-label> DATES COMPTABLES </mat-label>
            <mat-date-range-input [rangePicker]="drp">
              <input matStartDate #dstart [(ngModel)]="date_start" />
              <input matEndDate #dend [(ngModel)]="date_end" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="drp"></mat-datepicker-toggle>
            <mat-date-range-picker #drp panelClass="example-month-picker">
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="optional.indexOf('date_operation') !== -1">
        <div class="row-margin" style="margin-right: 45px;">
          <mat-form-field color="primary">
            <mat-label> DATES D'OPÉRATION </mat-label>
            <mat-date-range-input [rangePicker]="dOprp">
              <input matStartDate #dOpStart [(ngModel)]="date_Opstart" />
              <input matEndDate #dOpEend [(ngModel)]="date_Opend" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="dOprp"></mat-datepicker-toggle>
            <mat-date-range-picker #dOprp panelClass="example-month-picker">
            </mat-date-range-picker>
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="optional.indexOf('date_cloture') !== -1">
        <div class="row-margin">
          <mat-form-field color="primary">
            <input matInput placeholder="Mois comptable" [matDatepicker]="dt" [(ngModel)]="date" />
            <mat-datepicker-toggle matSuffix [for]="dt"></mat-datepicker-toggle>
            <mat-datepicker #dt panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </mat-grid-tile>

      <mat-grid-tile *ngIf="optional.indexOf('montant') !== -1" colspan="2">
        <div class="row-margin row-montant">
          <mat-select [(value)]="is_between_montant" width="70px">
              <mat-option [value]="false">Egale à</mat-option>
              <mat-option [value]="true">Entre</mat-option>
          </mat-select>
          <mat-form-field>
            <mat-label> MONTANT </mat-label>
            <input matInput type="text" #montant_value [(ngModel)]="montant_0" autocomplete="off" />
          </mat-form-field>
          <span style="margin:10px" *ngIf="is_between_montant"> et </span>
          <div *ngIf="optional.indexOf('montant') !== -1">
            <mat-form-field class="full-width" *ngIf="is_between_montant">
              <mat-label> MONTANT </mat-label>
              <input matInput type="text" #montant_value [(ngModel)]="montant_1" autocomplete="off" />
            </mat-form-field>
          </div>
        </div>
      </mat-grid-tile>

      <mat-grid-tile rowspan="1">
        <div class="action-bar">
          <button mat-button class="button-reset-search" (click)="unselect()">
            Vider
          </button>
          <button mat-button class="button-confirm-search" (click)="confirmSearch($event)">
            Valider
          </button>
          <button mat-button class="button-confirm-search-all" (click)="confirmSearchALL($event)"
            *ngIf="optional.indexOf('btn-loadall') !== -1">
            Tout recharger
          </button>
        </div>
      </mat-grid-tile>
     
    </mat-grid-list>

  </div>

  <div class="progress-bar" *ngIf="spinner">
    <mat-progress-bar class="my-color" color="red" mode="indeterminate"></mat-progress-bar>
  </div>
</mat-expansion-panel>