import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ErrorComponent } from './components/error/error.component'
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component'
import { ServerErrorsInterceptor } from './interceptors/server-errors.interceptor'
import { ErrorsService } from './services/errors.service'
import { MaterialModule } from '@app/material/material.module'

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule],
  declarations: [ErrorComponent, NotFoundPageComponent],
  providers: [
    ErrorsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
  ],
})
export class ErrorsModule {}
