import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-pre-modification-item',
  templateUrl: './pre-modification-item.component.html',
  styleUrls: ['./pre-modification-item.component.scss'],
})
export class PreModificationItemComponent implements OnInit, AfterViewInit {
  @Input() next_line: any
  @Input() actions: Action[]
  @Input() isChecked: boolean
  public dataSource: Action[]

  displayedColumns: string[] = ['ID_PAIEMENT_NEXT', 'DATE', 'INSTANCE_GN', 'SOCIETE', 'MONTANT', 'STATUT'];

  private selectAllEventSub: Subscription;
  @Input() selectAll: Observable<boolean>;

  @Output() checkEvents = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
    this.isChecked = (!this.actions.length && this.next_line['exclu'] === null) ? true : false
    this.selectAllEventSub = this.selectAll.subscribe((res) => this.isChecked = this.disabled() ? false : res)
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource = this.actions
    }, 100);
  }

  ngOnDestroy() {
    this.selectAllEventSub.unsubscribe()
  }

  getAlert() {
    let message = ''
    const length = this.actions.filter(item => item['STATUT'] === 'SOLDE' || item['STATUT'] === "MISE EN COMPTE D'ATTENTE").length
    message += length ? " Une action a un statut 'SOLDE' ou 'MISE EN COMPTE D'ATTENTE'. " : ''
    const exclude = (this.next_line['exclu'] === null) ? false : true
    message += exclude ? ' Ce flux Next a été exclu. Merci de le réinclure avant de pouvoir le manipuler. ' : ''

    return message
  }

  disabled() {
    const length = this.actions.filter(item => item['STATUT'] === 'SOLDE' || item['STATUT'] === "MISE EN COMPTE D'ATTENTE").length
    const exclude = (this.next_line['exclu'] === null) ? false : true
    return (length || exclude) ? true : false
  }

  sendStatusToParent() {
    const checkObject = {
      'fluxId': this.next_line.cle,
      'checked': this.isChecked,
      'montant': this.next_line.total
    }
    if (!this.disabled()) {
      this.checkEvents.emit(checkObject);
      setTimeout(() => {
        this.isChecked = this.isChecked ? false : true;
      }, 50)
    }
  }

}

export interface Action {
  CODE_MP_GN: string;
  DATE: string;
  ID_PAIEMENT_NEXT: string;
  INSTANCE_GN: string;
  MONTANT: string;
  SOCIETE: string;
  STATUT: string;
  TYPE_ACTION: string;
}
