<div cdkDrag class="popin-box">
  <div cdkDrag class="popin-content">
    <mat-card class="card mat-elevation-z2">
      <mat-card-header class="card-header">
        <mat-card-title>Apurer un écart</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <br />
        <br />
        <div class="progress-bar" *ngIf="spinner">
          <mat-progress-bar
            class="my-color"
            color="red"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>
        <div style="text-align: center">
          <mat-form-field>
            <mat-select
              #gn
              placeholder="REGION"
              [value]="list_regions && list_regions.length == 1 && list_regions[0]"
              required
              (selectionChange)="updateListSociete($event)"
            >
              <mat-option *ngFor="let region of list_regions" [value]="region">{{
                region
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input
              #societe
              placeholder="SOCIETE"
              matInput
              [value]="
                (list_societes && list_societes.length == 1 && list_societes[0]) ||
                ''
              "
              required
              [matAutocomplete]="auto"
              pattern="[a-zA-Z0-9]+" (mousedown)="$event.stopPropagation()"
            />
            <mat-autocomplete
              #auto
              placeholder="Société du payment next"
              name="societe"
            >
              <mat-option
                *ngFor="let sct of filtered(list_societes, societe.value || '')"
                [value]="sct"
                >{{ sct }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-select
              #mp
              placeholder="MP"
              [value]="list_mp && list_mp.length == 1 && list_mp[0]"
              required
            >
              <mat-option *ngFor="let mp of list_mps" [value]="mp">{{
                mp
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 180px">
            <input
              #date
              matInput
              [value]="def_date"
              [matDatepicker]="picker"
              placeholder="Date"
              required
              autocomplete="off" (mousedown)="$event.stopPropagation()"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input
              #montant
              matInput
              type="number"
              placeholder="Montant"
              [value]="def_montant"
              required
              autocomplete="off" (mousedown)="$event.stopPropagation()"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              #commentaire
              matInput
              placeholder="Commentaire"
              required
              autocomplete="off" (mousedown)="$event.stopPropagation()"
            />
          </mat-form-field>
          <br />
          <button class="button_delete" mat-button (click)="close()">
            Annuler
          </button>
          <br />
          <br />
          <button
            class="button_confirm"
            mat-button
            (click)="confirm(societe, gn, mp, date, montant, commentaire)"
            [disabled]="this.spinner"
          >
            Valider
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>