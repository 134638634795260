import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModuleService } from '@app/shared/services/module/module.service'
import * as _moment from 'moment'
import { Moment } from 'moment'
import { MultiAutocompleteComponent } from '../multi-autocomplete/multi-autocomplete.component'
interface ObjectMap {
  [key: string]: any
}
const moment = _moment
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCardComponent implements OnInit {
  @ViewChild('regionFilterComponent') regionFilterComponent: MultiAutocompleteComponent
  @ViewChild('societeFilterComponent') societeFilterComponent: MultiAutocompleteComponent
  @ViewChild('mpFilterComponent') mpFilterComponent: MultiAutocompleteComponent
  @ViewChild('statutFilterComponent') statutFilterComponent: MultiAutocompleteComponent
  @ViewChild('actionFilterComponent') actionFilterComponent: MultiAutocompleteComponent
  date: Moment
  date_start: Moment
  date_end: Moment

  date_Opstart: Moment
  date_Opend: Moment
  spinner: boolean

  list_regions: string[]
  list_mp: string[]
  list_mp_gn: string[]
  list_oib: string[]
  mp_matching: any
  list_societes: string[]
  liste_societes: string[] = []
  transco_region_societe: ObjectMap[] = sessionStorage.transco_region_societe
    ? JSON.parse(sessionStorage.transco_region_societe)
    : []

  list_action: string[]
  list_statut: string[]
  list_statut_inter: string[]
  // transco_region_societe: Array<Object>
  new_societe: string
  cache_societe: string
  cache_region: any[]
  cache_societes: any[]
  @Input() selectedSocieteValue: string[] = []
  @Input() selectedRegionValue: string[] = []
  @Input() selectedMpValue: string[] = []
  @Input() selectedActionValue: string[] = []
  @Input() selectedStatutValue: string[] = []
  montant_0 = '';
  montant_1 = '';
  cle_next = '';
  private message = {
    REGION: [],
    SOCIETE: [],
    MP: [],
    ACTION: [],
    STATUT: [],
    DATE: [],
    DATE_START: '',
    DATE_END: '',
    DATE_OPSTART: '',
    DATE_OPEND: '',
    LIST_REGION: [],
    LIST_SOCIETES: [],
    LIST_MP: [],
    LIST_MP_GN: [],
    LIST_OIB: [],
    LIST_ACTION: [],
    LIST_STATUT: [],
    MONTANT_0: '',
    MONTANT_1: '',
    LOAD_ALL_DATA: false,
    ID_PAIEMENT_NEXT: '',
    IS_BETWEEN_MONTANT: false
  }
  @Input() optional: string[] = []
  @Input() export: () => void
  @Output() messageEvent = new EventEmitter<string>()
  @Output() messageAllEvent = new EventEmitter<string>()
  @Output() dateEvent = new EventEmitter<string>()

  isExpanded: boolean = true;
  is_between_montant: boolean = false;


  constructor(
    private moduleService: ModuleService,
    private _snackBar: MatSnackBar,
    private ngZone: NgZone,
    private ch: ChangeDetectorRef
  ) {
    if (localStorage.societe) {
      const arrayS = localStorage.societe.split(',')
      this.selectedSocieteValue = arrayS
      this.message.SOCIETE = arrayS
    }
    if (localStorage.region) {
      const arrayR = localStorage.region.split(',')
      this.selectedRegionValue = arrayR
      this.message.REGION = arrayR
    }
    if (localStorage.mp) {
      const arrayM = localStorage.mp.split("','")
      this.selectedMpValue = arrayM
      this.message.MP = arrayM
    }
    if (localStorage.action) {
      const arrayA = localStorage.action.split(',')
      this.selectedActionValue = arrayA
      this.message.ACTION = arrayA
    }
    if (localStorage.statut) {
      const arrayS = localStorage.statut.split(',')
      this.selectedStatutValue = arrayS
      this.message.STATUT = arrayS
    }
    if (localStorage.date_start) {
      this.date_start = moment(localStorage.date_start)
      this.message.DATE_START = localStorage.date_start
    } else if (!this.date) {
      this.date_start = moment().startOf('week')
      this.message.DATE_START = this.date_start.format('YYYY-MM-DD')
    }
    if (this.optional.indexOf('date_cloture') !== -1) {
      this.date = this.date_start
    }

    if (localStorage.date_end) {
      this.date_end = moment(localStorage.date_end)
      this.message.DATE_END = localStorage.date_end
    } else if (!this.date) {
      this.date_end = moment().endOf('week')
      this.message.DATE_END = this.date_end.format('YYYY-MM-DD')
    }

    // if (localStorage.date_Opstart) {
    //   this.date_Opstart = moment(localStorage.date_Opstart)
    //   this.message.DATE_OPSTART = localStorage.date_Opstart
    // } else {
    //   this.date_Opstart = moment().startOf('week')
    //   this.message.DATE_OPSTART = this.date_Opstart.format('YYYY-MM-DD')
    // }
    // if (localStorage.date_Opend) {
    //   this.date_Opend = moment(localStorage.date_Opend)
    //   this.message.DATE_OPEND = localStorage.date_Opend
    // } else {
    //   this.date_Opend = moment().endOf('week')
    //   this.message.DATE_OPEND = this.date_Opend.format('YYYY-MM-DD')
    // }

    if (localStorage.is_between_montant) {
      this.is_between_montant = Boolean(localStorage.is_between_montant);
      this.message.IS_BETWEEN_MONTANT = Boolean(localStorage.is_between_montant);
    }

    if (localStorage.montant_0) {
      this.montant_0 = localStorage.montant_0;
      this.message.MONTANT_0 = localStorage.montant_0;
    }
    if (localStorage.montant_1) {
      this.montant_1 = localStorage.montant_1;
      this.message.MONTANT_1 = localStorage.montant_1;
    }
    if (localStorage.cle_next) {
      this.cle_next = localStorage.cle_next;
      this.message.ID_PAIEMENT_NEXT = localStorage.cle_next;
    }
    if (!this.date && this.date_start && this.date_end) {
      const dates = []
      const tmp = moment(this.date_start)
      while (1) {
        if (
          dates.length > 12 ||
          tmp.year() > this.date_end.year() ||
          (tmp.year() === this.date_end.year() &&
            tmp.month() > this.date_end.month())
        ) {
          break
        }
        dates.push(tmp.format('MM/YYYY'))
        tmp.add(1, 'month')
      }
      this.message.DATE = dates
    } else {
      this.message.DATE = (this.date && [this.date.format('MM/YYYY')]) || [
        moment(this.date_start).format('MM/YYYY'),
      ]
    }
  }

  ngOnInit() {
    if (!sessionStorage.list_mp) {
      this.spinner = true

      if (!this.spinner) {
        return
      }
      setTimeout(() => {
        this.moduleService.getFilterElements().subscribe(
          (result) => {
            if (!this.spinner) {
              return
            }
            this.transco_region_societe = result.TRANSCO_REGION_SOCIETE
            this.list_societes = result.SOCIETE.sort()
            this.list_regions = result.REGION.sort()
            this.list_mp = result.MP.sort().filter(Boolean)
            this.list_mp_gn = result.MP_GN.sort()
            this.list_oib = result.OIB.sort()
            this.mp_matching = result.MP_MATCHING
            this.list_action = result.ACTION.sort().filter(Boolean)
            this.list_statut = result.STATUT.sort().filter(Boolean)
            this.list_statut_inter = result.STATUT_INTER.sort().filter(Boolean)
            this.message.LIST_MP = this.list_mp
            this.message.LIST_MP_GN = this.list_mp_gn
            this.message.LIST_OIB = this.list_oib
            this.message.LIST_REGION = this.list_regions
            this.message.LIST_SOCIETES = this.list_societes
            this.message.LIST_STATUT = this.list_statut
            sessionStorage.setItem(
              'transco_region_societe',
              JSON.stringify(this.transco_region_societe)
            )
            sessionStorage.setItem(
              'list_societes',
              JSON.stringify(this.list_societes)
            )
            sessionStorage.setItem(
              'list_regions',
              JSON.stringify(this.list_regions)
            )
            sessionStorage.setItem(
              'list_action',
              JSON.stringify(this.list_action)
            )
            sessionStorage.setItem(
              'list_statut',
              JSON.stringify(this.list_statut)
            )
            sessionStorage.setItem(
              'list_statut_inter',
              JSON.stringify(this.list_statut_inter)
            )
            sessionStorage.setItem('list_mp', JSON.stringify(this.list_mp))
            sessionStorage.setItem('list_mp_gn', JSON.stringify(this.list_mp_gn))
            sessionStorage.setItem('list_oib', JSON.stringify(this.list_oib))
            sessionStorage.setItem('mp_matching', JSON.stringify(this.mp_matching))
            if (this.message.DATE) {
              this.dateEvent.emit()
            }
            this.spinner = false
          },
          (error) => {
            this.spinner = false
            this._snackBar.open('Chargement des filtres impossible', '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          }
        )
      }, 1000);
    } else {
      this.list_societes = JSON.parse(sessionStorage.list_societes).sort()
      this.list_regions = JSON.parse(sessionStorage.list_regions).sort()
      this.list_mp = JSON.parse(sessionStorage.list_mp).sort((item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1))
      this.list_mp_gn = JSON.parse(sessionStorage.list_mp_gn).sort((item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1))
      this.list_oib = JSON.parse(sessionStorage.list_oib).sort((item_one: string, item_two: string) => (item_one.toUpperCase() > item_two.toUpperCase() ? 1 : -1))
      this.list_action = JSON.parse(sessionStorage.list_action)
      this.list_statut = JSON.parse(sessionStorage.list_statut)
      this.list_statut_inter = JSON.parse(sessionStorage.list_statut_inter)
      this.message.LIST_MP = this.list_mp
      this.message.LIST_MP_GN = this.list_mp_gn
      this.message.LIST_OIB = this.list_oib
      this.message.LIST_REGION = this.list_regions
      this.message.LIST_SOCIETES = this.list_societes
      this.message.LIST_ACTION = this.list_action
      this.message.LIST_STATUT = this.list_statut
      setTimeout(() => {
        this.dateEvent.emit()
      }, 1000)
    }
  }

  confirmSearch($event: MouseEvent & { target: HTMLElement }) {
    this.isExpanded = false;
    this.formatMessage($event)
  }

  confirmSearchALL($event: MouseEvent & { target: HTMLElement }) {
    this.isExpanded = false;
    this.message.LOAD_ALL_DATA = true
    this.formatMessage($event)
  }

  formatMessage($event: MouseEvent & { target: HTMLElement }) {
    // SI tous les champs de recherche sont vides, demander à l'utilisateur de saisir au moins un
    // critère de recherche
    if (!this.selectedRegionValue.length &&
      !this.selectedSocieteValue.length &&
      !this.selectedMpValue.length &&
      !this.selectedActionValue.length &&
      !this.selectedStatutValue.length &&
      !this.date_start &&
      !this.date_end &&
      !this.date_Opstart &&
      !this.date_Opend &&
      !this.date &&
      !this.cle_next.length) {
      this._snackBar.open('Veuillez sélectionner au moins un critère de recherche', '', {
        duration: 10000,
        panelClass: ['green-snackbar'],
      })
      return
    }
    let date_comptable = false
    let date_operation = false
    if ((this.optional.indexOf('date_cloture') === -1 &&
      this.optional.indexOf('statut') === -1)
      || this.optional.indexOf('dates') !== -1) {
      date_comptable = true
      if (!this.date_start && !this.date_end) {
        date_comptable = false
      }
    }

    if (this.optional.indexOf('date_operation') !== -1) {
      date_operation = true
      if (!this.date_Opstart && !this.date_Opend) {
        date_operation = false
      }
    }

    if (!date_comptable && !date_operation) {
      this._snackBar.open("Veuillez sélectionner soit un intervalle de date comptable ou un intervalle de date d'opération", '', {
        duration: 10000,
        panelClass: ['green-snackbar'],
      })
      return
    }

    // On vérifie si le bouton sur lequel le clic a été effectué contient toujours un arrière plan
    // Si c'est le cas, on effectue pas de recherche.
    // En effet, on se sert de la couleur d'arrière plan (background) pour indiquer qu'un bouton vient
    // de recevoir un clic et qu'il faut attendre un certain temps avant de cliquer à nouveau sur ce bouton
    const elem =
      $event.target.tagName === 'BUTTON'
        ? $event.target
        : $event.target.parentElement
    if (elem.style.background) {
      return
    }
    elem.style.background = 'lightblue'
    this.ch.detectChanges()
    setTimeout(() => {
      if (this.selectedRegionValue.length) {
        const allRegions = this.selectedRegionValue
        this.message.REGION = allRegions
        localStorage.setItem('region', allRegions as any)
      } else {
        this.message.REGION = undefined
        localStorage.removeItem('region')
      }
      if (this.selectedSocieteValue.length) {
        const allSocietes = this.selectedSocieteValue
        this.message.SOCIETE = allSocietes
        localStorage.setItem('societe', allSocietes as any)
      } else {
        this.message.SOCIETE = undefined
        localStorage.removeItem('societe')
      }
      if (this.selectedMpValue.length) {
        const allMp = this.selectedMpValue
        this.message.MP = allMp
        localStorage.setItem('mp', allMp.join("','"))
      } else {
        this.message.MP = undefined
        localStorage.removeItem('mp')
      }
      if (this.selectedActionValue.length) {
        const allAction = this.selectedActionValue
        this.message.ACTION = allAction
        localStorage.setItem('action', allAction.join(','))
      } else {
        this.message.ACTION = undefined
        localStorage.removeItem('action')
      }
      if (this.selectedStatutValue.length) {
        const allStatut = this.selectedStatutValue
        this.message.STATUT = allStatut
        localStorage.setItem('statut', allStatut.join(','))
      } else {
        this.message.STATUT = undefined
        localStorage.removeItem('statut')
      }
      if (this.date_start) {
        const date_start = this.date_start.format('YYYY-MM-DD')
        this.message.DATE_START = date_start
        localStorage.setItem('date_start', date_start)
      }
      if (this.date_end) {
        const date_end = this.date_end.format('YYYY-MM-DD')
        this.message.DATE_END = date_end
        localStorage.setItem('date_end', date_end)
      }

      if (this.date_Opstart) {
        const date_Opstart = this.date_Opstart.format('YYYY-MM-DD')
        this.message.DATE_OPSTART = date_Opstart
        localStorage.setItem('date_Opstart', date_Opstart)
      }
      if (this.date_Opend) {
        const date_Opend = this.date_Opend.format('YYYY-MM-DD')
        this.message.DATE_OPEND = date_Opend
        localStorage.setItem('date_Opend', date_Opend)
      }
      if (!this.date && this.date_start && this.date_end) {
        const dates = []
        const tmp = moment(this.date_start)
        while (1) {
          if (
            dates.length > 12 ||
            tmp.year() > this.date_end.year() ||
            (tmp.year() === this.date_end.year() &&
              tmp.month() > this.date_end.month())
          ) {
            break
          }
          dates.push(tmp.format('MM/YYYY'))
          tmp.add(1, 'month')
        }
        this.message.DATE = dates
      } else {
        this.message.DATE = (this.date && [this.date.format('MM/YYYY')]) || [
          moment(this.date_start).format('MM/YYYY'),
        ]
      }

      if (this.montant_0) {
        if (isNaN(parseFloat(this.montant_0))) {
          this._snackBar.open('Montant: Format incorrect', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
          return
        } else {
          this.message.MONTANT_0 = this.montant_0
          localStorage.setItem('montant_0', this.montant_0)
        }
      } else {
        this.message.MONTANT_0 = undefined
        localStorage.removeItem('montant_0')
      }
      if ( this.is_between_montant && this.montant_1) {
        if (isNaN(parseFloat(this.montant_1))) {
          this._snackBar.open('Montant: Format incorrect', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
          return
        } else {
          this.message.MONTANT_1 = this.montant_1;
          localStorage.setItem('montant_1', this.montant_1);
          this.message.IS_BETWEEN_MONTANT = this.is_between_montant;
          localStorage.setItem('is_between_montant', this.is_between_montant.toString());
        }
      } else {
        this.message.MONTANT_1 = undefined;
        localStorage.removeItem('montant_1');
        this.message.IS_BETWEEN_MONTANT = this.is_between_montant;
        localStorage.removeItem('is_between_montant');
      }
      if (!this.message.DATE) {
        this._snackBar.open('Date manquante', '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
        return
      }
      if (this.cle_next) {
          this.message.ID_PAIEMENT_NEXT = this.cle_next
          localStorage.setItem('cle_next', this.cle_next)
      } else {
        this.message.ID_PAIEMENT_NEXT = undefined
        localStorage.removeItem('cle_next')
      }
      this.messageEvent.emit(this.message as any)
      setTimeout(() => {
        elem.style.background = ''
        this.initializeMessage()
      }, 5000)
    }, 200)
  }

  initializeMessage() {
    this.message = {
      REGION: [],
      SOCIETE: [],
      MP: [],
      ACTION: [],
      STATUT: [],
      DATE: [],
      DATE_START: '',
      DATE_END: '',
      DATE_OPSTART: '',
      DATE_OPEND: '',
      LIST_REGION: [],
      LIST_SOCIETES: [],
      LIST_MP: [],
      LIST_MP_GN: [],
      LIST_OIB: [],
      LIST_ACTION: [],
      LIST_STATUT: [],
      MONTANT_0: '',
      MONTANT_1: '',
      LOAD_ALL_DATA: false,
      ID_PAIEMENT_NEXT: '',
      IS_BETWEEN_MONTANT: false
    }
  }

  unselect() {
    this.selectedSocieteValue = []
    this.selectedRegionValue = []
    this.selectedMpValue = []
    this.selectedActionValue = []
    this.selectedStatutValue = []

    this.montant_0 = '';
    this.montant_1 = '';
    this.ngZone.runOutsideAngular(() => {
      const now = moment()
      this.date_start = null // moment(now.startOf('week'))
      this.date_end = null // now.endOf('week').startOf('day')
      if (this.optional.indexOf('date_cloture') !== -1) {
        this.date = this.date_start
      }

      this.date_Opstart = null // moment(now.startOf('week'))
      this.date_Opend = null // now.endOf('week').startOf('day')

      this.message.DATE_START = '' // this.date_start.format('YYYY-MM-DD')
      this.message.DATE_END = '' // this.date_end.format('YYYY-MM-DD')
      this.message.DATE = [] // [now.format('MM/YYYY')]

      this.message.DATE_OPSTART = ''; // this.date_Opstart.format('YYYY-MM-DD')
      this.message.DATE_OPEND = ''; // this.date_Opend.format('YYYY-MM-DD')
      this.message.REGION = undefined;
      this.message.SOCIETE = undefined;
      this.message.MONTANT_0 = undefined;
      this.message.MONTANT_1 = undefined;
      this.message.MP = undefined;
      this.message.ID_PAIEMENT_NEXT = undefined;

      localStorage.removeItem('date_start')
      localStorage.removeItem('date_end')
      localStorage.removeItem('date_Opstart')
      localStorage.removeItem('date_Opend')

      localStorage.removeItem('mp')
      localStorage.removeItem('action')
      localStorage.removeItem('statut')
      localStorage.removeItem('region')
      localStorage.removeItem('societe')
      localStorage.removeItem('montant')
      localStorage.removeItem('cle_next')

      if (this.regionFilterComponent) this.regionFilterComponent.reset()
      if (this.societeFilterComponent) this.societeFilterComponent.reset()
      if (this.mpFilterComponent) this.mpFilterComponent.reset()
      if (this.statutFilterComponent) this.statutFilterComponent.reset()
      if (this.actionFilterComponent) this.actionFilterComponent.reset()
    })
  }

  get mapRegionSociete() {
    if (this.selectedRegionValue.length === 0) {
      if (this.list_societes != undefined && this.list_societes.length > 0) {
        const sorted = this.list_societes.sort((a, b) => {
          return a.localeCompare(b, 'fr', {
            numeric: true})
          });
        return sorted
      }
    }
    this.cache_societes = []
    for (const [region, liste_societe] of Object.entries(
      this.transco_region_societe
    )) {
      if (this.selectedRegionValue.includes(region)) {
        this.cache_societes = this.cache_societes.concat(
          this.transco_region_societe[region]
        )
      }
    }
    if (this.cache_societes.length === 0) {
      this.cache_societes = this.list_societes
    }
    if (this.cache_societes != undefined && this.cache_societes.length > 0) {
      const sorted = this.cache_societes.sort((a, b) => {
        return a.localeCompare(b, 'fr', {
          numeric: true})
        });
      return sorted
    }
  }

  get mapSocieteRegion() {
    if (this.selectedSocieteValue.length === 0) {
      return this.list_regions
    }
    this.cache_region = []

    for (const [region, liste_societe] of Object.entries(
      this.transco_region_societe
    )) {
      const found = liste_societe.filter((societe) =>
        this.selectedSocieteValue.includes(societe)
      )
      if (found.length > 0) {
        this.cache_region.push(region)
      }
    }
    if (this.cache_region.length === 0) {
      this.cache_region = this.list_regions
    }
    return this.cache_region ? this.cache_region.sort() : []
  }
}
