import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewChildren,
  AfterViewInit
} from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'

import * as XLSX from 'xlsx'
import * as moment from 'moment'
/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'app-tabreport',
  templateUrl: './tabreport.component.html',
  styleUrls: ['./tabreport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabReportComponent implements OnChanges, AfterViewInit {
  displayedColumns: string[] = []
  selectedColumns: string[] = []
  dataSource: MatTableDataSource<string[]>
  maximize = false
  incomplete: boolean
  ordered: string
  lastElem: any
  toggleShowExcludedData: boolean
  col_contain = []
  contain = []
  col_sup = []
  sup = []
  col_inf = []
  inf = []
  maskedData: string[][]

  contextmenu = false
  contextmenuX = 0
  mapped: [string, Array<string>, (x: string) => void][]
  startSel = false
  somme: string = ''
  hasKey = false

  @Input() hideTableKey: boolean = false
  @Input() title: string
  @Input() otherTabs: TabReportComponent[]
  @Input() data: string[][]
  @Input() private columns: string[]
  @Input() lineClick: () => void
  @Input() excludedData: string[][]
  @Input() excludedDataLineClick: () => void

  @ViewChildren('div')
  elements: any

  private sum(data: any[]) {
    if (!data || !data.length) {
      return '0.0'
    }

    return data.reduce((partialSum, a) => Number((partialSum + parseFloat(a['total']) + 0.001).toFixed(2)), 0.0).toFixed(2)
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.data = this.data
    }
  }

  toggleSel() {
    this.selectedColumns =
      this.selectedColumns.length === this.dataSource.data.length
        ? []
        : this.dataSource.data.map((x) => x['cle'])
  }

  doubleclick(row: {}) {
    const index = this.selectedColumns.indexOf(row['cle'])
    if (index < 0) {
      this.selectedColumns.push(row['cle'])
    } else {
      this.selectedColumns.splice(index, 1)
    }
  }

  private containsStr(a: string, b: string) {
    return a.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(b.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())
  }

  private async filter_tab() {

    const dataToFilter = (! this.toggleShowExcludedData) ? this.data : this.excludedData

    if (!dataToFilter) {
      return
    }

    this.dataSource = new MatTableDataSource<string[]>(
      dataToFilter.filter((x) => {
        let condition = true
        // col contains
        for (const i in this.col_contain) {
          if (this.col_contain.hasOwnProperty(i)) {
            const col_name = this.col_contain[i]
            condition = condition && x[col_name] && this.containsStr(x[col_name], this.contain[col_name])
          }
        }

        // col col_sup
        for (const i in this.col_sup) {
          if (this.col_sup.hasOwnProperty(i)) {
            const col_name = this.col_sup[i]
            condition = condition && x[col_name] &&
              (isNaN(this.sup[col_name] as any)
                ? x[col_name] < this.sup[col_name]
                : parseFloat(x[col_name]) > parseFloat(this.sup[col_name]))
          }
        }

        // col col_inf
        for (const i in this.col_inf) {
          if (this.col_inf.hasOwnProperty(i)) {
            const col_name = this.col_inf[i]
            condition = condition && x[col_name] &&
              (isNaN(this.inf[col_name] as any)
                ? x[col_name] < this.inf[col_name]
                : parseFloat(x[col_name]) < parseFloat(this.inf[col_name]))
          }
        }

        return condition
      })
    )
    this.somme = this.sum(this.dataSource.data)
  }

  async filter_str(col: string, event: MouseEvent) {
    this.context(event as any, [
      [
        'Contient (' + (col).substr(0, 8) + ') : ',
        (this.contain[col]) ? this.contain[col] : '',
        (val: string) => {
          if (val.length === 0) {
            let reload = false
            if (this.col_contain.indexOf(col) > -1) {
              reload = true
            }
            const col_index = this.col_contain.indexOf(col)
            if (col_index > -1) {
              this.col_contain.splice(col_index, 1)
            }
            this.contain[col] = ''
            if (reload) {
              this.filter_str(col, event)
            }
          } else {
            if (this.col_contain.indexOf(col) === -1) {
              this.col_contain.push(col)
            }
            this.contain[col] = val
          }
          this.filter_tab()
        },
      ],
      [
        'Supérieur à (' + (col).substr(0, 8) + ') : ',
        (this.sup[col]) ? this.sup[col] : '',
        (val: string) => {
          if (val.length === 0) {
            let reload = false
            if (this.col_sup.indexOf(col) > -1) {
              reload = true
            }
            const col_index = this.col_sup.indexOf(col)
            if (col_index > -1) {
              this.col_sup.splice(col_index, 1)
            }
            this.sup[col] = ''
            if (reload) {
              this.filter_str(col, event)
            }
          } else {
            if (this.col_sup.indexOf(col) === -1) {
              this.col_sup.push(col)
            }
            this.sup[col] = val
          }
          this.filter_tab()
        },
      ],
      [
        'Inférieur à (' + (col).substr(0, 8) + ') : ',
        (this.inf[col]) ? this.inf[col] : '',
        (val: string) => {
          if (val.length === 0) {
            let reload = false
            if (this.col_inf.indexOf(col) > -1) {
              reload = true
            }
            const col_index = this.inf.indexOf(col)
            if (col_index > -1) {
              this.inf.splice(col_index, 1)
            }
            this.inf[col] = ''
            if (reload) {
              this.filter_str(col, event)
            }
          } else {
            if (this.col_inf.indexOf(col) === -1) {
              this.col_inf.push(col)
            }
            this.inf[col] = val
          }
          this.filter_tab()
        },
      ],
      [
        'Vider les filtres',
        'click',
        (val: string) => {
          this.reset_filters()
          this.filter_tab()
        },
      ],
    ])
  }

  private async reset_filters(resetSel = false) {
    this.col_contain = []
    this.contain = []
    this.col_sup = []
    this.sup = []
    this.col_inf = []
    this.inf = []
    this.contextmenu = false
    if (resetSel) { this.selectedColumns = [] }
  }

  // activates the menu with the coordinates
  private async context(
    event: MouseEvent & { target: { offsetParent: { offsetLeft: number } } },
    mapped: [string, Array<string>, (x: string) => void][]
  ) {
    this.contextmenuX = event.target.offsetParent.offsetLeft + event.offsetX + 5
    this.contextmenu = true
    this.mapped = mapped
  }

  split_tabs(line: TabReportComponent) {
    this.maximize = true
    this.elements.first.nativeElement.className = 'maximize half'
    this.elements.first.nativeElement.style = 'z-index:998'
    this.otherTabs.forEach((x, _, __) => {
      x.elements.first.nativeElement.style = 'z-index:997'
    })
    line.maximize = true
    line.elements.first.nativeElement.className = 'maximize half hright'
    line.elements.first.nativeElement.style = 'z-index:998'
  }

  ngOnChanges() {
    this.reset_filters(true)
    this.toggleShowExcludedData = false
    this.incomplete = false
    this.maximize = false
    if (this.data && this.data.length === 1000) {
      this.incomplete = true
    }
    if (this.columns.indexOf('cle') >= 0 && this.columns.indexOf('_') === -1) {
      this.displayedColumns = ['_'].concat(this.columns)
      this.hasKey = true
    } else {
      this.displayedColumns = this.columns
    }
    this.filter_tab()
  }

  order(key: string, event: MouseEvent & { toElement: any }) {
    const asc = this.ordered === key ? -1 : 1
    const data = this.dataSource.data
    this.dataSource = new MatTableDataSource<string[]>(
      data.sort((x, y) =>
        (
          isNaN(x[key]) || isNaN(y[key])
            ? x[key] > y[key]
            : parseFloat(x[key]) > parseFloat(y[key])
        )
          ? 1 * asc
          : -1 * asc
      )
    )
    this.somme = this.sum(this.dataSource.data)
    if (this.lastElem) {
      this.lastElem.style = ''
    }
    if (event.toElement) {
      event.toElement.style = 'background-color:#00ccff'
    }
    this.lastElem = event.toElement
    this.ordered = this.ordered === key ? undefined : key
  }

  removeFilter(key: string, container: Array<string>, values: Array<string>) {
    const index = container.indexOf(key);
    if (index !== -1) { container.splice(index, 1); }
    delete values[key]
    this.filter_tab()
  }
  exportAsExcel() {
    const name = (
      this.title || (location.pathname && location.pathname.substr(1)) || 'homepage'
    ).slice(0, 30)

    let data = Object.assign([], this.dataSource.data)
    let id_column = this.displayedColumns.splice(this.displayedColumns.indexOf('id next'), 1)[0];
    let dataHeader = Object.assign([], this.displayedColumns)

    data = this.formatData(data)
    dataHeader = this.formatDataHeader(dataHeader)

    data = this.convertStringFieldToNumber(data)
    data = this.formatDateCell(data)

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
      header: [...dataHeader],
    })

    this.formatCells(ws)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, name)
    XLSX.writeFile(wb, name + '.xlsx')
  }

  formatCells(obj: any) {
    for (const item in obj) {
      if (obj[item].t === 'n') {
        obj[item].z = '0.00'
      }
    }
  }

  formatData(d: any = []) {
    const ds = d.map(item => {

      if (item['id'] && item['cle'] && item['cle'] === item['id']) {
        delete item.id
      }

      if (item.cle) {
        item.cle += ''
      }

      return item
    })
    return ds
  }

  formatDataHeader(dh: any = []) {
    let dataH = Object.assign([], dh)

    if (dh['id'] && dh['cle'] && dh['cle'] === dh['id']) {
      dataH = dataH.filter(item => item !== 'id')
    }
    if (Object.keys(dh).indexOf('total') > -1 && Object.keys(dh).indexOf('montant') > -1 ) {
      dataH = dataH.filter(item => item !== 'total')
    }

    return dataH
  }

  convertStringFieldToNumber(arr: any[]) {
    const dataToExport = arr.map((item) => {
      if (item.total_flux_next) {
        item.total_flux_next = +item.total_flux_next
      }
      if (item.total_actions_next) {
        item.total_actions_next = +item.total_actions_next
      }
      if (item.total_flux_gn) {
        item.total_flux_gn = +item.total_flux_gn
      }
      if (item.montant) {
        item.montant = +item.montant
      }
      if (item.montant_paiement) {
        item.montant_paiement = +item.montant_paiement
      }
      if (item.total) {
        item.total = +item.total
      }
      return item
    })
    return dataToExport
  }

  formatDateCell(arr: any[]) {
    const dataToExport = arr.map((item) => {
      if (item.hasOwnProperty('created_at')) {
        item.created_at = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')
      }
      if (item.hasOwnProperty('updated_at')) {
        item.updated_at = moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss')
      }
      return item
    })
    return dataToExport
  }

  showExcludedData() {
    this.toggleShowExcludedData = !this.toggleShowExcludedData
    if (this.toggleShowExcludedData) {
      this.dataSource = new MatTableDataSource<string[]>(this.excludedData)
      this.somme = this.sum(this.dataSource.data)
    } else {
      this.dataSource = new MatTableDataSource<string[]>(this.data)
      this.somme = this.sum(this.dataSource.data)
    }
  }

  displayValue(value) {
    const v = String(value)
    return v.length > 32 ? v.slice(0, 33) + '...' : v
  }
}
