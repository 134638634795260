import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ComponentsModule } from '@app/components/components.module'
import { DashboardRoutingModule } from '@app/dashboard/dashboard-routing.module'
import { MaterialModule } from '@app/material/material.module'
import { ErrorsModule } from '@app/shared/errors/errors.module'
import { DashboardComponent } from './dashboard.component'
import { DateClotureComponent } from './date-cloture/date-cloture.component'
import { FilterpayementsComponent } from './filterpayements/filterpayements.component'
import { Numeric2Directive } from './numeric2.directive'
import { SolderIntersocietesComponent } from './solder-intersocietes/solder-intersocietes.component'
import { TableauClotureComponent } from './tableau-clotures/tableau.component'
import { TableauComponent } from './tableau/tableau.component'
import { UpdatepayementsModalComponent } from './updatepayements/updatepayements-modal/updatepayements-modal.component'
import { UpdatepayementsComponent } from './updatepayements/updatepayements.component';
import { ExportDataComponent } from './export-data/export-data.component';
import { PreModificationComponent } from './updatepayements/pre-modification/pre-modification.component';
import { PreModificationItemComponent } from './updatepayements/pre-modification-item/pre-modification-item.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        DashboardRoutingModule,
        FormsModule,
        RouterModule,
        ErrorsModule,
        ComponentsModule,
        DragDropModule
    ],
    declarations: [
        DashboardComponent,
        FilterpayementsComponent,
        UpdatepayementsComponent,
        UpdatepayementsModalComponent,
        TableauComponent,
        TableauClotureComponent,
        Numeric2Directive,
        SolderIntersocietesComponent,
        DateClotureComponent,
        ExportDataComponent,
        PreModificationComponent,
        PreModificationItemComponent,
    ],
})
export class DashboardModule {}
