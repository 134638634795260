<mat-card class="card mat-elevation-z2">
  <app-search-card
    (messageEvent)="receiveMessage($event)"
    [optional]="['action']"
    class="home-card"
  ></app-search-card>
  <mat-card-header class="card-header">
    <mat-card-title
      style="text-align: center; position: relative; top: 12px; height: 30px"
      >Liste des transactions</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="card-content">
    <div class="progress-bar" *ngIf="spinner">
      <mat-progress-bar
        class="my-color"
        color="red"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
    <app-tabreport [data]="data" [columns]="displayedColumns"> </app-tabreport>
  </mat-card-content>
</mat-card>
