import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component'
import { RgpdModalComponent } from '@app/components/modals/rgpd-modal/rgpd-modal.component'
import { User } from '@app/models/user'
import firebase from 'firebase/app'
import { GoogleApiService, GoogleAuthService } from 'ng-gapi'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable()
export class AuthenticationService {
  constructor(
    private gapiService: GoogleApiService,
    private googleAuthService: GoogleAuthService,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) {
    const inter = setInterval(() => {
      const c = firebase.auth().currentUser
      if (c) {
        c.getIdToken(false)
        clearInterval(inter)
      }
    }, 1000 * 60 * 20)
  }
  private currentUserSource = new BehaviorSubject<User>(new User())

  static initFirebaseApp() {
    const config = {
      apiKey: environment.apiKey,
      authDomain: environment.authDomain,
    }
    firebase.initializeApp(config)
  }

  getUserIdToken(): Observable<string | null> {
    const c = firebase.auth().currentUser
    if (!c) {
      return new Observable()
    }
    return from(c.getIdToken(false))
  }

  public loadGapi(): Observable<any> {
    return this.gapiService.onLoad()
  }

  public signIn() {
    this.googleAuthService.getAuth().subscribe((auth) => {
      auth.signIn().then(() => {})
    })
  }

  private connectionError(e: any) {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Connection Error',
        description: e.toString(),
      },
    })
    throw e
  }

  setUserData(user: User) {
    localStorage.setItem('user', JSON.stringify(user))
    this.currentUserSource.next(user)
  }

  setToken(token: string) {
    localStorage.setItem('token', token)
  }

  getUserdata(): Observable<User> {
    try {
      return this.httpClient.get<User>(`${environment.API_URL}/users/me/`)
    } catch (e) {
      this.connectionError(e)
    }
  }

  async getUser() {
    return new Promise((resolve, reject) => {
      const userData = localStorage.getItem('user')
      if (localStorage.getItem('token')) {
        if (userData === null) {
          firebase.auth().onAuthStateChanged((_) => {
            this.httpClient
              .get<User>(`${environment.API_URL}/users/me/`)
              .subscribe(
                (user) => {
                  this.setUserData(user)
                  resolve(user)
                },
                (error) => {
                  reject(error)
                }
              )
          })
        } else {
          const userObject = JSON.parse(userData)
          this.currentUserSource.next(userObject)
          resolve(userObject)
        }
      }
    }).catch((e) => {
      if (e !== 'OK') {
        this.connectionError(e)
      }
    })
  }

  showRGPD() {
    const rgpdDialog = this.dialog.open(RgpdModalComponent, {
      disableClose: true,
      closeOnNavigation: false,
      width: '750px',
      minHeight: '520px',
    })
    rgpdDialog.afterClosed().subscribe(() => {
      try {
        this.httpClient
          .put(`${environment.API_URL}/users/me/`, { accept_rgpd: true })
          .subscribe((res) => {
            const userData = localStorage.getItem('user')
            const userObject = JSON.parse(userData)
            userObject.show_rgpd = false
            localStorage.setItem('user', JSON.stringify(userObject))
          })
      } catch (e) {
        this.connectionError(e)
      }
    })
  }

  logout(reload = true) {
    localStorage.clear()
    if (window.indexedDB) {
      window.indexedDB.deleteDatabase('CashWater')
    }
    firebase.auth().signOut()
    if (reload) {
      window.location.href = '/'
    }
  }
}
