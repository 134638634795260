import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss'],
})
export class MultiAutocompleteComponent implements OnInit {
  control = new FormControl()
  @Input() label: string
  @Input() listVal: string[]
  @Input() retval: string[]
  @ViewChild('valInput') valInput: ElementRef<HTMLInputElement>
  @ViewChild('auto') auto: ElementRef<HTMLElement>
  filteredVal: Observable<{ name: string; selected: boolean }[]>
  ngOnInit() {
    this.filteredVal = this.control.valueChanges.pipe(map(this.filter_val))
  }

  update(value: string, event) {
    const val = value
    const indexed = this.retval.indexOf(val) >= 0
    if (!indexed && this.listVal.indexOf(val) >= 0) {
      this.retval.push(val)
    } else if (indexed) {
      this.retval.splice(this.retval.indexOf(val), 1)
    }
    this.control.setValue('')
    event.stopPropagation()
  }

  displayFn() {
    return (this.retval && this.retval.length && this.retval.join(', ')) || ''
  }

  filter_val = (val: string) => {
    return this.listVal
      .filter((x) => !val || x.toLowerCase().indexOf(val.toLowerCase()) >= 0)
      .map((x) => ({ name: x, selected: this.retval.indexOf(x) >= 0 }))
  }

  reset() {
    if (this.auto['panel']) {
      const checkboxes = this.auto['panel'].nativeElement.getElementsByClassName('option-checkbox')
      for (const item of checkboxes) {
        item.classList.remove('mat-checkbox-checked')
      }
    }
  }
}
