import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModuleService } from '@app/shared/services/module/module.service'

@Component({
  selector: 'app-updatepayements-modal',
  templateUrl: './updatepayements-modal.component.html',
  styleUrls: ['./updatepayements-modal.component.scss'],
})
export class UpdatepayementsModalComponent implements OnInit {
  spinner: any
  message: any
  action: any
  constructor(
    public matDialogRef: MatDialogRef<UpdatepayementsModalComponent>,
    public moduleService: ModuleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar
  ) {
    this.message = this.data.dataKey[0].message
    this.action = this.data.dataKey[0].action
  }

  ngOnInit() {}

  close() {
    this.matDialogRef.close('confirm')
  }

  annuler() {
    this.matDialogRef.close('cancel')
  }

  validateUpdate(close = false) {
    // Send request to the backend to validate all the modules with this refEnvoi
    this.spinner = true
    if (this.action === 'updatepayements') {
      this.moduleService.updatepayements(this.data.dataKey).subscribe(
        (result) => {
          this.spinner = false
          this._snackBar.open('Mise à jour validée', '', {
            duration: 5000,
            panelClass: ['blue-snackbar'],
          })
          this.close()
        },
        (error) => {
          this.spinner = false
          console.error(error)
          this._snackBar.open('Erreur', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
    if (this.action === 'deleteCuttingData') {
      this.moduleService.deleteCuttingData(this.data.dataKey).subscribe(
        (result) => {
          this.spinner = false

          this._snackBar.open('Mise à jour validée', '', {
            duration: 5000,
            panelClass: ['blue-snackbar'],
          })
          this.close()
        },
        (error) => {
          this.spinner = false
          console.error(error)
          this._snackBar.open('Erreur', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }
}
