import { Component, Inject } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ModuleService } from '@app/shared/services/module/module.service'

@Component({
  selector: 'app-filterpayements',
  templateUrl: './filterpayements.component.html',
  styleUrls: ['./filterpayements.component.scss'],
})
export class FilterpayementsComponent {
  response: string
  spinner = false
  spinner2 = false
  state

  constructor(
    private matDialogRef: MatDialogRef<FilterpayementsComponent>,
    private moduleService: ModuleService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.state = this.data.navigationExtras.state
  }

  close(x: number = 1) {
    this.matDialogRef.close(x)
    
  }

  onSubmit(fe: NgForm) {
    const dict = [] // create an empty array
    const variable = fe.value['ID_EXCLUSION']
    const variable2 = fe.value['COMMENT']

    if (!variable || !variable2) {
      this._snackBar.open('Veuillez remplir tous les champs', '', {
        duration: 5000,
        panelClass: ['red-snackbar'],
      })
    } else {
      this.spinner = true
      dict.push({
        id: [variable],
        comment: variable2,
        action: 'EXCLUSION',
      })
      this.moduleService.created(dict).subscribe(
        (result) => {
          this.spinner = false
          if (result != 'empty' && result != 'exclusion_active') {
            this._snackBar.open('Exclusion enregistrée', '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
            this.close(0)
          }
          if (result === 'empty') {
            this._snackBar.open('ID invalide', '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          }
          if (result === 'exclusion_active') {
            this._snackBar.open('Exclusion existe déjà', '', {
              duration: 5000,
            })
          }
        },
        (error) => {
          this.spinner = false
          this.response = 'ERROR'
          this._snackBar.open("Exclusion impossible. Vérifier si une action courante issue de cette ligne n'est pas en compte d'attente ou si cette ligne Next n'est pas considérée comme incluse en ce moment.", '', {
            duration: 15000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }

  submit(inclusion: string, comment: string) {
    const dict = []
    const variable = inclusion
    const variable2 = comment

    if (!variable || !variable2) {
      this._snackBar.open('Veuillez remplir tous les champs', '', {
        duration: 5000,
        panelClass: ['red-snackbar'],
      })
    } else {
      this.spinner2 = true
      dict.push({
        id: [variable],
        comment: variable2,
        action: 'INCLUSION',
      })
      this.moduleService.created(dict).subscribe(
        (result) => {
          this.spinner2 = false
          if (result.message === 'OK') {
            this._snackBar.open('Inclusion enregistrée', '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
            this.close(0)
          }
          if (result === 'empty') {
            this._snackBar.open('ID invalide', '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          }
          if (result === 'inclusion_active') {
            this._snackBar.open('Inclusion existe déjà', '', {
              duration: 5000,
            })
          }
        },
        (error) => {
          this.spinner2 = false

          this.response = 'ERROR'
          this._snackBar.open('Inclusion impossible', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }
}
