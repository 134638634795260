import { Injectable, Injector } from '@angular/core'
import { Event, NavigationError, Router } from '@angular/router'

@Injectable()
export class ErrorsService {
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      console.error(event)
      if (event instanceof NavigationError) {
        this.router.navigate(['/error'], { queryParams: null })
      }
    })
  }
}
