import { formatDate } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UpdatepayementsModalComponent } from '@app/dashboard/updatepayements/updatepayements-modal/updatepayements-modal.component'
import { ModuleService } from '@app/shared/services/module/module.service'
import * as moment from 'moment'
import { PreModificationComponent } from './pre-modification/pre-modification.component'

interface ObjectMap {
  [key: string]: any
}

const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
}

@Component({
  selector: 'app-updatepayements',
  templateUrl: './updatepayements.component.html',
  styleUrls: ['./updatepayements.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UpdatepayementsComponent implements OnInit {
  spinner = false

  flux_next: any

  id_next: string | string[] = ''
  mp_next: string
  mp_short_next: string
  code_mp_gn_next: string
  societe_next = ''
  gn_next = ''
  date_next: string
  montant_next: number

  liste_region: string[] = JSON.parse(sessionStorage.list_regions).sort()
  
  liste_societe: string[] = JSON.parse(sessionStorage.list_societes).sort()
  liste_societe_par_region: string[][] = []
  transco_region_societe: ObjectMap[] = sessionStorage.transco_region_societe
    ? JSON.parse(sessionStorage.transco_region_societe)
    : []
  liste_mp: string[] = JSON.parse(sessionStorage.list_mp).sort(
    (item_one: string, item_two: string) => (
      this.getMpShortText(item_one).toUpperCase() > this.getMpShortText(item_two).toUpperCase() ? 1 : -1
    )
  )

  new_societe = ''
  cache_societe: string
  cache_region: any[]
  cache_societes: any[]
  new_gn: string
  new_mp: string
  new_date: FormControl
  societe_error = ''
  region_error = ''
  comment: string
  commentaire = ''
  desactivation = false
  private dictInitValuesNext: { [key: string]: string | number | string[] }

  private id_next_cut: string

  cuttingData = []
  validate = false
  validate2 = false

  liste_db_cutting: any[]
  private total_montant_db = 0
  public state

  isFromContext: boolean = false;

  isNextPos: boolean;
  isNextNeg: boolean;
  isActionAmountPos: boolean;
  isActionAmountNeg: boolean;
  isActionMontantOppositeNext: boolean;
  isActionIS: boolean;
  isISStatutSoldeOnFluxNextForSameSociety: boolean;
  isAmountActionSupSoldedAction: boolean;
  isSumActionNotSoldedSupSoldedAction: boolean;


  constructor(
    private matDialogRef: MatDialogRef<UpdatepayementsComponent>,
    private dialog: MatDialog,
    private moduleService: ModuleService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.flux_next = this.data.flux_next
    this.new_date = new FormControl(null)
    this.state = this.data.navigationExtras.state
    this.isFromContext = this.data.fromContext
    if (this.state.MODIF) {
      if (this.state.SELECTION) {
        this.id_next = this.state.SELECTION
        this.mp_next = 'Selection multiple'
        this.new_mp = this.mp_next
        this.societe_next = 'Selection multiple'
        this.new_societe =
          (this.state.SOCIETE && this.state.SOCIETE[0]) || this.societe_next
        this.gn_next = 'Selection multiple'
        this.new_gn =
          (this.state.REGION && this.state.REGION[0]) || this.gn_next
        this.date_next = this.new_date.value
        this.validate2 = true
      } else {
        this.id_next = this.state.KEY
        setTimeout(() => this.confirmeID(this.state.KEY), 150)
      }
    }
    if (this.state.CUT && this.state.KEY !== null) {
      setTimeout(() => this.confirmeID2(this.state.KEY), 150)
    }
  }

  ngOnInit() {
  }

  get mapRegionSociete() {
    if (
      this.new_gn === '' ||
      this.new_gn === null ||
      typeof this.new_gn === 'undefined'
    ) {
      const sorted = this.liste_societe.sort((a, b) => {
        return a.localeCompare(b, 'fr', {
          numeric: true})
        });
      return sorted
    }
    this.cache_societes = []
    for (const [region, liste_societe] of Object.entries(
      this.transco_region_societe
    )) {
      if (this.new_gn === region) {
        this.cache_societes = this.transco_region_societe[region]
      }
    }
    if (this.cache_societes.length === 0) {
      this.cache_societes = this.liste_societe
    }
    const sorted = this.cache_societes.sort((a, b) => {
      return a.localeCompare(b, 'fr', {
        numeric: true})
      });
    return sorted
  }

  get mapSocieteRegion() {
    if (this.new_societe == null || typeof this.new_societe === 'undefined') {
      return this.liste_region
    }
    // not search if new_societe has not been modified
    if (this.cache_societe === this.new_societe) {
      return this.cache_region
    } else {
      this.cache_region = []
    }
    this.cache_societe = this.new_societe

    for (const [region, liste_societe] of Object.entries(
      this.transco_region_societe
    )) {
      const found = liste_societe.filter(
        (societe) => societe.trim() === this.new_societe.trim()
      )
      if (found.length > 0) {
        this.cache_region.push(region)
      }
    }
    if (this.cache_region.length === 0) {
      this.cache_region = this.liste_region
    }
    return this.cache_region
  }

  isSocieteExistsInRegion(societe, region) {
    for (const [current_region, liste_societe] of Object.entries(
      this.transco_region_societe
    )) {
      if (region.trim() === current_region) {
        const found = liste_societe.filter(
          (current_societe) => current_societe.trim() === societe.trim()
        )
        if (found.length > 0) {
          return true
        }
      }
    }
    return false
  }

  regionSelected(value) {
    this.new_societe = ''
    this.new_gn = value
    if (value === '') {
      this.new_gn = value
      return
    }
  }

  societeSelected(value) {
    this.new_societe = value
    if (value === '') {
      return
    }
    const found = this.liste_societe.filter(
      (societe) => societe.trim().toLowerCase() === value.trim().toLowerCase()
    )
    if (found.length > 0) {
      this.societe_error = ''
      this.new_societe = value
    } else {
      this.new_societe = ''
      this.societe_error = "La société '" + value + "' n'existe pas."
    }
  }

  societeSelectedForCutting(value, index) {
    if (value === '') {
      return
    }
    const found = this.liste_societe_par_region[index].filter(
      (societe) => societe.trim().toUpperCase() === value.trim().toUpperCase()
    )
    if (found.length > 0) {
      this.societe_error = ''
      this.cuttingData[+index].societe = value.trim().toUpperCase()
    } else {
      this.cuttingData[+index].societe = ''
      this.societe_error = "La société '" + value + "' n'existe pas pour cette région."
    }
  }

  close(x: number = 1) {
    this.matDialogRef.close(x)
  }

  async addNew() {
    this.cuttingData.push({
      id: this.id_next_cut,
      societe: this.societe_next,
      region: this.gn_next,
      date: moment(this.date_next, 'DD/MM/YYYY'),
      mp: this.mp_next,
      montant: ((Number(this.montant_next) - Number(this.total_montant_db)) + 0.001).toFixed(2),
      commentaire: '',
    })
    const item = { value: this.gn_next }
    const index = this.cuttingData.length - 1
    this.setRegions(item, '' + index, false)
  }

  submit() {
    this.desactivation = true
    let total_montant = 0
    let empty_value = false
    let violation_rule = false
    let valeur_identique = false
    const dict = []
    const liste_cutting = JSON.parse(JSON.stringify(this.cuttingData))
    const format = 'dd/MM/yyyy'
    const locale = 'en-US'
    dict.push({ action: 'cuttingData' })
    dict.push({
      id_next: [this.id_next_cut],
    })

    this.isNextPos = Number(this.montant_next) > 0 ? true : false;
    this.isNextNeg = Number(this.montant_next) < 0 ? true : false;

    for (const element of liste_cutting) {
      this.isActionAmountPos = Number(element.montant) > 0 ? true : false;
      this.isActionAmountNeg = Number(element.montant) < 0 ? true : false;
      this.isActionMontantOppositeNext = (this.isNextPos && this.isActionAmountPos) ||
        (this.isNextNeg && this.isActionAmountNeg) ? false : true;
      this.isActionIS = (element.region != this.gn_next) ||
        (element.societe != this.societe_next) ? true : false;

      element.date = formatDate(element.date, format, locale);

      if (!(Number(this.montant_next) * Number(element.montant) < 0)) {
        total_montant += Number(element.montant);
      }
      // Si le montant de l'action est du signe contraire au flux Next d'origine et si l'actiin est un IS
      if (this.isActionMontantOppositeNext && this.isActionIS) {
        // vérifier qu'il existe un IS soldé sur le flux next pour la même société que l'action créée
        this.isISStatutSoldeOnFluxNextForSameSociety = this.liste_db_cutting.length > 0 &&
          this.liste_db_cutting.filter(x => x.statut === 'SOLDE' && x.societe === element.societe).length > 0 ? true : false;
        if (this.isISStatutSoldeOnFluxNextForSameSociety) {
          // Somme des actions au statut "SOLDE" pour la même société
          const action_solded_list = this.liste_db_cutting.filter(x => x.statut === 'SOLDE' &&
            x.societe === element.societe);
          const sum_action_solded = this.sumArray(action_solded_list.map(x => Number(x.montant)));
          this.isAmountActionSupSoldedAction = Math.abs(Number(element.montant)) > Math.abs(sum_action_solded) ? true : false;
          // Somme des actions non soldées pour la même société
          const action_not_solded_list = this.liste_db_cutting.filter(x => x.statut !== 'SOLDE' &&
            x.societe === element.societe);
          const sum_action_not_solded = this.sumArray(action_not_solded_list.map(x => Number(x.montant)));
          this.isSumActionNotSoldedSupSoldedAction = Math.abs(sum_action_not_solded + Number(element.montant)) > Math.abs(sum_action_solded)? true : false;
        }
      }

      if (
        element.societe === '' ||
        element.date === '' ||
        element.mp === '' ||
        element.montant === '' ||
        element.commentaire === '' ||
        isNaN(element.montant)
      ) {
        empty_value = true
      }

      if (
        element.region === this.gn_next &&
        element.societe === this.societe_next &&
        element.date === this.date_next &&
        this.getMpShortText(element.mp) === this.getMpShortText(this.mp_next) &&
        !this.isActionMontantOppositeNext
      ) {
        valeur_identique = true
      }
      if (+element.montant === 0) {
        violation_rule = true
      }
    }

    // La somme des montants des actions courantes d'un flux Next ne peut dépasser le montant initial du flux Next
    if (
      Number(Math.abs(total_montant + this.total_montant_db).toFixed(2)) >
      Number(Math.abs(Number(this.montant_next)).toFixed(2))
    ) {
      this.desactivation = false
      this._snackBar.open(
        'Somme des montants supérieure au montant initial',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else if (empty_value) {
      this.desactivation = false
      this._snackBar.open(
        'Un ou plusieurs champs ne sont pas remplis correctement',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else if (violation_rule) {
      this.desactivation = false
      this._snackBar.open(
        'Un ou plusieurs champs ne respectent pas les règles de découpage.',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else if (valeur_identique) {
      this.desactivation = false
      this._snackBar.open(
        'Une ou plusieurs lignes sont identiques au flux Next que vous essayez de découper.',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      //  Le montant de l'action ne peut être supérieur en valeur absolue au montant de l'action soldée pour la même société
      //  La somme des montants des actions non soldées sur la même société ne peut être supérieur à l'action soldée
    } else if (this.isAmountActionSupSoldedAction || this.isSumActionNotSoldedSupSoldedAction) {
      this.desactivation = false
      this._snackBar.open(
        'Montant saisi supérieur au montant disponible',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      // Si le montant de l'action est du signe contraire au flux Next d'origine si l’action est un IS
      // (modification de région/société) vérifier qu'il existe un IS soldé sur le flux next pour la même société que l'action créée
    } else if (this.isActionIS && !this.isISStatutSoldeOnFluxNextForSameSociety && this.isActionMontantOppositeNext) {
      this.desactivation = false
      this._snackBar.open(
        'Le montant à défaire n\'est pas au statut soldé',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      // Si le flux next a un montant négatif à l'origine, le montant de l'action doit être négatif
    } else if ((!this.isISStatutSoldeOnFluxNextForSameSociety && this.isNextPos && this.isActionMontantOppositeNext)) {
      this.desactivation = false
      this._snackBar.open(
        'Le montant ne peut être négatif',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
      // Si le flux next a un montant positif à l'origine, le montant de l'action doit être positif
    } else if (!this.isISStatutSoldeOnFluxNextForSameSociety && this.isNextNeg && this.isActionMontantOppositeNext) {
      this.desactivation = false
      this._snackBar.open(
        'Le montant ne peut être positif',
        '',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      )
    } else {
      this.spinner = true
      dict.push(liste_cutting)

      this.moduleService.recordCuttingData(dict).subscribe(
        (result) => {
          this.spinner = false
          this.validate = true

          if (result.RESULTAT === 'OK') {
            this._snackBar.open('Découpage enregistré', '', {
              duration: 5000,
              panelClass: ['blue-snackbar'],
            })
          }
          this.desactivation = false
          this.close(0)
          this.cancelForm()
        },
        (error) => {
          this.desactivation = false
          this.spinner = false
          this._snackBar.open('Erreur', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
      )
    }
  }

  sumArray(array) {
    let sum = 0
    for (const item of array) {
      sum += item
    }
    return sum
  }

  confirmeID(id: string) {
    this.desactivation = true
    this.dictInitValuesNext = {}
    const dict = [
      {
        id,
      },
    ]
    this.spinner = true
    this.moduleService.check(dict).subscribe(
      (result) => {
        this.spinner = false
        this.validate2 = true
        if (Object.keys(result).length !== 0) {
          if (result.EXCLUS) {
            this._snackBar.open('Impossible de modifier un ID exclu', '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          } else {
            this.id_next = result.ID
            this.mp_next = result.CODE_MODE_PAIEMENT
            this.new_mp = this.mp_next
            this.societe_next = result.CODE_SOCIETE
            this.new_societe = this.societe_next
            this.montant_next = result.MONTANT
            this.gn_next = result.CODE_INSTANCE
            this.new_gn = this.gn_next
            this.date_next = result.DATE

            this.dictInitValuesNext.id = this.id_next
            this.dictInitValuesNext.mp = this.mp_next
            this.dictInitValuesNext.societe = this.societe_next
            this.dictInitValuesNext.montant = this.montant_next
            this.dictInitValuesNext.gn = this.gn_next
            this.dictInitValuesNext.date = this.date_next

            this.new_date = new FormControl(new Date(this.date_next))
          }
        } else {
          this._snackBar.open('Id next invalide', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
        this.desactivation = false
      },
      (error) => {
        this.desactivation = false
        this.spinner = false
        this._snackBar.open('Erreur', '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
      }
    )
  }

  confirmeID2(id: string) {
    this.id_next_cut = id
    this.desactivation = true
    this.dictInitValuesNext = {}
    const dict = [
      {
        id,
      },
    ]
    this.spinner = true
    this.moduleService.check_cut(dict).subscribe(
      (result) => {
        this.spinner = false
        if (Object.keys(result).length !== 0) {
          if (result.EXCLUS) {
            this._snackBar.open('Impossible de découper un ID exclu', '', {
              duration: 5000,
              panelClass: ['red-snackbar'],
            })
          } else {
            this.validate = true
            this.id_next = result.ID
            this.mp_next = result.CODE_MODE_PAIEMENT

            this.mp_short_next = this.getMpShortText(this.mp_next)

            this.code_mp_gn_next = result.CODE_MP_GN
            this.new_mp = this.mp_next
            this.societe_next = result.CODE_SOCIETE
            this.new_societe = this.societe_next
            this.montant_next = result.MONTANT
            this.gn_next = result.CODE_INSTANCE
            this.new_gn = this.gn_next
            this.date_next = result.DATE
            const date_next = new Date(this.date_next)
            const format = 'dd/MM/yyyy'
            const locale = 'en-US'
            this.date_next = formatDate(date_next, format, locale)
            this.new_date = new FormControl(date_next)

            this.dictInitValuesNext.id = this.id_next
            this.dictInitValuesNext.mp = this.mp_next
            this.dictInitValuesNext.societe = this.societe_next
            this.dictInitValuesNext.montant = this.montant_next
            this.dictInitValuesNext.gn = this.gn_next
            this.dictInitValuesNext.date = this.date_next
            this.liste_db_cutting = result['LISTE_CUTTING']

            for (const element of this.liste_db_cutting) {
              if (!(Number(this.montant_next) * Number(element.montant) > 0
                && this.gn_next === element.gn
                && this.societe_next === element.societe
                && ["MISE EN COMPTE D'ATTENTE", 'A SOLDER', 'SOLDE'].indexOf(element.statut) > -1)
              ) {
                this.total_montant_db = Number(((this.total_montant_db + Number(element.montant)) + 0.001).toFixed(2))
              }
            }
          }
        } else {
          this._snackBar.open('Id next invalide', '', {
            duration: 5000,
            panelClass: ['red-snackbar'],
          })
        }
        this.isFromContext = true
        this.desactivation = false
      },
      (error) => {
        this.desactivation = false
        this.spinner = false
        this._snackBar.open('Erreur', '', {
          duration: 5000,
          panelClass: ['red-snackbar'],
        })
      }
    )
  }

  filtered(lst: string[], str: string) {
    if (typeof lst === 'undefined') {
      return []
    }
    return lst.filter(
      (x) => x.trim().toLowerCase().indexOf(str.trim().toLowerCase()) >= 0
    )
  }

  cancelForm() {
    this.desactivation = false
    this.new_societe = ''
    this.new_gn = ''
    this.new_mp = ''
    this.new_date.setValue('')
    this.commentaire = ''

    this.id_next = ''
    this.mp_next = ''
    this.societe_next = ''
    this.montant_next = undefined
    this.gn_next = ''
    this.date_next = ''
    this.validate = false
    this.validate2 = false
    this.new_date = new FormControl(new Date(''))
    this.liste_db_cutting = []
    this.cuttingData = []
  }

  confirmForm() {
    this.desactivation = true
    const liste_cutting = []

    if (this.comment === undefined || this.comment === '') {
      this._snackBar.open('Veuillez indiquer un commentaire pour cette action', '', {
        duration: 10000,
        panelClass: ['red-snackbar'],
      })
      this.desactivation = false
      return
    }

    const format = 'dd/MM/yyyy'
    const locale = 'en-US'
    const data: { [key: string]: string | number | boolean | string[] } = {}
    data.societe = this.new_societe
    data.region = this.new_gn
    data.mp = this.new_mp
    data.date = this.new_date.value !== null ? formatDate(this.new_date.value, format, locale) : undefined
    data.montant = this.montant_next
    data.commentaire = this.comment
    liste_cutting.push(data)
    this.spinner = true
    this.moduleService.checkingNextDataForCutting(this.id_next).subscribe(
      (response) => {
        this.spinner = false
        this.validate = true
        if (response === 'La liste des identifiants est vide.') {
          this._snackBar.open('La liste des flux Next à modifier est vide. Veuillez sélectionner des flux Next et réessayez.', '', {
            duration: 10000,
            panelClass: ['blue-snackbar'],
          })
        } else {
          // La réponse contient les clé des flux Next (ID_PAIEMENT_NEXT) associées
          // aux actions courantes créées à partir de ces flux Next.
          // Dans ce bloc, on va afficher chaque flux et ses actions à l'utilisateur
          // afin qu'il choisissent celles qui veut réellement modifier. Car la modification
          // annulera toutes les actions courantes qui ont été faites précédemment
          const height = Math.ceil(window.innerHeight / 3) * 2
          this.dialog
            .open(PreModificationComponent, {
              width: '100%',
              height: '100%',
              disableClose: true,
              data: {
                actions: response,
                flux_next: this.flux_next,
                update_to_apply: liste_cutting,
              },
            })
            .afterClosed()
            .subscribe((resp) => {
              this.desactivation = false
              if (resp !== 'confirm') {
                this._snackBar.open("L'opération a été annulée", '', {
                  duration: 5000,
                  panelClass: ['green-snackbar'],
                })
              } else {
                this.close(1)
                this.close(0)
                this.cancelForm()
              }
            })
        }
      },
      (error) => {
      }
    )
  }

  delete(el, index: number) {
    this.cuttingData.splice(index, 1)
  }

  deleteCuttingRecord(el, index: number) {
    const dict = [
      {
        message: 'Etes vous sur de vouloir supprimer ce découpage ? ',
        action: 'deleteCuttingData',
      },
      this.liste_db_cutting[index],
    ]
    this.dialog
      .open(UpdatepayementsModalComponent, {
        width: '660px',
        height: '260px',
        disableClose: true,
        data: {
          dataKey: dict,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response === 'confirm') {
          this.liste_db_cutting.splice(index, 1)
          if (Number(this.montant_next) * Number(el.montant) > 0) {
            if (!(Number(this.montant_next) * Number(el.montant) > 0 && this.gn_next === el.gn && this.societe_next === el.societe && ["MISE EN COMPTE D'ATTENTE", 'A SOLDER', 'SOLDE'].indexOf(el.statut) > -1)) {
              this.total_montant_db = Number(((this.total_montant_db - (+el.montant)) + 0.001).toFixed(2))
            }
          }
        } else {
        }
      })
  }


  getMpShortText(modep) {

    return (Object.keys(JSON.parse(sessionStorage.getItem('mp_matching')))).find((key) => {
      if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('VIREMENTS (')) {
        return modep.includes('VIREMENTS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('ANNULATIONS')) {
        return modep.includes('ANNULATIONS');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CARTES BANCAIRES (')) {
        return modep.includes('CARTES BANCAIRES (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES DOCAPOST (')) {
        return modep.includes('DOCAPOST (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES ETRANGERS (')) {
        return modep.includes('ETRANGERS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES IMPAYES (')) {
        return modep.includes('IMPAYES (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('CHEQUES MANUELS (')) {
        return modep.includes('MANUELS (');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 4) == 'ETIP') {
        return modep.substr(0, 4) == 'ETIP';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 12) == 'PRELEVEMENTS') {
        return modep.substr(0, 12) == 'PRELEVEMENTS';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('REPRISE SOLDE')) {
        return modep.includes('REPRISE SOLDE');
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].substr(0, 3) == 'TIP') {
        return modep.substr(0, 3) == 'TIP';
      }
      else if (JSON.parse(sessionStorage.getItem('mp_matching'))[key].startsWith('VIREMENTS CCP (')) {
        return modep.includes('CCP (');
      } else {
        return JSON.parse(sessionStorage.getItem('mp_matching'))[key] === modep;
      }
    })
  }

  setRegions(event, index: string, refreshSociete: boolean = true) {
    this.liste_societe_par_region[index] = this.transco_region_societe[event.value] as string[]
    this.liste_societe_par_region[index].sort()
    // tslint:disable-next-line
    refreshSociete ? this.cuttingData[+index].societe = '' : null
  }

  hideDeleteBtn(statut) {
    if (statut.includes("MISE EN COMPTE D'ATTENTE") || statut == 'SOLDE') {
      return true
    }
    return false
  }

  compensationAvoir(element) {
    if (Number(this.montant_next) * Number(element.montant) > 0 && this.gn_next === element.gn && this.societe_next === element.societe && ["MISE EN COMPTE D'ATTENTE", 'A SOLDER', 'SOLDE'].indexOf(element.statut) > -1) {
      return true
    }
    return false
  }

  montantOppose(element) {
    if ((Number(this.montant_next) * Number(element.montant)) < 0) {
      return true
    }
    return false
  }
}
