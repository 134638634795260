<mat-card
  class="card mat-elevation-z2"
  *ngIf="user.is_observator || user.is_actor"
>
  <mat-card-header class="card-header">
    <mat-card-title>Actions courantes</mat-card-title>
  </mat-card-header>
  <mat-card-content
    class="card-content"
    *ngIf="user.is_observator || user.is_actor"
  >
    <mat-grid-list
      cols="1"
      rowHeight="40px"
      [gutterSize]="'20px'"
      *ngIf="user.is_observator || user.is_actor"
    >
      <mat-grid-tile *ngIf="user.is_actor">
        <button
          [disabled]="!user.is_actor"
          class="button_list"
          mat-button
          (click)="goToSolderIntersocietes()"
        >
          Solder les inter-sociétés
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button
          [disabled]="!user.is_observator && !user.is_actor"
          class="button_list"
          mat-button
          (click)="goToTableTraitements()"
        >
          Visualiser/exporter les traitements
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-card class="card mat-elevation-z2" *ngIf="user.is_parametreur">
  <mat-card-header class="card-header">
    <mat-card-title>Actions de clôture</mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-content">
    <mat-grid-list cols="1" rowHeight="40px" [gutterSize]="'10px'">
      <mat-grid-tile>
        <button
          [disabled]="!user.is_parametreur"
          class="button_list"
          mat-button
          (click)="goToDateCloture()"
        >
          Déclarer les dates de clôture
        </button>
      </mat-grid-tile>

      <!-- <mat-grid-tile>
        <button
          [disabled]="!user.is_parametreur"
          class="button_list"
          mat-button
        >
          Actions de clôture
        </button>
      </mat-grid-tile> -->
      <mat-grid-tile>
        <button
          [disabled]="!user.is_parametreur"
          class="button_list"
          (click)="goToClotures()"
          mat-button
        >
          Visualiser et exporter une clôture
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
<mat-card class="card mat-elevation-z2" *ngIf="user.is_parametreur">
  <mat-card-header class="card-header">
    <mat-card-title>Export des données</mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-content">
    <mat-grid-list cols="1" rowHeight="40px" [gutterSize]="'10px'">
      <mat-grid-tile *ngIf="user.is_actor">
        <button
          [disabled]="!user.is_actor || desactivation"
          class="button_list"
          mat-button
          (click)="openExportDataDialog()"
        >
          Exporter les données
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
