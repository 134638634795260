<div class="title">{{ title }}</div>
<mat-dialog-content [innerHtml]="description"> </mat-dialog-content>
<mat-dialog-actions>
  <button
    class="cancel-btn"
    color="primary"
    mat-raised-button
    mat-dialog-close
    (click)="refuse()"
  >
    {{ labelRefuse }}
  </button>
  <button color="primary" mat-raised-button (click)="confirm()">
    {{ labelConfirm }}
  </button>
</mat-dialog-actions>
